import { Component, OnInit, ViewChild } from '@angular/core';
import { SubscriptionService } from './subscription.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

import { Appearance, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';


@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
  providers: [SubscriptionService]
})
export class SubscriptionComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  customerID: any;
  PaymentMethodID: any;
  selectedMonth = 1;
  email: any;
  isActiveSubscription = true;
  subscriptionExpireDate:any;
  currentUser: any;
  errorMsg: any;

  constructor(private spinnerService: Ng4LoadingSpinnerService, private stripeService: StripeService, private SubscriptionService: SubscriptionService, private httpClient: HttpClient) {
    this.currentUser = JSON.parse(localStorage.getItem('loggedInUser'));
  }

  ngOnInit() {
    this.SubscriptionService.getSubscribe(parseInt(this.currentUser.id)).subscribe((res) => {
      this.isActiveSubscription = res.subscriptionStatus;
      this.subscriptionExpireDate = this.currentUser.subscriptionEndDate;
    })
  }

  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#000',
        backgroundColor: 'transparent',
         color: '#000',
         fontWeight: '300',
         fontSize: '18px',
         lineHeight:'40px',
         padding:'100px',
        '::placeholder': {
          color: '#000',
        },
      },
      invalid: {
        iconColor: '#ffc7ee',
        color: '#ffc7ee'
      },
    }
  };

  elementsOptions: StripeElementsOptions = {
      locale: 'en',
  };

  subscriptionMonth(event) {
    this.selectedMonth = event.target.value;
    this.email = this.currentUser.email;
  }

  createToken(): void {
    this.errorMsg = "";
    this.spinnerService.show();
    this.stripeService.createToken(this.card.element, this.email).subscribe((result) => {
      if(result.token) {
        let userId = parseInt(this.currentUser.id);

        const req = {
          month: this.selectedMonth,
          userId: userId,
          tokenId: result.token.id
        };
        this.SubscriptionService.subscribe(req).subscribe((res) => {
          this.isActiveSubscription = true;
          this.subscriptionExpireDate = res.subscriptionEndDate
          this.spinnerService.hide();
        });

      } else if (result.error) {
        this.spinnerService.hide();
        this.errorMsg = result.error.message;
        console.log(result.error.message)
      }
    })
  }
}
