import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { DataService } from 'src/app/shared-services/data-service/data.service';
import { DashboardService } from '../dashboard.service';

const LIMIT = 10;

@Component({
  selector: 'app-magazines-podcast',
  templateUrl: './magazines-podcast.component.html',
  styleUrls: ['./magazines-podcast.component.scss']
})

export class MagazinesPodcastComponent implements OnInit {
  noRecordBool: boolean;
  pageNo = 0;
  offset = LIMIT * this.pageNo;
  pdfdata: any;

  constructor(private dashboardService: DashboardService,
              private httpClient: HttpClient,
              private data: DataService,
              private spinnerService: Ng4LoadingSpinnerService) { }

ngOnInit() {
  this.getAllMagazines(LIMIT, this.offset);
  }

  // tslint:disable-next-line:no-shadowed-variable
  getAllMagazines(LIMIT, offset) {
    const magzinParam = {
      searchterm: '',
      limit: LIMIT,
      offset: 0
    };
    this.spinnerService.show();
    this.noRecordBool = false;
    this.dashboardService.getAllPdf(magzinParam).subscribe(
      async data => {
        if (data) {
          console.log(data);
          this.pdfdata = data.respon;
          console.log(this.pdfdata);
          if (data.respon.length === 0) {
            this.noRecordBool = true;
          }
          this.spinnerService.hide();
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

}
