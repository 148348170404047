import { Component, OnInit } from '@angular/core';
import { TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { ViewContainerRef } from '@angular/core';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { AboutComponent } from 'src/app/ask-osho/dashboard/about/about.component';
import { TermsComponent } from '../terms/terms.component';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [AuthService]
})
export class LoginComponent implements OnInit {

  modalRef: BsModalRef;
  windowName: string;
  showLoginErrorBool = false;

  loginData = {
    'X-API-KEY': 'testkey',
    email: null,
    password: null
  };


  signupData = {
    'X-API-KEY': 'testkey',
    username: null,
    email: null,
    password: null,
    sanyas_name: null,
    phone_number: null,
    country_id: null,
    state_id: null,
    city: null,
    profile_photo: null,
    device:'Web'
  };

  countryData: any[] = [];
  stateData: any[] = [];
  showStateBool = false;
  redirectURL = '';

  constructor(private modalService: BsModalService, private authService: AuthService,
    private router: Router, private bsmoodalref: BsModalRef,private modalDialogService: ModalDialogService, private viewContainer: ViewContainerRef) {
      const navigation = this.router.getCurrentNavigation();
      
      if(null != navigation) {
        const state = navigation.extras.state as {
          redirectURLAfterLogin: string
        };
        if( undefined !== state && '' !== state.redirectURLAfterLogin ) {
          this.redirectURL = state.redirectURLAfterLogin.replace('/avplay?', '');
        }
      }
  }
  
  openPopup(window) {
      const initialState = {
        windowName: window,
        animated: true,
        keyboard: true
      };
      this.modalRef = this.modalService.show(TermsComponent, { initialState });
      this.modalRef.content.closeBtnName = 'Close';
    }
  async ngOnInit() {
    
    console.log(this.windowName);
    await this.getCountries();
  }

  login(form: NgForm): void {
    const formdata = form.value;
    this.loginData.email = formdata.loginEmail;
    this.loginData.password = formdata.loginPass;
    this.authService.login(this.loginData).subscribe(
      async data => {
        console.log(data);
        if (data.error === 0) {
          console.log(data.respon);
          localStorage.setItem('loggedInUser', JSON.stringify(data.respon));
          let param = this.redirectURL;

        
          if('' !== this.redirectURL) {
            this.router.navigate(['/avplay'], {queryParams: { param } } );
          } else {
            this.router.navigate(['/ask-osho/main-content']);
          }

          this.bsmoodalref.hide();
          form.reset();
          this.showLoginErrorBool = false;
        } else {
          console.log(data.respon);
          this.showLoginErrorBool = true;
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  openSimpleModal(message) {
    this.modalDialogService.openDialog(this.viewContainer, {
      /* title: 'Simple dialog', */
      childComponent: SimpleModalComponent,
      settings: {
        closeButtonClass: 'fa fa-times'
      },
      data: {
        text: message
      }
    });
  }



  // Sign up
  signIn(form: NgForm): void {
    const formdata = form.value;
    console.log(formdata);
    this.signupData.username = formdata.name;
    this.signupData.email = formdata.signupEmail;
    this.signupData.password = formdata.signupPassword;
    this.signupData.sanyas_name = formdata.sannyasName;
    this.signupData.phone_number = formdata.contactNumber;
    this.signupData.country_id = parseInt(formdata.country);
    this.signupData.state_id = parseInt(formdata.state);
    this.signupData.city = formdata.city;
    this.signupData.profile_photo = '';
    this.signupData.device = 'Web'

    console.log('SIGNUP DATA : ', this.signupData);
    this.authService.signUp(this.signupData).subscribe(
      async data => {
        console.log(data);
        if (data.message === 0) {
          console.log(`Registration failed please check if id already exists!`);
          this.bsmoodalref.hide();
          this.openSimpleModal("Registration failed please check if Email already exists!");
        } else {
          console.log(`Successfull registration with id of ${data.message}`);
          this.openSimpleModal("Registration successfully completed");
          this.windowName = 'login';
        }
      },
      err => {
        return console.error(err);
      }
    );

  }

  // Get Countries
  getCountries(): void {
    this.authService.getCountries().subscribe(
      async data => {
        console.log(data);
        if (data.status) {
          this.countryData = data.resultObject;
          console.log(this.countryData);
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  // Get States
  setStateId(countryId): void {
    const stateForm = {
      id: countryId
    };
    this.authService.getStates(stateForm).subscribe(
      async data => {
        console.log(data);
        if (data.status) {
          this.stateData = data.resultObject;
          this.showStateBool = true;
          console.log(this.stateData);
        }
      },
      err => {
        return console.error(err);
      }
    );

  }

  changeView(window): void {
    this.windowName = window;
  }


  forgetPassword(form: NgForm) {
    const formdata = form.value;
    console.log(formdata.forgetEmail);
    this.authService.forgetPassword(formdata.forgetEmail).subscribe(
      async data => {
        console.log(data);
        console.log(data.message);
        if(data.message == 'No email exist')
        {
          this.openSimpleModal("No email exist");
        }
        else {
          this.openSimpleModal("Email sent");
        }
       
      },
      err => {
        return console.error(err);
        
      }
    );
  }

  alert() {
    window.alert('terms and condition');
  }
}
