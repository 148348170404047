import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Track } from 'ngx-audio-player';
import { DataService } from 'src/app/shared-services/data-service/data.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { MatGridTileHeaderCssMatStyler } from '@angular/material';


const LIMIT = 0;
const MASTER_SUBSCRIPTION_ID = 1;

@Component({
  selector: 'app-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss', '../main-content/main-content.component.scss'],
  providers: [DashboardService]
})
export class AudioComponent implements OnInit {

  audioBaseUrl: any;
  audioCatUrl: any;
  audioInfo = [];
  audioDiscourseInfo = [];
  pageNo = 0;
  isPaused = false;
  isPlayed = false;
  offset = LIMIT * this.pageNo;
  activeTab = 'qAndATab';
  msbapAudioUrlNew: any;
  selectedCategoryName = '';
  msaapDisplayTitle = false;
  msaapDisplayPlayList = true;
  msaapPageSizeOptions = [100];
  songsList = [];
  playAudioInfo: Array<any> = [];
  categoryId = 0;
  categoryName = "";
  sortBy = 1;
  discourseSortBy = 1;
  selectFilterBool = false;
  selectDisFilterBool = false;
  storageBaseUrl: any;
  baseUrl: any;
  audioCategoryResult = [];
  audioDisCategoryResult = [];
  isInitPlayed = false;
  searchStr = '';
  srchString = '';
  timeout = null;
  isSearching = false;
  selectLanguage = [
    { id: 1, name: "English" },
    { id: 2, name: "Hindi" },
  ];
  noRecordBool = false;
  currentUserId: any;
  subscriptionBody: any;
  modalRef: BsModalRef;
  queryParams: any;
  showSearchOption = false;
  showDisSearchOption = false;
  isSort = false;
  shareAudio = [];

  constructor(private dashboardService: DashboardService, private httpClient: HttpClient, private data: DataService,
    private spinnerService: Ng4LoadingSpinnerService, private modalService: BsModalService, private route: ActivatedRoute, private router: Router,
    private ngNavigatorShareService: NgNavigatorShareService) {

    this.audioBaseUrl = environment.storageBaseUrl + '/audios/cover/';
    this.audioCatUrl = environment.baseUrl + "/uploads/media_images/";
    let currentuser = JSON.parse(localStorage.getItem('loggedInUser'));
    this.currentUserId = parseInt(currentuser.id);

    route.queryParams.subscribe(
      // params => console.log('queryParams', params['tab']));
      params => this.queryParams = params['tab']);
  }

  ngOnInit() {
    window.scrollTo(0, 0)
    if (this.queryParams == 'Discourse') {
      this.tabChange('discourseTab');
    } else {
      this.getqAndaAudioCategory();
    }

    this.data.pauseSong.subscribe(songName => {
      console.log('init audio comp')
      if (songName.toString() == 'true') {
        this.isPaused = true;
        this.isPlayed = false;
      } else {
        this.isPaused = false;
        this.isPlayed = true;
      }
      console.log(this.isPaused)
    })
  }

  shareToSocial(audioInfo) {
    console.log("in audio", audioInfo );
    // if (!this.ngNavigatorShareService.canShare()) {
    //   alert(`This service/api is not supported in your Browser`);
    //   return;
    // }
    this.spinnerService.show();
    
    const reqObj={
      av_id: parseInt(audioInfo.id),
      user_id: this.currentUserId,
      media_type: parseInt(audioInfo.category),
      media_category: parseInt(audioInfo.category)
    }
    this.dashboardService.getAvLink(reqObj).subscribe((res:any) =>{
      console.log("link", res.respon);

      this.ngNavigatorShareService.share({
        title: res.respon.name,
        text: res.respon.name,
        url: res.respon.link
      }).then( (response) => {
        console.log(response);
        this.modalService.hide(1);
        this.spinnerService.hide();
      })
      .catch( (error) => {
        this.spinnerService.hide();
        alert("Something went wrong! Please try after sometime");
      });

      

    },(error)=>{
      console.log("error", error);
    });
    
  }

  tabChange(tab) {
    this.srchString = '';
    this.activeTab = tab;
    this.showSearchOption = false;
    this.showDisSearchOption = false;
    if (this.activeTab === 'discourseTab') {
      this.audioDisCategoryResult = [];
      this.discourseSortBy = 1
      this.pageNo = 0;
      this.offset = LIMIT * this.pageNo;
      this.showDisSearchOption = true;
      this.getDiscourseAudioCategory(this.srchString, 0, this.offset);
    } else {
      this.audioCategoryResult = [];
      this.sortBy = 1;
      this.pageNo = 0;
      this.offset = LIMIT * this.pageNo;
      this.getqAndaAudioCategory();
    }
  }

  onOptionsFilterSelected(event) {
    if (event.target.value == 'English') {
      this.selectFilterBool = true;
      this.sortBy = 1;
      this.pageNo = 0;
      this.offset = LIMIT * this.pageNo;
      this.audioInfo = [];
    } else if (event.target.value == 'Hindi') {
      this.selectFilterBool = true;
      this.sortBy = 2;
      this.pageNo = 0;
      this.offset = LIMIT * this.pageNo;
      this.audioInfo = [];
    }
    this.getAudioSongsList('', LIMIT, this.offset, this.categoryId);
  }

  searchSongByKey(searchName) {
    var refObj = this;
    refObj.srchString = searchName;
    refObj.audioInfo = [];
    clearTimeout(refObj.timeout);
    // Make a new timeout set to go off in 500ms
    refObj.timeout = setTimeout(function() {
      console.log('Input Value:', refObj.srchString);
      if (refObj.srchString.length === 0) {
        refObj.srchString = '';
        refObj.isSearching = false;
        refObj.pageNo = 0;
        refObj.offset = LIMIT * refObj.pageNo;
        refObj.getAudioSongsList(refObj.srchString, LIMIT, refObj.offset, refObj.categoryId);
      } else {
        refObj.isSearching = true;
        refObj.pageNo = 0;
        refObj.offset = LIMIT * refObj.pageNo;
        refObj.getAudioSongsList(refObj.srchString, LIMIT, refObj.offset, refObj.categoryId);
      }
    }, 500);
  }
  
  searchDiscourseByKey(searchName) {
    var refObj = this;
    refObj.srchString = searchName;
    refObj.audioDisCategoryResult = [];
    clearTimeout(refObj.timeout);
    // Make a new timeout set to go off in 500ms
    refObj.timeout = setTimeout(function() {
      console.log('Input Value:', refObj.srchString);
      if (refObj.srchString.length === 0) {
        refObj.srchString = '';
        refObj.isSearching = false;
        refObj.pageNo = 0;
        refObj.offset = LIMIT * refObj.pageNo;
        refObj.getDiscourseAudioCategory(refObj.srchString, 0, refObj.offset);
      } else {
        refObj.isSearching = true;
        refObj.pageNo = 0;
        refObj.offset = LIMIT * refObj.pageNo;
        refObj.getDiscourseAudioCategory(refObj.srchString, 0, refObj.offset);
      }
    }, 500);
  }

  onOptionsFilterDiscourseSelected(event) {
    if (event.target.value == 'English') {
      this.selectDisFilterBool = true;
      this.discourseSortBy = 1;
      this.pageNo = 0;
      this.offset = LIMIT * this.pageNo;
      this.audioDisCategoryResult = [];
    } else if (event.target.value == 'Hindi') {
      this.selectDisFilterBool = true;
      this.discourseSortBy = 2;
      this.pageNo = 0;
      this.offset = LIMIT * this.pageNo;
      this.audioDisCategoryResult = [];
    }
    this.getDiscourseAudioCategory(this.srchString, 0, this.offset);
  }

  showAudioSongs(tab, category) {
    window.scrollTo(0, 0);
    this.showSearchOption = false;
    if (tab == 'QandASongs') {
      this.categoryId = category.id;
      this.selectedCategoryName = category.name;
      this.audioInfo = [];
      this.sortBy = 1;
      this.activeTab = "qAndATabSonngs"
      this.showSearchOption = true;
      this.getAudioSongsList('', LIMIT, this.offset, this.categoryId);
    }
    if (tab == 'discourseSongs') {
      this.searchStr = category.name;
      this.audioDiscourseInfo = [];
      this.sortBy = 1; //added
      this.activeTab = "discourseTabSonngs"
      this.getDiscourseAudioSongsList(LIMIT, this.offset, this.searchStr);
    }
  }

  /* get Q and A Audio cateogry */
  getqAndaAudioCategory() {
    this.spinnerService.show();
    this.noRecordBool = false;
    this.dashboardService.getqAndaAudioCategory().subscribe(
      async data => {
        if (data) {
          if (data.respon.length == 0) {
            this.noRecordBool = true;
          }
          this.spinnerService.hide();
          await Promise.all(data.respon.map(async (singleData, i) => {
            this.audioCategoryResult.push(singleData);
            const currentIndex = this.audioCategoryResult.length - 1;
            this.audioCategoryResult[currentIndex].coverImage = `${this.audioCatUrl}${singleData.image_url}`;
          }));
          console.log('category data', data);
          // <h1>{{cat.subscriptionStatus}}</h1>

        }
      },
      err => {
        return console.error(err);
      }
    );
  }
  /* get Audio songs */
  getAudioSongsList(searchName, LIMIT, offset, catId) {
    const audioParam = {
      searchterm: searchName,
      limit: LIMIT,
      offset: offset,
      quedisc: 1,
      album_id: this.sortBy,
      should_orderby_name: false,
      media_type_id: parseInt(catId),
    };
    this.spinnerService.show();
    this.noRecordBool = false;
    this.dashboardService.getAudioSongsList(audioParam).subscribe(
      async data => {
        if (data) {
          if (data.respon.length == 0) {
            this.noRecordBool = true;
          }
          this.spinnerService.hide();
          const subscription_status = await this.getUserSubscription(this.currentUserId);

          await Promise.all(data.respon.map(async (singleData, i) => {
            this.audioInfo.push(singleData);
            const currentIndex = this.audioInfo.length - 1;
            this.audioInfo[currentIndex].coverImage = `${this.audioBaseUrl}${singleData.cover}`;
            this.audioInfo[currentIndex].subscriptionStatus = subscription_status;

          }));
          console.log('data', this.audioInfo);
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  /* get Discourse Audio cateogry */
  getDiscourseAudioCategory(searchName, LIMIT, offset) {
    console.log(searchName, "search")
    const audioParam = {
      searchterm: searchName,
      limit: LIMIT,
      offset: offset,
      quedisc: 2,
      album_id: this.discourseSortBy,
      should_orderby_name: true,
    };
    this.spinnerService.show();
    this.noRecordBool = false;
    this.dashboardService.getDiscourseAudioCategory(audioParam).subscribe(
      async data => {
        if (data) {
          if (data.respon.length == 0) {
            this.noRecordBool = true;
          }
          this.spinnerService.hide();
          await Promise.all(data.respon.map(async (singleData, i) => {
            this.audioDisCategoryResult.push(singleData);
            const currentIndex = this.audioDisCategoryResult.length - 1;
            this.audioDisCategoryResult[currentIndex].coverImage = `${this.audioBaseUrl}${singleData.cover}`;
          }));
          console.log('Discourse category data', data);
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  /* get Discourse Audio songs */
  getDiscourseAudioSongsList(LIMIT, offset, searchStr) {
    const audioParam = {
      searchterm: searchStr,
      quedisc: 2,
      album_id: this.discourseSortBy,
      limit: LIMIT,
      offset: offset,
      should_orderby_name: false
    };

    this.spinnerService.show();
    this.noRecordBool = false;
    this.dashboardService.getDiscourseAudioSongsList(audioParam).subscribe(
      async data => {
        if (data) {
          if (data.respon.length == 0) {
            this.noRecordBool = true;
          }
          this.spinnerService.hide();
          await Promise.all(data.respon.map(async (singleData, i) => {
            this.audioDiscourseInfo.push(singleData);
            const currentIndex = this.audioDiscourseInfo.length - 1;
            this.audioDiscourseInfo[currentIndex].coverImage = `${this.audioBaseUrl}${singleData.cover}`;
          }));
          console.log('before audioDiscourseInfo', this.audioDiscourseInfo);
          this.audioDiscourseInfo.sort((a,b) => a.added.localeCompare(b.added));
          console.log('audioDiscourseInfo', this.audioDiscourseInfo);
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  /* call play audio method */
  public playedId = 0;
  playAudioSongSliced(songArr, index) {
    songArr = songArr.slice(0, 2);
    this.isPlayed = true;
    this.isPaused = false;

    this.playedId = songArr[index].id;
    this.playAudioInfo = [
      { showPlayerBool: true, songInfo: songArr[index], format: "audio" }
    ]
    //songArr.splice(index, 1);
    for (let i = 0; i < songArr.length; i++) {
      this.playAudioInfo.push(
        { showPlayerBool: true, songInfo: songArr[i], format: "audio" });
      this.songAddToplaylist(songArr[i]);
    }
    this.isInitPlayed = true;
    this.data.changeSong(this.playAudioInfo);
  }

  playAudioSong(songArr, index) {
    console.log('index')
    console.log(index);
    this.isPlayed = true;
    this.isPaused = false;
    console.log('play audio called')

    this.playedId = songArr[index].id;
    this.playAudioInfo = [
      { showPlayerBool: true, songInfo: songArr[index], format: "audio" }
    ];
    this.songAddToplaylist(songArr[index]);
    //songArr.splice(index, 1);
    for (let i = index + 1; i < songArr.length; i++) {
      //this.songAddToplaylist(songArr[i]);
      this.playAudioInfo.push(
        { showPlayerBool: true, songInfo: songArr[i], format: "audio" });

    }
    for (let j = 0; j < index; j++) {
      //this.songAddToplaylist(songArr[j]);
      this.playAudioInfo.push(
        { showPlayerBool: true, songInfo: songArr[j], format: "audio" });
    }
    this.isInitPlayed = true;
    this.data.changeSong(this.playAudioInfo);
  }

  /* add song to playlist */
  songAddToplaylist(songArr) {
    const audioParam = {
      user_id: this.currentUserId,
      track_id: songArr.id,
      track_type: 1
    };
    this.dashboardService.songAddToplaylist(audioParam).subscribe(
      async data => {
        if (data) {
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  /* call pagination method */
  nextPage(): void {
    this.pageNo = this.pageNo + 1;
    this.offset = (this.pageNo * LIMIT);
    if (this.activeTab === 'discourseTab' || this.activeTab === 'discourseTabSonngs') {
      this.getDiscourseAudioSongsList(LIMIT, this.offset, this.searchStr);
    } else {
      this.getAudioSongsList(this.srchString, LIMIT, this.offset, this.categoryId);
    }
  }

  /* Add to Plalist Method */
  sendToPlaylist(songInfo) {
    console.log('send to play list audio comp')
    let playlistParam = [
      { "trackType": 'Jokes', "TrackTypeId": 1, "songId": songInfo.id }
    ];
    this.data.changePlaylist(playlistParam);
  }

  sortTimeline() {
    console.log("in sortTimeline", this.isSort);
    if(this.isSort == false) {
      this.isSort = true;
      this.audioDisCategoryResult.sort((a,b) => a.timeline.localeCompare(b.timeline));
    } else {
      this.isSort = false;
      this.audioDisCategoryResult.sort((a,b) => a.file_name.localeCompare(b.file_name));
    }

  }


  /* Add to Plalist Dis */
  sendToDisPlaylist(songInfo) {
    let playlistParam = [
      { "trackType": 'Jokes', "TrackTypeId": 1, "songId": songInfo.id }
    ];
    this.data.changePlaylist(playlistParam);
  }

  /* Add to Community Method */
  sendToCommunity(songInfo) {
    console.log('send to community audio comp')
    this.modalService.hide(1);
    let communityParam = [
      { "trackType": 'community', "TrackTypeId": 1, "songId": songInfo.id }
    ];
    this.data.changeCommunity(communityParam);
  }

  // Get User subscription 
  // tslint:disable-next-line: variable-name
  async getUserSubscription(user_id) {
    let status = false;
    try {
      this.subscriptionBody = {
        userId: user_id,
        materId: MASTER_SUBSCRIPTION_ID
      }

      let subscription_status = new Promise((resolve, reject) => {
        this.dashboardService.getSubscription(this.subscriptionBody).subscribe(
          async data => {
            console.log('data => ', data);
            if (data.iOSstatus || data.status) {
              status = true;
            } else {
              status = false;
            }
            console.log('STATUS WE GET => ', status);

            resolve(status);
          },
          err => {
            return console.error(err);
          }
        );
      });
      return subscription_status;
    } catch (error) {
      return console.error(error);
    }

  }


  // Payment Popup
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  
  openShareModal(template: TemplateRef<any>, audio) {
    this.shareAudio = audio;
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered' 
    });
  }

}