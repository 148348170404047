import { Injectable } from '@angular/core';
import { DataService } from 'src/app/shared-services/data-service/data.service';

import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private http: HttpClient, private dataService: DataService) {}

  subscribe(data){
    return this.dataService.postService(`/endpoint/Subscription/addSubscription`, data);
  }
  
  getSubscribe(userId){
    return this.dataService.getService(`endpoint/Subscription/subscriptionInfoByUserId/${userId}`);
  }

}
