import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  @Output() onNavClick = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  closeMenu() {
    this.onNavClick.emit(false);
  }
}
