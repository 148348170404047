import { Component, OnInit, NgZone, Renderer2, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
/* popup import files */
import { ViewContainerRef } from '@angular/core';
import { DashboardService } from '../dashboard/dashboard.service';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { environment } from 'src/environments/environment';
import { BootController } from '../../../boot-control';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', '../dashboard/events/people-list/people-list.component.scss', 
  '../dashboard/main-content/main-content.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() isMenuOpen = new EventEmitter<boolean>();
  @Input('isMenuClose')

  set data(isMenuClose: boolean) {
    this.openMenu = isMenuClose;
  }

  communityCategoryResult = [];
  currentUserId: any;
  communityBaseUrl: any;
  showNotifications = false;
  notificationCount = 0;
  openMenu = false;


  constructor(private ngZone: NgZone, private dashboardService: DashboardService,
              private router: Router, private modalDialogService: ModalDialogService, private viewContainer: ViewContainerRef, 
              private renderer: Renderer2, private elRef: ElementRef) {
    const currentuser = JSON.parse(localStorage.getItem('loggedInUser'));
    this.currentUserId = parseInt(currentuser.id);
    this.communityBaseUrl = environment.storageBaseUrl + '/community/images/';
   }

  ngOnInit() {
    this.getNotifications();
  }

  logout() {
    localStorage.removeItem('loggedInUser');

    // Triggers the reboot in main.ts
    this.ngZone.runOutsideAngular(() => BootController.getbootControl().restart());
    this.router.navigate(['/auth']);
  }

  openPromptModal() {
    this.modalDialogService.openDialog(this.viewContainer, {
      title: 'Logout',
      childComponent: SimpleModalComponent,
      data: {
        text: 'Are you sure you want to logout ?'
      },
      settings: {
        closeButtonClass: 'close theme-icon-close',
        headerTitleClass: 'headerWithoutData'
      },
      actionButtons: [
        {
          text: 'Yes',
          buttonClass: 'btn btn-success',
          onAction: () => {
            this.logout();
            return true;
          }
        },
        {
          text: 'No',
          buttonClass: 'btn btn-danger',
          onAction: () => true
        },
      ]
    });
  }

  openPromptModalNotification() {
    alert('Community requests');
  }

  toggleMenu(event: any) {
    if(this.openMenu == false) {
      this.openMenu = true;
      this.isMenuOpen.emit(this.openMenu);
    } else {
      this.openMenu = false;
      this.isMenuOpen.emit(this.openMenu);
    }
  }

  getNotifications() {
    this.dashboardService.getCommunityCategory(this.currentUserId).subscribe(
      async data => {
        if (data) {
          this.communityCategoryResult = [];
          await Promise.all(data.resultObject.map(async (singleData, i) => {
            if (singleData.action == '1' && singleData.request == '1') {
              this.communityCategoryResult.push(singleData);
              this.notificationCount = this.communityCategoryResult.length;
              const currentIndex = this.communityCategoryResult.length - 1;

              if (singleData.image_url) {
                this.communityCategoryResult[currentIndex].coverImage = `${this.communityBaseUrl}${singleData.image_url}`;
              } else {
                this.communityCategoryResult[currentIndex].coverImage = undefined;
              }
            }
          }));
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  acceptRejectRequest(community_id, action) {
    const requestParam = {
      community_id,
      user_id: this.currentUserId,
      action
      };
    this.dashboardService.acceptRejectRequest(requestParam).subscribe(
        async data => {
          this.openSimpleModal(data.message);
        },
        err => {
          return console.error(err);
        });
  }

  openSimpleModal(message) {
    this.modalDialogService.openDialog(this.viewContainer, {
      /* title: 'Simple dialog', */
      childComponent: SimpleModalComponent,
      settings: {
        closeButtonClass: 'fa fa-times'
      },
      data: {
        text: message
      }
    });
  }

}
