import { Component, OnInit, NgZone, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { environment } from 'src/environments/environment';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import * as moment from 'moment';
import { ViewContainerRef } from '@angular/core';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { PeopleListComponent } from '../events/people-list/people-list.component';
import { DataService } from 'src/app/shared-services/data-service/data.service';

const LIMIT = 10;

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss', '../main-content/main-content.component.scss'],
  providers: [DashboardService]
})
export class EventsComponent implements OnInit {

  eventInfo = [];
  modalOption: ModalOptions = {};
  eventBaseUrl: any;
  modalRef: BsModalRef;
  currntdt = new Date();
  selectedEndDateVar: any;
  selectedStartDateVar: any;
  endDateGreaterBool = false;
  isEditing = false;
  currentEventId: any;
  jsStartDate: any;
  jsEndDate: any;
  jsStartDateSeason: any;
  jsEndDateSeason: any;
  uploadedImages: any;
  isImageEdited = false;
  storageBaseUrl: any;
  eventName: '';
  // tslint:disable-next-line:variable-name
  website_link: '';
  eventAddress: '';
  pageNo = 0;
  offset = LIMIT * this.pageNo;
  currentUserId: any;
  weblink: any;
  attendMessage = 'Are you sure you want attend this event?';

  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'mm-dd-yyyy',
    disableUntil: { year: this.currntdt.getFullYear(), month: this.currntdt.getMonth() + 1, day: this.currntdt.getDate() - 1 },
    indicateInvalidDate: true,
    openSelectorOnInputClick: true,
    inline: false,
    editableDateField: false,
  };
  public myDatePickerEndOptions: IMyDpOptions = {
    dateFormat: 'mm-dd-yyyy',
    disableUntil: { year: this.currntdt.getFullYear(), month: this.currntdt.getMonth() + 1, day: this.currntdt.getDate() - 1 },
    indicateInvalidDate: true,
    openSelectorOnInputClick: true,
    inline: false,
    editableDateField: false,
  };
  url: any;

  // tslint:disable-next-line:max-line-length
  constructor(private ngZone: NgZone, private ref: ChangeDetectorRef, private dashboardService: DashboardService, private modalService: BsModalService,
              // tslint:disable-next-line:max-line-length
              private spinnerService: Ng4LoadingSpinnerService, private modalDialogService: ModalDialogService, private viewContainer: ViewContainerRef,
              private data: DataService) {
    this.eventBaseUrl = environment.storageBaseUrl + '/event/';
  }

  onStartDateChanged(event: IMyDateModel) {
    this.jsStartDate = event.formatted;

    /* selected start date */
    this.selectedStartDateVar = event.jsdate;
    this.checkStartAndEndDate();

  }

  openSimpleModal(message) {
    this.modalDialogService.openDialog(this.viewContainer, {
      /* title: 'Simple dialog', */
      childComponent: SimpleModalComponent,
      settings: {
        closeButtonClass: 'fa fa-times'
      },
      data: {
        text: message
      }
    });
  }


  onEndDateChanged(event: IMyDateModel) {
    this.jsEndDate = event.formatted;
    this.selectedEndDateVar = event.jsdate;
    this.checkStartAndEndDate();
  }

  checkStartAndEndDate() {
    /* compare date start date and end date */
    const d1 = new Date(this.selectedStartDateVar);
    const d2 = new Date(this.selectedEndDateVar);
    const same = d1.getTime() === d2.getTime();

    if (d1.getTime() > d2.getTime()) {
      this.endDateGreaterBool = true;
      this.selectedEndDateVar = '';
    } else {
      this.endDateGreaterBool = false;
    }
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    const currentuser = JSON.parse(localStorage.getItem('loggedInUser'));
    // tslint:disable-next-line:radix
    this.currentUserId = parseInt(currentuser.id);
    this.getAllEventsList(LIMIT, this.offset);
  }

  openModal(template: TemplateRef<any>) {
    this.currentEventId = '';
    this.eventName = '';
    this.eventAddress = '';
    this.uploadedImages = '';
    this.jsStartDate = '';
    this.jsEndDate = '';
    this.website_link = '';
    this.isEditing = false;
    this.modalRef = this.modalService.show(template);

  }

  editEventModal(template: TemplateRef<any>, event) {
    this.isImageEdited = false;
    this.dashboardService.getAllEventsById(this.currentUserId).subscribe(
      async data => {
        const result = await data.userobject.filter(userobject => {
          return userobject.id === event.id;
        });

        this.modalRef = this.modalService.show(template, this.modalOption);


        this.eventName = result[0].name;
        this.eventAddress = result[0].address;
        this.jsStartDate = moment(result[0].from_date).format('DD-MM-YYYY');
        this.jsEndDate = moment(result[0].to_date).format('DD-MM-YYYY');
        this.uploadedImages = result[0].image;
        this.weblink = result[0].website_link;
        this.isEditing = true;
        // tslint:disable-next-line:radix
        this.currentEventId = parseInt(result[0].id);
      },
      err => {
        return console.error(err);
      });
  }



  getAllEventsList(limit, offset) {
    this.dashboardService.getAllEventsById(this.currentUserId).subscribe(
      data => {
        this.eventInfo = data.userobject;
        console.log('event data');
        console.log( this.eventInfo);
        const currentIndex = this.eventInfo.length - 1;
        // this.eventInfo[currentIndex].coverImage = `${this.eventBaseUrl}${this.eventInfo[0].image}`;
      },
      err => {
        return console.error(err);
      });
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files[0].type.toString().includes('image')) {
      const file = fileInput.target.files[0];

      const reader = new FileReader();
      reader.onload = e => this.url = reader.result;

      reader.readAsDataURL(file);

      this.uploadedImages = fileInput.target.files;
      this.isImageEdited = true;
      console.log('fileChangeEvent', this.uploadedImages);
    } else {
      this.uploadedImages = '';
      alert('Please attach images only');
    }
  }

  nextPage(): void {
    this.pageNo = this.pageNo + 1;
    this.offset = (this.pageNo * LIMIT);
    this.getAllEventsList(LIMIT, this.offset);
  }

  formatDate(input) {
    return input.split(' ')[0];

  }

  /* create event method */
  createEvent() {
    this.spinnerService.show();
    if (this.isEditing && !this.isImageEdited) {
      let eventParam;
      eventParam = {
        event_id: this.currentEventId,
        user_id: this.currentUserId,
        name: this.eventName,
        address: this.eventAddress,
        image: this.uploadedImages,
        website_link: this.website_link,
        from_date: moment(this.jsStartDate).format('YYYY-MM-DD'),
        to_date: moment(this.jsEndDate).format('YYYY-MM-DD')
      };
      this.dashboardService.updateEvent(eventParam).subscribe(
        async data => {
          if (data) {
            this.modalRef.hide();
            this.openSimpleModal('Event updated successfully.');
            this.pageNo = 0;
            this.offset = LIMIT * this.pageNo;
            this.getAllEventsList(LIMIT, this.offset);
            this.eventName = '';
            this.eventAddress = '';
            this.uploadedImages = '';
          }
        },
        err => {
          return console.error(err);
        });
    } else {
      this.dashboardService.uploadFile(this.uploadedImages).then(res => {
        if (res.upload_data) {
          let uploadedImage = res.upload_data.file_name;
          let eventParam;
          if (this.isEditing) {
            eventParam = {
              event_id: this.currentEventId,
              user_id: this.currentUserId,
              name: this.eventName,
              address: this.eventAddress,
              image: uploadedImage,
              website_link: this.website_link,
              from_date: moment(this.jsStartDate).format('YYYY-MM-DD'),
              to_date: moment(this.jsEndDate).format('YYYY-MM-DD')
            };

            this.dashboardService.updateEvent(eventParam).subscribe(
              async data => {
                if (data) {
                  this.modalRef.hide();
                  this.openSimpleModal('Event updated successfully.');
                  this.pageNo = 0;
                  this.offset = LIMIT * this.pageNo;
                  this.getAllEventsList(LIMIT, this.offset);
                  this.eventName = '';
                  this.eventAddress = '';
                  uploadedImage = '';
                  this.isImageEdited = false;
                }
              },
              err => {
                return console.error(err);
              });

          } else {
            eventParam = {
              user_id: this.currentUserId,
              name: this.eventName,
              address: this.eventAddress,
              image: uploadedImage,
              website_link: this.website_link,
              from_date: moment(this.jsStartDate).format('YYYY-MM-DD'),
              to_date: moment(this.jsEndDate).format('YYYY-MM-DD')
            };

            this.dashboardService.saveEvent(eventParam).subscribe(
              async data => {
                if (data) {
                  this.modalRef.hide();
                  this.openSimpleModal('Event added successfully.');
                  this.pageNo = 0;
                  this.offset = LIMIT * this.pageNo;
                  this.getAllEventsList(LIMIT, this.offset);
                  this.eventName = '';
                  this.eventAddress = '';
                  uploadedImage = '';
                }
              },
              err => {
                return console.error(err);
              });
          }




        }
      });

    }
  }

  /* Interested People list window */
  // tslint:disable-next-line:variable-name
  openPromptModal(event_id) {
    this.modalDialogService.openDialog(this.viewContainer, {
      title: 'People List',
      childComponent: PeopleListComponent,
      settings: {
        closeButtonClass: 'close theme-icon-close',
        headerTitleClass: 'headerWithoutData'
      },
      data: {
        user_id: this.currentUserId,
        id: event_id,
        route_from: 'event'
      },
      actionButtons: [
        {
          text: 'Close',
          buttonClass: 'btn btn-danger',
          onAction: () => true
        },
      ]
    });
  }

  // tslint:disable-next-line:variable-name
  confirmAttend(event_id, attendStatus) {
    if (attendStatus === 'attend') {
      this.attendMessage = 'Are you sure, you want to attend this event?';
    } else if (attendStatus === 'donotattend') {
      this.attendMessage = 'Are you sure, you do not want to attend this event?';
    }

    this.modalDialogService.openDialog(this.viewContainer, {
      title: 'Confirm',
      childComponent: SimpleModalComponent,
      data: {
        text: this.attendMessage
      },
      settings: {
        closeButtonClass: 'close theme-icon-close',
        headerTitleClass: 'headerWithoutData'
      },
      actionButtons: [
        {
          text: 'Yes',
          buttonClass: 'btn btn-success',
          onAction: () => {
            if (attendStatus === 'attend') {
              this.dashboardService.addEventUser({
                user_id: this.currentUserId,
                event_id
              }).subscribe(
                async data => {
                  this.getAllEventsList(LIMIT, this.offset);
                }
              );
            } else {
              this.dashboardService.removeEventUser({
                user_id: this.currentUserId,
                event_id
              }).subscribe(
                async data => {
                  this.getAllEventsList(LIMIT, this.offset);
                });
            }

            return true;
          }
        },
        {
          text: 'No',
          buttonClass: 'btn btn-danger',
          onAction: () => true
        },
      ]

    });
  }

  closeModal() {
    this.currentEventId = '';
    this.eventName = '';
    this.eventAddress = '';
    this.uploadedImages = '';
    this.jsStartDate = '';
    this.jsEndDate = '';
    this.modalRef.hide();
  }

 /* Add to Community Method */
 sendToCommunity(songInfo) {
  console.log('send to community event comp');
  const communityParam = [
    { trackType: 'community', TrackTypeId: 6, songId: songInfo.id }
  ];
  this.data.changeCommunity(communityParam);
}

open(event) {
 console.log(event);
 window.open(event.website_link);
}
}
