import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../../src/app/auth/auth.service';
import { DashboardService } from '../dashboard.service';
import { environment } from 'src/environments/environment';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
/* popup import files */
import { ViewContainerRef } from '@angular/core';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { DataService } from 'src/app/shared-services/data-service/data.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss', '../main-content/main-content.component.scss']
})
export class ProfileComponent implements OnInit {
  
  countryData: any[] = [];
  stateData: any[] = [];
  showStateBool = false;
  currentUserId:any;
  username:any;
  useremail:any;
  usercity:any;
  userphone:any;
  usercountry:any;
  userstate:any;
  uploadedImages: any;
  profileImgRes:any = "";
  profileImgUrl:any;
  showProfileImg:any;

  signupData = {
    'X-API-KEY': 'testkey',
    id:null,
    username: null,
    email: null,
    password: null,
    sanyas_name: null,
    phone_number: null,
    country_id: null,
    state_id: null,
    city: null,
    profile_photo: null
  };

  constructor(private authService: AuthService,private dashboardService: DashboardService,private spinnerService: Ng4LoadingSpinnerService,private modalDialogService: ModalDialogService, private viewContainer: ViewContainerRef,private data: DataService) {
    let currentuser = JSON.parse(localStorage.getItem('loggedInUser'));
    this.currentUserId = parseInt(currentuser.id);
    this.profileImgUrl = environment.storageBaseUrl + '/userprofile/';
   }
  
  async ngOnInit() {
    this.getCountries();
    this.getLoginUserInfo();
  }
  
  
  // Get Countries
  getCountries(): void {
    this.spinnerService.show();
    this.authService.getCountries().subscribe(
      async data => {
        console.log(data);
        if (data.status) {
          this.countryData = data.resultObject;
          console.log(this.countryData);
          this.spinnerService.hide();
        }
      },
      err => {
        return console.error(err);
      }
    );  
  }

  openSimpleModal(message) {
    this.modalDialogService.openDialog(this.viewContainer, {
      /* title: 'Simple dialog', */
      childComponent: SimpleModalComponent,
      settings: {
        closeButtonClass: 'fa fa-times'
      },
      data: {
        text: message
      }
    });
  }

  // Get States
  setStateId(countryId,str): void {
    const stateForm = {
      id: countryId
    };
    this.spinnerService.show();
    this.authService.getStates(stateForm).subscribe(
      async data => {
        console.log(data);
        if (data.status) {
          this.stateData = data.resultObject;
          if(str == "changeState"){
            this.userstate = "";
          }
          this.showStateBool = true;
          this.spinnerService.hide();
        }
      },
      err => {
        return console.error(err);
      }
    );

  }


  getLoginUserInfo(){
    this.spinnerService.show();
    this.dashboardService.getLoginUserInfo(this.currentUserId).subscribe(
      async data => {
        if (data) {
          console.log("User data : ",data)
          this.username = data.username;
          this.useremail = data.email;
          this.usercity = data.city;
          this.userphone = data.phone_number;
          this.usercountry = data.country_id;
          this.setStateId(this.usercountry,"noChanged"); 
          this.userstate = data.state_id;
          if(data.profile_photo == ""){
            this.showProfileImg = "assets/images/6.jpg";  
          }
          else{
            this.showProfileImg = `${this.profileImgUrl}${data.profile_photo}`;
            this.profileImgRes = data.profile_photo;
          }
          
          this.spinnerService.hide();
        }
      },
      err => {
        return console.error(err);
      }
    );
  }


  
  /* upload profile picture */
  fileChangeEvent(fileInput: any) {
    if ( fileInput.target.files[0].type.toString().includes('image')){
      this.uploadedImages = fileInput.target.files;
      console.log('fileChangeEvent', this.uploadedImages);
    }else{
      this.uploadedImages = {};
      alert('Please attach images only')
    }
    
    
  }

  uploadProfilePic(){
    this.dashboardService.uploadProfileFile(this.uploadedImages)
    .then(res => {
      if(res){
        console.log("success image ",res);
        this.profileImgRes =   res.upload_data.file_name;
        this.editProfileSubmit()  ;
        
      }
    });
  }

  submitProfileData(){

    if(this.uploadedImages){
      this.uploadProfilePic();
    }
    else{
      this.editProfileSubmit();
    }
  }



  // editProfileSubmit method
  editProfileSubmit(): void {
    
    this.signupData.username            =  this.username;
    this.signupData.id                  =  this.currentUserId;
    this.signupData.email               =  this.useremail;
    this.signupData.phone_number        =  this.userphone;
    this.signupData.country_id          =  parseInt(this.usercountry);
    this.signupData.state_id            =  parseInt(this.userstate);
    this.signupData.city                =  this.usercity;
    this.signupData.profile_photo       =  this.profileImgRes;
    this.spinnerService.show();
    this.dashboardService.editProfileSubmit(this.signupData).subscribe(
      async data => {
        console.log(data);
        if (data) {
          this.spinnerService.hide();
          this.openSimpleModal("Profile details updated successfully.");
          this.getLoginUserInfo();
        }
      },
      err => {
        return console.error(err);
      }
    );

  }


}