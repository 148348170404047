import { Component, OnInit, ViewChild, ComponentRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataService } from 'src/app/shared-services/data-service/data.service';
import { TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { DashboardService } from '../../dashboard.service';
import { async } from '@angular/core/testing';
import { ViewContainerRef } from '@angular/core';
import { ModalDialogService, SimpleModalComponent, IModalDialogOptions, IModalDialog } from 'ngx-modal-dialog';
import { Subject, of } from 'rxjs';
// import {ToastrService} from 'ngx-toastr';

const initialState = {
  title: "title",
  message: "message",
  options: "options",
};

@Component({
  selector: 'app-people-list',
  templateUrl: './people-list.component.html',
  styleUrls: ['./people-list.component.scss', '../../main-content/main-content.component.scss']
})

export class PeopleListComponent implements OnInit, IModalDialog {

  eventDetails: any;
  peopleList = [];
  routeFrom = '';
  closingSubject$: Subject<void>;
  currentUserId: any;
  profileImgUrl: any;

  dialogInit(reference: ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<any>>) {
    // no processing needed
    this.eventDetails = options.data;
    this.closingSubject$ = options.closeDialogSubject;
  }

  constructor(private data: DataService, private modalService: BsModalService, private spinnerService: Ng4LoadingSpinnerService, private dashboardService: DashboardService, private modalDialogService: ModalDialogService, private viewContainer: ViewContainerRef) {
    this.profileImgUrl = environment.storageBaseUrl + '/userprofile/';
  }

  isModalShown: boolean = false;

  ngOnInit() {
    if (this.eventDetails.route_from == 'event') {
      this.routeFrom = 'event';
      this.dashboardService.getInterestedInEvent(this.eventDetails.id).subscribe(
        async data => {
          this.peopleList = data.resultObject;
        },
        err => {
          return console.error(err);
        });
    } else if(this.eventDetails.route_from == 'community') {
      this.routeFrom = 'community';
      this.currentUserId = this.eventDetails.user_id;
      this.dashboardService.getCommunityPepleList(this.eventDetails.id, '500','0').subscribe(
        async data => {
          // this.peopleList = data.resultObject;
          await Promise.all(data.resultObject.map(async (singleData, i) => {
            if (singleData.action == '2') {
              this.peopleList.push(singleData);  
            }
          }));
        },
        err => {
          return console.error(err);
        });
    } else if(this.eventDetails.route_from == 'community_requests') {
      this.routeFrom = 'community_requests';
      this.currentUserId = this.eventDetails.user_id;
      this.dashboardService.getCommunityPepleList(this.eventDetails.community_id, '500','0').subscribe(
        async data => {
          // this.peopleList = data.resultObject;
          await Promise.all(data.resultObject.map(async (singleData, i) => {
            if (singleData.action == '1') {
              this.peopleList.push(singleData);  
            }
          }));
        },
        err => {
          return console.error(err);
        });
    } else {
      this.routeFrom = 'community_friends';
      this.getUsersToAdd('')
    }
  }

  getUsersToAdd(searchterm) {
    const getUsersParam = {
      searchterm: searchterm,
      limit: 500,
      offset: 0
      }
      this.dashboardService.getAllUsers(getUsersParam).subscribe(
        async data => {
          this.peopleList = data.respon;
        },
        err => {
          return console.error(err);
        });
  }

  // Confirm Dialog - Commented as not working due to one dialog on another dialog
  openPromptModalAddToCommunity(person) {
    this.modalDialogService.openDialog(this.viewContainer, {
      title: 'Add Friend?',
      childComponent: SimpleModalComponent,
      data: {
        text: 'Are you sure you want to add ' + '"' + person.username + '"' + ' ?'
      },
      settings: {
        closeButtonClass: 'close theme-icon-close',
        headerTitleClass: "headerWithoutData"
      },
      actionButtons: [
        {
          text: 'Yes',
          buttonClass: 'btn btn-success',
          onAction: () => {
            this.addToCommunity(person.id);
            return true;
          }
        },
        {
          text: 'No',
          buttonClass: 'btn btn-danger',
          onAction: () => true
        },
      ]
    });
  }

  addToCommunity(user_id) {
    const addUsersParam = {
      parent_user_id: this.eventDetails.user_id,
      community_id: this.eventDetails.community_id,
      user_id: user_id,
      request:1
      }
      this.dashboardService.addUserToCommunity(addUsersParam).subscribe(
        async data => {
          this.closeMe();
          //this.toast.success(data.message);
          //this.openSimpleModal(data.message);
        },
        err => {
          return console.error(err);
        });
  }

  acceptRejectRequest(user_id, action) {
    const requestParam = {
      community_id: this.eventDetails.community_id,
      user_id: user_id,
      action:action
      }
      this.dashboardService.acceptRejectRequest(requestParam).subscribe(
        async data => {
          this.closeMe();
          //this.toast.success(data.message);
          this.openSimpleModal(data.message);
        },
        err => {
          return console.error(err);
        });
  }

  openSimpleModal(message) {
    this.modalDialogService.openDialog(this.viewContainer, {
      /* title: 'Simple dialog', */
      childComponent: SimpleModalComponent,
      settings: {
        closeButtonClass: 'fa fa-times'
      },
      data: {
        text: message
      }
    });
  }

  closeMe() {
    if (this.closingSubject$) {
      this.closingSubject$.next();
    }
  }

  searchByKey(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.getUsersToAdd(filterValue);
  }


}
