import { Component, OnInit, ComponentRef } from '@angular/core';
import { IModalDialog, IModalDialogOptions } from 'ngx-modal-dialog';
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss', '../main-content/main-content.component.scss']
})
export class AboutComponent implements OnInit, IModalDialog {
  activeTab = 'qAndATab';

  constructor() { }
  dialogInit(reference: ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<any>>) {
    // no processing needed
  }

  ngOnInit() {
    window.scrollTo(0, 0)
  }

  tabChange(tab) {
    this.activeTab = tab;
  }
}
