import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DashboardService } from '../dashboard.service';
import { TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { CarouselComponent } from 'ngx-bootstrap';

const LIMIT = 6;

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss', '../main-content/main-content.component.scss']
})
export class ProductsComponent implements OnInit {

  @ViewChild('previewSlider') previewSlider: CarouselComponent;
  
  modalRef: BsModalRef;
  productBaseUrl: any;
  currentUserId: number;
  noRecordBool: boolean;
  productList = [];
  offset = 0;
  productTitle: any;
  productdescription: any;
  productId: any;
  coverImage: any;
  imageList: any = [];
  img: any;
  imgArray:any=[];
  sliderSliderIndexPos = 0;
  showImgModalBool = false;
  previewCarouselBool = false;
  constructor(private dashboardService: DashboardService, private modalService: BsModalService) {
    this.productBaseUrl = environment.storageBaseUrl + "/product";

  }

  ngOnInit() {
    window.scrollTo(0, 0)
    let currentuser = JSON.parse(localStorage.getItem('loggedInUser'));
    this.currentUserId = parseInt(currentuser.id);
    this.getAllProductList(LIMIT, this.offset);
  }

  getAllProductList(LIMIT, offset) {
    let productParam = {
      "searchterm": "",
      "limit": 200,
      "offset": 0
    };
    this.noRecordBool = false;
    this.dashboardService.getAllProductList(productParam).subscribe(
      async data => {
        if (data) {
          if (data.respon.length == 0) {
            this.noRecordBool = true;
          }
          await Promise.all(data.respon.map(async (singleData, i) => {
            singleData.images.map((singleImage) => {
              singleImage.image_url = `${this.productBaseUrl}/${singleImage.url}`;
            });
            this.productList.push(singleData);
          }));
          console.log('data', this.productList);
        }
      }
    );
  }

  productDetailsModal(template: TemplateRef<any>, product,i) {
    this.imgArray.length = 0;
    this.imgArray.push(this.productList[i].images);
    this.modalRef = this.modalService.show(template);
    this.productTitle = product.title;
    this.productdescription = product.description;
    this.productId = product.id;
    this.coverImage = product.coverImage;

  }

  closeModal() {
    this.modalRef.hide();
  }

  showImgInModal(index){
    this.sliderSliderIndexPos = index;
    this.showImgModalBool = true;
    this.previewCarouselBool = true;
    setTimeout(() => {
      this.previewCarouselBool = false;
      this.previewSlider.selectSlide(index);      
    }, 200);
  }

  closeImgModal(){
    this.showImgModalBool = false;
  }
}
