import { Injectable } from '@angular/core';
import { DataService } from 'src/app/shared-services/data-service/data.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  eventImgUrl: any;
  profileImgUrl: any;
  communityImgUrl: any;

  constructor(private dataService: DataService) {
    this.eventImgUrl = environment.baseUrl + '/endpoint/event/upload/';
    this.profileImgUrl = environment.baseUrl + '/endpoint/appusers/upload/';
    this.communityImgUrl = environment.baseUrl + '/endpoint/community/upload/';
  }

  // get banner images
  getRecentlyPlayedSongs(idVal, limitVal, offsetVal) {
    return this.dataService.getService(`/endpoint/recent_playtrack/recent_play/?id=${idVal}&limit=${limitVal}&offset=${offsetVal}`);
  }



  /* audio section */

  getqAndaAudioCategory() {
    return this.dataService.getService(`/endpoint/videocast/allmediatype?limit=0&offset=0`);
  }

  getAudioSongsList(param) {
    return this.dataService.postService(`/endpoint/track/searchquedisc/`, param);
  }

  getDiscourseAudioCategory(param) {
    return this.dataService.postService(`/endpoint/track/searchquedisc/`, param);
  }

  getDiscourseAudioSongsList(param) {
    return this.dataService.postService(`/endpoint/track/search/`, param);
  }

  /* event section */
  getAllEventsList(limitVal, offsetVal) {
    return this.dataService.getService(`/endpoint/event/all?limit=${limitVal}&offset=${offsetVal}`);
  }

  getAllEventsById(param) {
    return this.dataService.getService(`/endpoint/event/alleventbyusrid/` + param);
  }

  updateEvent(param) {
    return this.dataService.postService(`/endpoint/event/updateevent/`, param);
  }

  /* event section */
  getAllNewsList(param) {
    return this.dataService.postService(`/endpoint/news/search/`, param);
  }

  getAllProductList(Param) {
    return this.dataService.postService(`/endpoint/product/search/`, Param);
  }

  getInterestedInEvent(param) {
    return this.dataService.getService(`/endpoint/event/alluserbyeventid/` + param);
  }

  addEventUser(param) {
    return this.dataService.postService(`/endpoint/event/addeventuser/`, param);
  }

  removeEventUser(param) {
    return this.dataService.postService(`/endpoint/event/removeeventuser/`, param);
  }

  /* video section */

  getqAndaVideoCategory() {
    return this.dataService.getService(`/endpoint/videocast/allmediatype?limit=0&offset=0`);
  }
  getVideoSongsList(param) {
    return this.dataService.postService(`/endpoint/videocast/searchquedisc/`, param);
  }
  getDiscourseVideoCategory(param) {
    return this.dataService.postService(`/endpoint/videocast/searchquedisc/`, param);
  }
  getDiscourseVideoSongsList(param) {
    return this.dataService.postService(`/endpoint/videocast/searchquedisc/`, param);
  }

  /* meditation section */

  getAllMeditationList(param) {
    return this.dataService.postService(`/endpoint/showmeditations/search/`, param);
  }

  getMeditationSongsList(param) {
    return this.dataService.postService(`/endpoint/meditation/search/`, param);
  }

  meditationWorkshopSongsList(param) {
    return this.dataService.postService(`/endpoint/meditation/search/`, param);
  }
  getMeditationTechSongsList(param) {
    return this.dataService.postService(`/endpoint/meditation/search/`, param);
  }

  /* Interview section */
  getInterviewSongsList(param) {
    return this.dataService.postService(`/endpoint/interview/search/`, param);
  }

  getInterviewVideoList(param) {
    return this.dataService.postService(`/endpoint/interviewvideos/search/`, param);
  }

  /* jokes section */
  getJokesSongsList(param) {
    return this.dataService.postService(`/endpoint/joke/search/`, param);
  }

  /* music secton */
  getMediationMusicSongsList(param) {
    return this.dataService.postService(`/endpoint/music/search/`, param);
  }
  getBhajanMusicSongsList(param) {
    return this.dataService.postService(`/endpoint/bhajan/search/`, param);
  }
  getSatsangMusicSongsList(param) {
    return this.dataService.postService(`/endpoint/satsang/search/`, param);
  }

  /* playlist section */
  getAllPlaylist(param) {
    return this.dataService.postService(`/endpoint/playlist/alluserplaylist/`, param);
  }
  createPlaylist(param) {
    return this.dataService.postService(`/endpoint/playlist/add/`, param);
  }
  deleteSinglePlaylist(param) {
    return this.dataService.postService(`/endpoint/playlist/delete/`, param);
  }
  /* event section */
  showPlaylistSongs(id) {
    return this.dataService.getService(`/endpoint/playtrack/alltrack/?id=` + id);
  }

  /* Add to playlist section */
  addToplayList(param) {
    return this.dataService.postService(`/endpoint/playtrack/add/`, param);
  }

  /* save event section */
  saveEvent(param) {
    return this.dataService.postService(`/endpoint/event/addevent/`, param);
  }

  uploadEventFile(param) {
    const formData = new FormData();
    formData.append('file', param);
    return this.dataService.fileService(`/endpoint/event/upload/`, param);
  }

  /* get current logged in user info */
  getLoginUserInfo(param) {
    return this.dataService.getService(`/endpoint/appusers/userinfo/${param}`);
  }

  /* edit profile  section */
  editProfileSubmit(param) {
    return this.dataService.postService(`/endpoint/appusers/update/`, param);
  }

  /* edit profile  section */
  getOshoCenters(param) {
    return this.dataService.postService(`/endpoint/country/getAllAshramByStateId/`, param);
  }

  /* song add to playlist section */
  songAddToplaylist(param) {
    return this.dataService.postService(`/endpoint/recent_playtrack/add/`, param);
  }

  /* Community section */
  getCommunityCategory(id) {
    return this.dataService.getService(`/endpoint/community/allcombyusrid/${id}`);
  }

  addCommunity(param) {
    return this.dataService.postService(`/endpoint/community/addcom`, param);
  }

  deleteSingleCommunity(param) {
    return this.dataService.postService(`/endpoint/community/deletecom`, param);
  }

  updateCommunity(param) {
    return this.dataService.postService(`/endpoint/community/updatecom`, param);
  }

  searchCommunity(param) {
    return this.dataService.postService(`/endpoint/community/search/`, param);
  }

  uploadCommunityFile(param) {
    const formData = new FormData();
    formData.append('file', param);
    return this.dataService.fileService(`/endpoint/community/upload/`, param);
  }

  getCommunityData(id, limitVal, offsetVal) {
    return this.dataService.getService(`/endpoint/community/allpostbycommunityid?id=${id}&limit=${limitVal}&offset=${offsetVal}`);
  }

  addToCommunity(param) {
    return this.dataService.postService(`/endpoint/community/addcompost`, param);
  }

  getCommunityPepleList(communityId, limitVal, offsetVal) {
    return this.dataService.getService(`/endpoint/community/allcomusrbycomid?id=${communityId}&limit=${limitVal}&offset=${offsetVal}`);
  }

  getCommunityComments(communityId, limitVal, offsetVal) {
    // tslint:disable-next-line:max-line-length
    return this.dataService.getService(`/endpoint/community/allcommentpostbycommunityid?id=${communityId}&limit=${limitVal}&offset=${offsetVal}`);
  }

  addCommunityCommentPost(param) {
    return this.dataService.postService(`/endpoint/community/addcomcommentpost`, param);
  }

  getAllUsers(param) {
    return this.dataService.postService(`/endpoint/appusers/search`, param);
  }

  getAllPdf(param) {
    return this.dataService.postService(`/endpoint/onlinemagazines/search`, param);
  }

  addUserToCommunity(param) {
    return this.dataService.postService(`/endpoint/community/addusrtocom`, param);
  }

  acceptRejectRequest(param) {
    return this.dataService.postService(`/endpoint/community/acceptrejectcomrequest`, param);
  }
  /* Community section end */

  /* get subscription */
  getSubscription(param) {
    return this.dataService.postService(`/endpoint/subscription/userSubscriptionByUserId/`, param);
  }

  uploadFile(files): Promise<any> {

    const data = new FormData();
    if (files.__proto__.constructor.name === 'File') {
      data.append('file', files);
    } else {
      // tslint:disable-next-line:forin
      for (const i in files) {
        data.append('file', files[i]);
      }
    }

    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response) as any);
          } else {
            reject(xhr.response);
          }
        }
      };
      xhr.open('POST', this.communityImgUrl, true);
      // xhr.setRequestHeader();
      xhr.send(data);
    });
  }


  /* upload profile image */
  uploadProfileFile(files): Promise<any> {

    const data = new FormData();
    if (files.__proto__.constructor.name === 'File') {
      data.append('file', files);
    } else {
      // tslint:disable-next-line:forin
      for (const i in files) {
        data.append('file', files[i]);
      }
    }

    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response) as any);
          } else {
            reject(xhr.response);
          }
        }
      };
      xhr.open('POST', this.profileImgUrl, true);
      // xhr.setRequestHeader();
      xhr.send(data);
    });
  }


  //audio and video api 
  public getaudioorvideo(param) {
    return this.dataService.postService(`/endpoint/appusers/getav/`, param);
  }

  public getAvLink(param) {
    return this.dataService.postService(`/endpoint/appusers/generatelink/`, param);
  }


}
