import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../dashboard/dashboard.service';
import { environment } from 'src/environments/environment';
import { ModalDialogService, SimpleModalComponent} from 'ngx-modal-dialog';
import { ViewContainerRef } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  requestList = [];
  currentUserId: any;
  communityBaseUrl: any;
  communityCategoryResult = [];

  constructor(private dashboardService: DashboardService, private modalDialogService: ModalDialogService, private viewContainer: ViewContainerRef) {
    let currentuser = JSON.parse(localStorage.getItem('loggedInUser'));
    this.currentUserId = parseInt(currentuser.id);
    this.communityBaseUrl = environment.storageBaseUrl + '/community/images/';
   }

  ngOnInit() {
    this.getCommunityRequests();
  }

  getCommunityRequests() {
    this.dashboardService.getCommunityCategory(this.currentUserId).subscribe(
      async data => {
        if (data) {
          await Promise.all(data.resultObject.map(async (singleData, i) => {
            if (singleData.action == '1' && singleData.request == '1') {
              this.communityCategoryResult.push(singleData);
              const currentIndex = this.communityCategoryResult.length - 1;

              if (singleData.image_url) {
                this.communityCategoryResult[currentIndex].coverImage = `${this.communityBaseUrl}${singleData.image_url}`;
              } else {
                this.communityCategoryResult[currentIndex].coverImage = undefined;
              }
            }
          }));
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  acceptRejectRequest(community_id, user_id, action) {
    const requestParam = {
      community_id: community_id,
      user_id: user_id,
      action:action
      }
      this.dashboardService.acceptRejectRequest(requestParam).subscribe(
        async data => {
          this.openSimpleModal(data.message);
        },
        err => {
          return console.error(err);
        });
  }

  openSimpleModal(message) {
    this.modalDialogService.openDialog(this.viewContainer, {
      /* title: 'Simple dialog', */
      childComponent: SimpleModalComponent,
      settings: {
        closeButtonClass: 'fa fa-times'
      },
      data: {
        text: message
      }
    });
  }

}
