import { Component, OnInit, TemplateRef } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { environment } from 'src/environments/environment';
import { Track } from 'ngx-audio-player';
import { DataService } from 'src/app/shared-services/data-service/data.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

import { NgNavigatorShareService } from 'ng-navigator-share';


const LIMIT = 10;

@Component({
  selector: 'app-music',
  templateUrl: './music.component.html',
  styleUrls: ['./music.component.scss', '../main-content/main-content.component.scss']
})
export class MusicComponent implements OnInit {

  audioBaseUrl: any;
  pageNo = 0;
  offset = LIMIT * this.pageNo;
  activeTab = 'MeditationMusic';
  playAudioInfo = [];
  storageBaseUrl: any;

  meditationMusicInfo = [];
  bhajanKirtanMusicInfo = [];
  eveningSatsangMusicInfo = [];
  noRecordBool = false;
  currentUserId: any;
  selectViewOption = [
    { id: 1, name: "More" },
    { id: 2, name: "Less" },
  ];

  isInitPlayed = false;
  isPaused = false;
  isPlayed = false;

  searchStr = '';
  srchString = '';
  timeout = null;
  isSearching = false;
  modalRef: BsModalRef;
  shareAudio = [];

  constructor(private dashboardService: DashboardService, private data: DataService, private spinnerService: Ng4LoadingSpinnerService, 
    private ngNavigatorShareService: NgNavigatorShareService, private modalService: BsModalService) {
    this.audioBaseUrl = environment.storageBaseUrl + '/audios/cover/';
    let currentuser = JSON.parse(localStorage.getItem('loggedInUser'));
    this.currentUserId = parseInt(currentuser.id);
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getMediationMusicSongsList('', LIMIT, this.offset);

    this.data.pauseSong.subscribe(songName => {
      console.log('init audio comp');
      if (songName.toString() === 'true') {
        this.isPaused = true;
        this.isPlayed = false;
      } else {
        this.isPaused = false;
        this.isPlayed = true;
      }
      console.log(this.isPaused);
    });
  }

  tabChange(tab) {
    this.srchString = '';
    this.activeTab = tab;
    this.pageNo = 0;
    this.offset = LIMIT * this.pageNo;
    if (this.activeTab === 'MeditationMusic') {
      this.meditationMusicInfo = [];
      this.getMediationMusicSongsList('', LIMIT, this.offset);
    } else if (this.activeTab === 'BhajanAndkirtan') {
      this.bhajanKirtanMusicInfo = [];
      this.getBhajanMusicSongsList('', LIMIT, this.offset);
    } else if (this.activeTab === 'EveningSatsang') {
      this.eveningSatsangMusicInfo = [];
      this.getSatsangMusicSongsList('', LIMIT, this.offset);
    }
  }

  /* get meditation songs */
  getMediationMusicSongsList(searchName, LIMIT, offset) {
    const audioParam = {
      searchterm: searchName,
      limit: LIMIT,
      offset: offset
    }
    this.spinnerService.show();
    this.noRecordBool = false;
    this.dashboardService.getMediationMusicSongsList(audioParam).subscribe(
      async data => {
        if (data) {
          if (data.respon.length == 0) {
            this.noRecordBool = true;
          }
          this.spinnerService.hide();
          await Promise.all(data.respon.map(async (singleData, i) => {
            this.meditationMusicInfo.push(singleData);
            const currentIndex = this.meditationMusicInfo.length - 1;
            this.meditationMusicInfo[currentIndex].coverImage = `${this.audioBaseUrl}${singleData.cover}`;
          }));
          console.log('interviewSongsInfo data', this.meditationMusicInfo);
        }
      },
      err => {
        this.spinnerService.hide();
        return console.error(err);
      }
    );
  }

  /* get bhajan songs */
  getBhajanMusicSongsList(searchName, LIMIT, offset) {
    const audioParam = {
      searchterm: searchName,
      limit: LIMIT,
      offset: offset
    }
    this.spinnerService.show();
    this.noRecordBool = false;
    this.dashboardService.getBhajanMusicSongsList(audioParam).subscribe(
      async data => {
        if (data) {
          if (data.respon.length == 0) {
            this.noRecordBool = true;
          }
          this.spinnerService.hide();
          await Promise.all(data.respon.map(async (singleData, i) => {
            this.bhajanKirtanMusicInfo.push(singleData);
            const currentIndex = this.bhajanKirtanMusicInfo.length - 1;
            this.bhajanKirtanMusicInfo[currentIndex].coverImage = `${this.audioBaseUrl}${singleData.cover}`;
          }));
          console.log('bhajanKirtanMusicInfo data', this.bhajanKirtanMusicInfo);
        }
      },
      err => {
        this.spinnerService.hide();
        return console.error(err);
      }
    );
  }

  /*added  */

  onOptionsFilterDiscourseSelected(event) {
    if (event.target.value == 'More') {
      this.pageNo = 0;
      this.offset = LIMIT * this.pageNo;

    } else if (event.target.value == 'Hindi') {
      this.pageNo = 0;
      this.offset = LIMIT * this.pageNo;

    }
  }
  /* get bhajan songs */
  getSatsangMusicSongsList(searchName, LIMIT, offset) {
    const audioParam = {
      searchterm: searchName,
      limit: LIMIT,
      offset: offset
    }
    this.spinnerService.show();
    this.noRecordBool = false;
    this.dashboardService.getSatsangMusicSongsList(audioParam).subscribe(
      async data => {
        if (data) {
          if (data.respon.length == 0) {
            this.noRecordBool = true;
          }
          this.spinnerService.hide();
          await Promise.all(data.respon.map(async (singleData, i) => {
            this.eveningSatsangMusicInfo.push(singleData);
            const currentIndex = this.eveningSatsangMusicInfo.length - 1;
            this.eveningSatsangMusicInfo[currentIndex].coverImage = `${this.audioBaseUrl}${singleData.cover}`;
          }));
          console.log('eveningSatsangMusicInfo data', this.eveningSatsangMusicInfo);
        }
      },
      err => {
        this.spinnerService.hide();
        return console.error(err);
      }
    );
  }

  searchSongByKey(searchName) {
    let refObj = this;
    refObj.srchString = searchName;
    if (refObj.activeTab === 'MeditationMusic') {
      refObj.meditationMusicInfo = [];
    } else if (refObj.activeTab === 'BhajanAndkirtan') {
      refObj.bhajanKirtanMusicInfo = [];
    } else if (refObj.activeTab === 'EveningSatsang') {
      refObj.eveningSatsangMusicInfo = [];
    }
    clearTimeout(refObj.timeout);
    // Make a new timeout set to go off in 500ms
    refObj.timeout = setTimeout(function () {
      console.log('Input Value:', refObj.srchString);
      if (refObj.srchString.length === 0) {
        refObj.srchString = '';
        refObj.isSearching = false;
        refObj.pageNo = 0;
        refObj.offset = LIMIT * refObj.pageNo;
        if (refObj.activeTab === 'MeditationMusic') {
          refObj.getMediationMusicSongsList(refObj.srchString, LIMIT, refObj.offset);
        } else if (refObj.activeTab === 'BhajanAndkirtan') {
          refObj.getBhajanMusicSongsList(refObj.srchString, LIMIT, refObj.offset);
        } else if (refObj.activeTab === 'EveningSatsang') {
          refObj.getSatsangMusicSongsList(refObj.srchString, LIMIT, refObj.offset);
        }
      } else {
        refObj.isSearching = true;
        refObj.pageNo = 0;
        refObj.offset = LIMIT * refObj.pageNo;
        if (refObj.activeTab === 'MeditationMusic') {
          refObj.getMediationMusicSongsList(refObj.srchString, LIMIT, refObj.offset);
        } else if (refObj.activeTab === 'BhajanAndkirtan') {
          refObj.getBhajanMusicSongsList(refObj.srchString, LIMIT, refObj.offset);
        } else if (refObj.activeTab === 'EveningSatsang') {
          refObj.getSatsangMusicSongsList(refObj.srchString, LIMIT, refObj.offset);
        }
      }
    }, 500);
  }

  /* call play audio method */
  public playedId = 0;
  playAudioSong(songArr, musicType, index) {
    this.isPlayed = true;
    this.isPaused = false;

    this.playedId = songArr[index].id;
    this.playAudioInfo = [
      { showPlayerBool: true, songInfo: songArr[index], format: 'audio' }
    ];

    if (musicType == "meditation") {
      let trackType = 3;
      this.songAddToplaylist(songArr[index], trackType);
    }
    if (musicType == "bhajan") {
      let trackType = 6;
      this.songAddToplaylist(songArr[index], trackType);
    }
    if (musicType == "satsang") {
      let trackType = 7;
      this.songAddToplaylist(songArr[index], trackType);
    }
    for (let i = index + 1; i < songArr.length; i++) {
      this.playAudioInfo.push(
        { showPlayerBool: true, songInfo: songArr[i], format: 'audio' });

    }
    for (let j = 0; j < index; j++) {
      this.playAudioInfo.push(
        { showPlayerBool: true, songInfo: songArr[j], format: 'audio' });
    }
    this.isInitPlayed = true;
    this.data.changeSong(this.playAudioInfo);
  }

  /* add song to playlist */
  songAddToplaylist(songArr, trackType) {
    const audioParam = {
      user_id: this.currentUserId,
      track_id: songArr.id,
      track_type: trackType
    };
    this.dashboardService.songAddToplaylist(audioParam).subscribe(
      async data => {
        if (data) {
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  /* call pagination method */
  nextPage(): void {
    this.pageNo = this.pageNo + 1;
    this.offset = (this.pageNo * LIMIT);
    if (this.activeTab === 'MeditationMusic') {
      this.getMediationMusicSongsList(this.srchString, LIMIT, this.offset);
    } else if (this.activeTab === 'BhajanAndkirtan') {
      this.getBhajanMusicSongsList(this.srchString, LIMIT, this.offset);
    }
    else if (this.activeTab === 'EveningSatsang') {
      this.getSatsangMusicSongsList(this.srchString, LIMIT, this.offset);
    }
  }

  /* call pagination method */
  previousPage(): void {
    this.pageNo = this.pageNo - 1;
    this.offset = (this.pageNo * LIMIT);
    if (this.activeTab === 'MeditationMusic') {
      this.getMediationMusicSongsList(this.srchString, LIMIT, this.offset);
    } else if (this.activeTab === 'BhajanAndkirtan') {
      this.getBhajanMusicSongsList(this.srchString, LIMIT, this.offset);
    }
    else if (this.activeTab === 'EveningSatsang') {
      this.getSatsangMusicSongsList(this.srchString, LIMIT, this.offset);
    }
  }


  /* Add to Plalist Method */
  sendToPlaylist(songInfo) {
    let playlistParam = [
      { "trackType": 'Jokes', "TrackTypeId": 3, "songId": songInfo.id }
    ];
    this.data.changePlaylist(playlistParam);
  }

  sendToBhajanPlaylist(songInfo) {
    let playlistParam = [
      { "trackType": 'Jokes', "TrackTypeId": 6, "songId": songInfo.id }
    ];
    this.data.changePlaylist(playlistParam);
  }

  sendToSatsungPlaylist(songInfo) {
    let playlistParam = [
      { "trackType": 'Jokes', "TrackTypeId": 7, "songId": songInfo.id }
    ];
    this.data.changePlaylist(playlistParam);
  }

  /* Add to Community Method */
  sendToCommunity(songInfo) {
    console.log('send to community audio comp')
    this.modalService.hide(1);
    let communityParam = [
      { "trackType": 'community', "TrackTypeId": 3, "songId": songInfo.id }
    ];
    this.data.changeCommunity(communityParam);
  }

  sendToBhajanCommunity(songInfo) {
    console.log('send to community audio comp')
    let communityParam = [
      { "trackType": 'community', "TrackTypeId": 8, "songId": songInfo.id }
    ];
    this.data.changeCommunity(communityParam);
  }

  sendToSatsungCommunity(songInfo) {
    console.log('send to community audio comp')
    let communityParam = [
      { "trackType": 'community', "TrackTypeId": 9, "songId": songInfo.id }
    ];
    this.data.changeCommunity(communityParam);
  }

  shareToSocial(audioInfo) {
    console.log("in audio", audioInfo );
    // if (!this.ngNavigatorShareService.canShare()) {
    //   alert(`This service/api is not supported in your Browser`);
    //   return;
    // }
    this.spinnerService.show();
    
    const reqObj={
      av_id: parseInt(audioInfo.id),
      user_id: this.currentUserId,
      media_type: parseInt(audioInfo.category),
      media_category: 3
    }
    this.dashboardService.getAvLink(reqObj).subscribe((res:any) =>{
      console.log("link", res.respon);

      this.ngNavigatorShareService.share({
        title: res.respon.name,
        text: res.respon.name,
        url: res.respon.link
      }).then( (response) => {
        console.log(response);
        this.modalService.hide(1);
        this.spinnerService.hide();
      })
      .catch( (error) => {
        this.spinnerService.hide();
        alert("Something went wrong! Please try after sometime");
      });

      

    },(error)=>{
      console.log("error", error);
    });
    
  }

  openShareModal(template: TemplateRef<any>, audio) {
    this.shareAudio = audio;
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered' 
    });
  }


}
