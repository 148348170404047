import { Injectable } from '@angular/core';
import { DataService } from 'src/app/shared-services/data-service/data.service';

import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionNewService {

  constructor(private http: HttpClient, private dataService: DataService) {}

  subscribe(data){
    return this.dataService.postService(`/endpoint/Subscription/addSubscription`, data);
  }
  
  getSubscribe(userId){
    return this.dataService.getService(`endpoint/Subscription/getSubscriptionDetails/${userId}`);
  }

  createCustomer(data){
    return this.dataService.postService('endpoint/Subscription/createCustomer', data);
  } 
  
  startSubscription(data) {
    return this.dataService.postService('endpoint/Subscription/createSubscription', data);
  }

  createPaymentMethodForCustomer(data) {
    return this.dataService.postService('endpoint/Subscription/createPaymentMethod', data);
  }

  cancelSubscription(subscriptionId: string, userId) {
    return this.dataService.postService('endpoint/Subscription/cancelSubscription', { subscription_id: subscriptionId, userId: userId });
  }

  handlePaymentFailed(subscriptionId, userId) {
    return this.dataService.postService('endpoint/Subscription/handlePaymentFailed', { subscription_id: subscriptionId, userId: userId });
  }

}
