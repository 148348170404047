import { Injectable } from '@angular/core';
import { DataService } from 'src/app/shared-services/data-service/data.service';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  constructor(private dataService: DataService) { }

  // get banner images
  getBannerImages() {
    return this.dataService.getService(`/endpoint/slider/all?limit=10&offset=0`);
  }
}
