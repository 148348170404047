import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LoginComponent } from './login/login.component';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  modalRef: BsModalRef;
  config = {
    animated: true
  };
  currentYear: number=new Date().getFullYear();

  constructor(private modalService: BsModalService, private router: Router) { 
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as {
      openLogin: boolean,
    };
    if( undefined !== state && true == state.openLogin ) {
      this.openPopup('login');
    }
  }

  ngOnInit() {
  }

  getCurrentYear() {
    return this.currentYear;
  }

  openPopup(window) {
    const initialState = {
      windowName: window,
      animated: true,
      keyboard: true
    };
    this.modalRef = this.modalService.show(LoginComponent, { initialState });
    this.modalRef.setClass('auth__modal-dialog');
    this.modalRef.content.closeBtnName = 'Close';
  }

}
