import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ask-osho',
  templateUrl: './ask-osho.component.html',
  styleUrls: ['./ask-osho.component.scss']
})
export class AskOshoComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {
    if(!localStorage.getItem('loggedInUser')){
      this.router.navigate(['/auth']);
    }
  }
  

}
