import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { AskOshoComponent } from './ask-osho/ask-osho.component';
import { HeaderComponent } from './ask-osho/header/header.component';
import { FooterComponent } from './ask-osho/footer/footer.component';
import { DashboardComponent } from './ask-osho/dashboard/dashboard.component';
import { SideMenuComponent } from './ask-osho/dashboard/side-menu/side-menu.component';

import { MusicPlayerComponent } from './ask-osho/dashboard/music-player/music-player.component';
import { ProfileInfoComponent } from './ask-osho/header/profile-info/profile-info.component';
import { NotificationComponent } from './ask-osho/header/notification/notification.component';
import { LoginComponent } from './auth/login/login.component';
import { SignupComponent } from './auth/signup/signup.component';
import { ForgetPasswordComponent } from './auth/forget-password/forget-password.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { BannerComponent } from './ask-osho/dashboard/banner/banner.component';

import { CarouselModule as ngxCarouselModule } from 'ngx-bootstrap';
import { DataService } from './shared-services/data-service/data.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorsService } from './shared-services/interceptor/http-interceptors.service';
import { ManagePlaylistComponent } from './ask-osho/dashboard/manage-playlist/manage-playlist.component';

import { AboutComponent } from './ask-osho/dashboard/about/about.component';
import { PeopleListComponent } from './ask-osho/dashboard/events/people-list/people-list.component';
import { ContactUsComponent } from './ask-osho/dashboard/contact-us/contact-us.component';
import { HowToUseComponent } from './ask-osho/dashboard/how-to-use/how-to-use.component';
import { RecentlyPlayedComponent } from './ask-osho/dashboard/recently-played/recently-played.component';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { SocialShareComponent } from './ask-osho/dashboard/social-share/social-share.component';

import { NgxSoundmanager2Module } from 'ngx-soundmanager2';
import { MatVideoModule } from 'mat-video';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MyDatePickerModule } from 'mydatepicker';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ModalDialogModule } from 'ngx-modal-dialog';
import { OshoCenterComponent } from './ask-osho/dashboard/osho-center/osho-center.component';
import { ProfileComponent } from './ask-osho/dashboard/profile/profile.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TermsComponent } from './auth/terms/terms.component';
import { ProductsComponent } from './ask-osho/dashboard/products/products.component';
import { LightboxModule } from 'ngx-lightbox';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { DonationComponent } from './ask-osho/donation/donation.component';
import { InstructionsComponent } from './ask-osho/instructions/instructions.component';
import { DownloadComponent } from './ask-osho/download/download.component';
import { MagazinesPodcastComponent } from './ask-osho/dashboard/magazines-podcast/magazines-podcast.component';
import { AudioVideoplayComponent } from './audio-videoplay/audio-videoplay.component';
import { SwiperModule } from "swiper/angular";
import { NgxStripeModule } from 'ngx-stripe';
import { SubscriptionComponent } from './ask-osho/dashboard/subscription/subscription.component'
import { SubscriptionNewComponent } from './ask-osho/dashboard/subscription-new/subscription-new.component'
// import { ToastrModule } from 'ngx-toastr';


@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    AskOshoComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    SideMenuComponent,

    MusicPlayerComponent,
    ProfileInfoComponent,
    NotificationComponent,
    LoginComponent,
    SignupComponent,
    ForgetPasswordComponent,
    ChangePasswordComponent,
    BannerComponent,
    routingComponents,
    AboutComponent,
    SubscriptionComponent,
    SubscriptionNewComponent,
    PeopleListComponent,
    ContactUsComponent,
    HowToUseComponent,
    RecentlyPlayedComponent,
    SocialShareComponent,
    ManagePlaylistComponent,
    OshoCenterComponent,
    ProfileComponent,
    TermsComponent,
    ProductsComponent,
    TermsAndConditionsComponent,
    DonationComponent,
    InstructionsComponent,
    DownloadComponent,
    MagazinesPodcastComponent,
    AudioVideoplayComponent
  ],
  entryComponents: [PeopleListComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CarouselModule,
    NgxAudioPlayerModule,
    BrowserAnimationsModule,
    NgxSoundmanager2Module.forRoot(),
    MatVideoModule,
    Ng4LoadingSpinnerModule.forRoot(),
    ModalModule.forRoot(),
    FormsModule,
    MyDatePickerModule ,
    ngxCarouselModule,
    ModalDialogModule.forRoot(),
    BsDropdownModule.forRoot(),
    LightboxModule,
    SwiperModule,
    NgxStripeModule.forRoot('pk_live_HWbArqG6EA1pyMEkrRsmF15e'), 
    // NgxStripeModule.forRoot('pk_test_51O7L6wCEqapLKj8TCaHE1K8rIjayFeJxJHvJTXULK8nahV64wKsvDo12rbO6XaveiusqULuWeuRC4A9TweZWSftV00MGpGoWn2'), 
    // ToastrModule.forRoot()
  ],
  providers: [DataService, HttpInterceptorsService, {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInterceptorsService,
    multi: true,
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
