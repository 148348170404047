import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DashboardService } from '../dashboard.service';
import { TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import * as moment from 'moment';

const LIMIT = 6;

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss', '../events/events.component.scss', '../main-content/main-content.component.scss'],
  providers: [DashboardService]
})
export class NewsComponent implements OnInit {
  modalOption: ModalOptions = {};
  modalRef: BsModalRef;
  newsBaseUrl: any;
  newsInfo = [];
  pageNo = 0;
  offset = LIMIT * this.pageNo;
  storageBaseUrl: any;
  noRecordBool = false;
  newsTitle: any;
  newsDesc: any;
  jsDate: any;
  uploadedImages: any;
  currentUserId: any;
  currentNewsId: any;

  constructor(private dashboardService: DashboardService, private modalService: BsModalService) {
    this.newsBaseUrl = environment.storageBaseUrl + '/news/';
   }

  ngOnInit() {
    window.scrollTo(0, 0);
    const currentuser = JSON.parse(localStorage.getItem('loggedInUser'));
    // tslint:disable-next-line:radix
    this.currentUserId = parseInt(currentuser.id);
    this.getAllNewsList(LIMIT, this.offset);
  }

  // tslint:disable-next-line:no-shadowed-variable
  getAllNewsList(LIMIT, offset) {
    const newsParam = {
      searchterm: '',
      limit: LIMIT,
      offset
    };
    this.noRecordBool = false;
    this.dashboardService.getAllNewsList(newsParam).subscribe(
      async data => {
        if (data) {
          console.log('newsdata', data);
          if (data.respon.length === 0) {
            this.noRecordBool = true;
          }
          await Promise.all(data.respon.map(async (singleData, i) => {
            this.newsInfo.push(singleData);
            const currentIndex = this.newsInfo.length - 1;
            this.newsInfo[currentIndex].coverImage = `${this.newsBaseUrl}${singleData.image}`;
          }));
          console.log('data', data);
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  nextPage(): void {
    this.pageNo = this.pageNo + 1;
    console.log(this.pageNo);
    this.offset = (this.pageNo * LIMIT);
    console.log(this.offset);
    this.getAllNewsList(LIMIT, this.offset);
  }

  newsDetailsModal(template: TemplateRef<any>, news) {
        this.modalRef = this.modalService.show(template, this.modalOption);

        this.newsTitle = news.title;
        this.newsDesc = news.description;
        this.jsDate = moment(news.createdAt).format('DD-MM-YYYY');
        this.uploadedImages = news.image;
        // tslint:disable-next-line:radix
        this.currentNewsId = parseInt(news.id);
  }

  // newsDetailsModal(template: TemplateRef<any>, news) {
  //   let newsParam = {
  //     "searchterm":"",
  //     "limit":500,
  //     "offset":0
  //   };
  //   this.dashboardService.getAllNewsList(newsParam).subscribe(
  //     async data => {
  //       var result = await data.respon.filter(respon => {
  //         return respon.id === news.id;
  //       })

  //       this.modalRef = this.modalService.show(template, this.modalOption);

  //       this.newsTitle = result[0].title;
  //       this.newsDesc = result[0].description;
  //       this.jsDate = moment(result[0].createdAt).format('DD-MM-YYYY');
  //       this.uploadedImages = result[0].image;
  //       this.currentNewsId = parseInt(result[0].id);
  //     },
  //     err => {
  //       return console.error(err);
  //     });
  // }

  closeModal() {
    this.modalRef.hide();
  }
}
