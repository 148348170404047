import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataService } from 'src/app/shared-services/data-service/data.service';
import { TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { DashboardService } from '../dashboard.service';
import { async } from '@angular/core/testing';
import { ViewContainerRef } from '@angular/core';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';

const initialState = {
  title: "title",
  message: "message",
  options: "options",
};

@Component({
  selector: 'app-manage-playlist',
  templateUrl: './manage-playlist.component.html',
  styleUrls: ['./manage-playlist.component.scss' , '../main-content/main-content.component.scss', '../events/events.component.scss'],
  providers: [DashboardService]
})

export class ManagePlaylistComponent implements OnInit {

  showPlaylistBool = false;
  modalRef: BsModalRef;
  playlistName = "";
  noRecordBool = false;
  allPlaylistInfo = [];
  communityCategoryResult = [];
  showModalBool = false;
  trackTypeId: any;
  trackId: any;
  playlistId: any;
  songMatchBool = false;
  currentUserId: any;
  routeFrom: any;
  @ViewChild('autoShownModal') autoShownModal: ModalDirective;

  constructor(private data: DataService, private modalService: BsModalService, private spinnerService: Ng4LoadingSpinnerService, private dashboardService: DashboardService, private modalDialogService: ModalDialogService, private viewContainer: ViewContainerRef) {
    let currentuser = JSON.parse(localStorage.getItem('loggedInUser'));
    this.currentUserId = parseInt(currentuser.id);
  }

  isModalShown: boolean = false;

  ngOnInit() {
      this.data.currentPlaylist.subscribe(playlist => {
        if (playlist[0]) {
          console.log("playlist[0]", playlist[0]);

          this.trackId = playlist[0].songId;
          this.trackTypeId = playlist[0].TrackTypeId;
          this.routeFrom = playlist[0].trackType;
          this.showPlaylistBool = true;
          if (this.showPlaylistBool) {
            if(this.routeFrom == 'community') {
            this.getAllCommunity();
            } else {
            this.getAllPlaylist();
            }
          }
        }
      })
    
  }


  getAllPlaylist() {
    const playListparam = {
      'X-API-KEY': 'testkey',
      user_id: this.currentUserId
    }
    this.spinnerService.show();
    this.noRecordBool = false;
    this.dashboardService.getAllPlaylist(playListparam).subscribe(
      async data => {
        if (data.respon.length == 0) {
          this.noRecordBool = true;
        }
        if (data) {
          this.spinnerService.hide();
          this.allPlaylistInfo = data.respon;
          this.showModal();
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  /* get Community */
  getAllCommunity() {
    this.communityCategoryResult = [];
    this.spinnerService.show();
    this.noRecordBool = false;
    this.dashboardService.getCommunityCategory(this.currentUserId).subscribe(
      async data => {
        if (data.resultObject.length == 0) {
          this.noRecordBool = true;
        }
        if (data) {
          this.spinnerService.hide();
          await Promise.all(data.resultObject.map(async (singleData, i) => {
            if (singleData.action == '2') {
              this.communityCategoryResult.push(singleData);
              this.showModal();
            }
          }));
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  toggle(playlist) {
    console.log("playlist", playlist);
    console.log("this.allPlaylistInfo", this.allPlaylistInfo);
    for (let i = 0; i < this.allPlaylistInfo.length; i++) {
      if (this.allPlaylistInfo[i].id == playlist.id) {
        this.allPlaylistInfo[i].selected = true;
      }
      else {
        this.allPlaylistInfo[i].selected = false;
      }
    }
  }

  toggleCommunity(community) {
    console.log("community", community);
    console.log("this.communityCategoryResult", this.communityCategoryResult);
    for (let i = 0; i < this.communityCategoryResult.length; i++) {
      if (this.communityCategoryResult[i].id == community.id) {
        this.communityCategoryResult[i].selected = true;
      }
      else {
        this.communityCategoryResult[i].selected = false;
      }
    }
  }

  openSimpleModal(message) {
    this.modalDialogService.openDialog(this.viewContainer, {
      /* title: 'Simple dialog', */
      childComponent: SimpleModalComponent,
      settings: {
        closeButtonClass: 'fa fa-times'
      },
      data: {
        text: message
      }
    });
  }

  addSongToPlaylist() {
    console.log("this.allPlaylistInfo", this.allPlaylistInfo);


    for (let i = 0; i < this.allPlaylistInfo.length; i++) {
      if (this.allPlaylistInfo[i].selected == true) {
        this.playlistId = this.allPlaylistInfo[i].id;
        this.songMatchBool = true;
      }
    }


    if (this.songMatchBool) {
      let playlistParam = {
        playlist_id: this.playlistId,
        track_id: this.trackId,
        track_type: this.trackTypeId
      }

      this.dashboardService.addToplayList(playlistParam).subscribe(
        async data => {
          if (data) {
            this.spinnerService.hide();
            this.hideModal();
            this.openSimpleModal("Added successfully.");
          }
        },
        err => {
          return console.error(err);
        }
      );
    } else {
      this.hideModal();
    }


  }

  addToCommunity() {
    console.log("this.communityCategoryResult", this.communityCategoryResult);

    for (let i = 0; i < this.communityCategoryResult.length; i++) {
      if (this.communityCategoryResult[i].selected == true) {
        this.playlistId = this.communityCategoryResult[i].id;
        this.songMatchBool = true;
      }
    }

    if (this.songMatchBool) {
      let playlistParam = {
        user_id:this.currentUserId,
        community_id: this.playlistId,
        track_id: this.trackId,
        track_type: this.trackTypeId
      }

      this.dashboardService.addToCommunity(playlistParam).subscribe(
        async data => {
          if (data) {
            this.spinnerService.hide();
            this.hideModal();
            this.openSimpleModal("Added successfully.");
          }
        },
        err => {
          return console.error(err);
        }
      );
    } else {
      this.hideModal();
    }
  }


  // create playlist start

  openModal(createPlay: TemplateRef<any>) {
    this.modalRef = this.modalService.show(createPlay);
  }



  createPlaylist() {
    const createPlaylistParam = {
      user_id: this.currentUserId,
      name: this.playlistName
    }
    this.spinnerService.show();
    this.dashboardService.createPlaylist(createPlaylistParam).subscribe(
      async data => {
        if (data) {
          this.spinnerService.hide();
          this.getAllPlaylist();
          this.playlistName = "";
          this.modalRef.hide();
          this.openSimpleModal("Playlist added successfully.");
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  //create playlist end

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }

}
