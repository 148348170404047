import { Component, OnInit } from '@angular/core';
import { TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { DashboardService } from '../dashboard.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ViewContainerRef } from '@angular/core';
import { DataService } from 'src/app/shared-services/data-service/data.service';
import { environment } from 'src/environments/environment';
import { PeopleListComponent } from '../events/people-list/people-list.component';
import * as moment from 'moment';

const LIMIT = 10;
@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss', '../main-content/main-content.component.scss', '../events/events.component.scss', '../events/people-list/people-list.component.scss']
})
export class CommunityComponent implements OnInit {

  modalOption: ModalOptions = {};
  pageNo = 0;
  offset = LIMIT * this.pageNo;
  activeTab = 'posts';
  noRecordBool = false;
  currentUserId: any;
  isSelfCommunity = false;
  communityId: any;
  selectedCommunityName: any
  communityBaseUrl: any;
  profileImgUrl: any;
  audioBaseUrl: any;
  eventBaseUrl: any;
  videoBaseUrl: any;
  modalRef: BsModalRef;
  communityCategoryResult = [];
  searchCommunityCategoryResult = [];
  communityCommentsResult = [];
  communitySongData = [];
  communityName = '';
  communityImage: any;
  uploadedImages: any;
  url: any;
  isEditing = false;
  isImageEdited = false;
  eventName: "";
  eventAddress: "";
  currentEventId: any;
  jsStartDate: any;
  jsEndDate: any;
  uploadedImagesEvent: any;
  isPaused = false;
  isPlayed = false;
  playAudioInfo: Array<any> = [];
  playVideoInfo = [];
  isInitPlayed = false;
  srchString = '';
  commentText: '';
  timeout = null;
  peopleList = [];

  constructor(private modalService: BsModalService, private dashboardService: DashboardService, private httpClient: HttpClient, private data: DataService, private spinnerService: Ng4LoadingSpinnerService, private modalDialogService: ModalDialogService, private viewContainer: ViewContainerRef) {
    let currentuser = JSON.parse(localStorage.getItem('loggedInUser'));
    this.currentUserId = parseInt(currentuser.id);
    this.communityBaseUrl = environment.storageBaseUrl + '/community/images/';
    this.audioBaseUrl = environment.storageBaseUrl + '/audios/cover/';
    this.videoBaseUrl = environment.storageBaseUrl + '/videos/cover/';
    this.eventBaseUrl = environment.storageBaseUrl + '/event/';
    this.profileImgUrl = environment.storageBaseUrl + '/userprofile/';
  }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.getCommunityCategory();

    this.data.pauseSong.subscribe(songName => {
      console.log('init audio comp')
      if (songName.toString() == 'true') {
        this.isPaused = true;
        this.isPlayed = false;
      } else {
        this.isPaused = false;
        this.isPlayed = true;
      }
      console.log(this.isPaused)
    })
  }

  tabChange(tab) {
    this.activeTab = tab;
    if (this.activeTab === 'chat') {
      this.communityCommentsResult = [];
      this.commentText = '';
      this.getCommunityComments();
    } else {
      // this.audioCategoryResult = [];
      // this.sortBy = 1;
      // this.pageNo = 0;
      // this.offset = LIMIT * this.pageNo;
      // this.getqAndaAudioCategory();
    }
  }

  openAddCommunityModal(addCommunity: TemplateRef<any>) {
    this.communityName = '';
    this.uploadedImages = '';
    this.isEditing = false;
    this.modalRef = this.modalService.show(addCommunity);
  }

  editCommunityModal(addCommunity: TemplateRef<any>, community) {
    this.isImageEdited = false;
    this.dashboardService.getCommunityCategory(this.currentUserId).subscribe(
      async data => {
        var result = await data.resultObject.filter(resultObject => {
          return resultObject.id === community.id;
        })

        this.modalRef = this.modalService.show(addCommunity, this.modalOption);

        this.communityName = result[0].community_name;
        this.uploadedImages = result[0].image_url;
        this.isEditing = true;
        this.communityId = parseInt(result[0].id);
      },
      err => {
        return console.error(err);
      });
  }

  /* get Community */
  getCommunityCategory() {
    this.communityCategoryResult = [];
    this.spinnerService.show();
    this.noRecordBool = false;
    this.dashboardService.getCommunityCategory(this.currentUserId).subscribe(
      async data => {
        if (data) {
          await Promise.all(data.resultObject.map(async (singleData, i) => {
            if (singleData.action != '0') {
              this.communityCategoryResult.push(singleData);
              const currentIndex = this.communityCategoryResult.length - 1;

              if (singleData.image_url) {
                this.communityCategoryResult[currentIndex].coverImage = `${this.communityBaseUrl}${singleData.image_url}`;
              } else {
                this.communityCategoryResult[currentIndex].coverImage = undefined;
              }
            }
          }));
          this.spinnerService.hide();
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  /* Get Community Comments */
  getCommunityComments() {
    this.communityCommentsResult = [];
    this.dashboardService.getCommunityComments(this.communityId, '500', '0').subscribe(
      async data => {
        await Promise.all(data.resultObject.map(async (singleData, i) => {
          this.communityCommentsResult.push(singleData);
        }));
      },
      err => {
        return console.error(err);
      });
  }

  /* Post comment to community */
  addCommentToCommunity() {
    if (this.commentText.trim() != '') {
      const addCommentParam = {
        user_id: this.currentUserId,
        community_id: this.communityId,
        comment: this.commentText
      }
      this.spinnerService.show();
      this.dashboardService.addCommunityCommentPost(addCommentParam).subscribe(
        async data => {
          if (data) {
            this.spinnerService.hide();
            this.getCommunityComments();
            this.commentText = '';
            // this.openSimpleModal(data.message);
          }
        },
        err => {
          return console.error(err);
        }
      );
    } else {
      this.openSimpleModal('Comment can not be blank');
    }


  }


  /* Search Community */
  searchCommunityCategory(searchterm) {
    this.searchCommunityCategoryResult = [];
    this.spinnerService.show();
    this.noRecordBool = false;
    const searchCommunityParam = {
      searchterm: searchterm,
      limit: 500,
      offset: 0
    }
    this.dashboardService.searchCommunity(searchCommunityParam).subscribe(
      async data => {
        if (data) {
          await Promise.all(data.respon.map(async (singleData, i) => {
            if (singleData.parent_user_id != this.currentUserId) {
              this.searchCommunityCategoryResult.push(singleData);
              const currentIndex = this.searchCommunityCategoryResult.length - 1;

              if (singleData.image_url) {
                this.searchCommunityCategoryResult[currentIndex].coverImage = `${this.communityBaseUrl}${singleData.image_url}`;
              } else {
                this.searchCommunityCategoryResult[currentIndex].coverImage = undefined;
              }
            }
          }));
          this.spinnerService.hide();
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  searchCommunityByKey(event) {
    this.srchString = event.target.value.trim();
    this.searchCommunityCategoryResult = [];
    this.searchCommunityCategory(this.srchString);
  }

  /* create community method */
  addCommunityAPICall() {
    if (this.communityName.trim() != '') {
      this.spinnerService.show();
      if (this.isEditing && !this.isImageEdited) {
        const addCommunityParam = {
          parent_user_id: this.currentUserId,
          community_id: this.communityId,
          type: 1,
          name: this.communityName,
          short_info: '',
          long_info: '',
          image_url: this.uploadedImages
        }
        this.spinnerService.show();
        this.dashboardService.updateCommunity(addCommunityParam).subscribe(
          async data => {
            if (data) {
              this.spinnerService.hide();
              this.getCommunityCategory();
              this.communityName = "";
              this.modalRef.hide();
              // this.openSimpleModal("Community updated successfully.");
              this.openSimpleModal(data.message);
              this.uploadedImages = '';
            }
          },
          err => {
            return console.error(err);
          }
        );
      }
      else {
        if (this.uploadedImages == '') {
          let addCommunityParam;
          if (this.isEditing) {
            addCommunityParam = {
              parent_user_id: this.currentUserId,
              community_id: this.communityId,
              type: 1,
              name: this.communityName,
              short_info: '',
              long_info: '',
              image_url: ''
            }
            this.spinnerService.show();
            this.dashboardService.updateCommunity(addCommunityParam).subscribe(
              async data => {
                if (data) {
                  this.spinnerService.hide();
                  this.getCommunityCategory();
                  this.communityName = "";
                  this.modalRef.hide();
                  // this.openSimpleModal("Community updated successfully.");
                  this.openSimpleModal(data.message);
                  this.isImageEdited = false;
                }
              },
              err => {
                return console.error(err);
              }
            );
          } else {
            addCommunityParam = {
              parent_user_id: this.currentUserId,
              type: 1,
              name: this.communityName,
              short_info: '',
              long_info: '',
              image_url: ''
            }
            this.spinnerService.show();
            this.dashboardService.addCommunity(addCommunityParam).subscribe(
              async data => {
                if (data) {
                  this.spinnerService.hide();
                  this.getCommunityCategory();
                  this.communityName = "";
                  this.modalRef.hide();
                  // this.openSimpleModal("Community added successfully.");
                  this.openSimpleModal(data.message);
                }
              },
              err => {
                return console.error(err);
              }
            );
          }
        } else {
          this.dashboardService.uploadFile(this.uploadedImages).then(res => {
            if (res.upload_data) {
              let uploadedImage = res.upload_data.file_name;
              let addCommunityParam;
              if (this.isEditing) {
                addCommunityParam = {
                  parent_user_id: this.currentUserId,
                  community_id: this.communityId,
                  type: 1,
                  name: this.communityName,
                  short_info: '',
                  long_info: '',
                  image_url: uploadedImage
                }
                this.spinnerService.show();
                this.dashboardService.updateCommunity(addCommunityParam).subscribe(
                  async data => {
                    if (data) {
                      this.spinnerService.hide();
                      this.getCommunityCategory();
                      this.communityName = "";
                      this.modalRef.hide();
                      // this.openSimpleModal("Community updated successfully.");
                      this.openSimpleModal(data.message);
                      uploadedImage = '';
                      this.isImageEdited = false;
                    }
                  },
                  err => {
                    return console.error(err);
                  }
                );
              } else {
                addCommunityParam = {
                  parent_user_id: this.currentUserId,
                  type: 1,
                  name: this.communityName,
                  short_info: '',
                  long_info: '',
                  image_url: uploadedImage
                }
                this.spinnerService.show();
                this.dashboardService.addCommunity(addCommunityParam).subscribe(
                  async data => {
                    if (data) {
                      this.spinnerService.hide();
                      this.getCommunityCategory();
                      this.communityName = "";
                      this.modalRef.hide();
                      // this.openSimpleModal("Community added successfully.");
                      this.openSimpleModal(data.message);
                      uploadedImage = '';
                    }
                  },
                  err => {
                    return console.error(err);
                  }
                );
              }
            }
          });
        }
      }
    } else {
      this.modalRef.hide();
      this.openSimpleModal('Community name can not be blank');
    }
  }

  openSimpleModal(message) {
    this.modalDialogService.openDialog(this.viewContainer, {
      /* title: 'Simple dialog', */
      childComponent: SimpleModalComponent,
      settings: {
        closeButtonClass: 'fa fa-times'
      },
      data: {
        text: message
      }
    });
  }

  fileChangeCommunity(fileInput: any) {
    if (fileInput.target.files[0].type.toString().includes('image')) {
      const file = fileInput.target.files[0];

      const reader = new FileReader();
      reader.onload = e => this.url = reader.result;

      reader.readAsDataURL(file);

      this.uploadedImages = fileInput.target.files;
      this.isImageEdited = true;
      console.log('fileChangeEvent', this.uploadedImages);
    } else {
      this.uploadedImages = '';
      alert('Please attach images only')
    }
  }

  displaySongDetails: boolean = false;
  displayCommunitySearch: boolean = false;

  // Get community information
  getCommunityInfo(community): void {
    this.communityImage = community.coverImage;
    console.log(community);
    this.displaySongDetails = true;
    this.displayCommunitySearch = false;
    this.communityId = parseInt(community.id);
    this.selectedCommunityName = community.community_name;
    if (community.parent_user_id == this.currentUserId) {
      this.isSelfCommunity = true;
    } else {
      this.isSelfCommunity = false;
    }
    this.communitySongData = [];
    this.pageNo = 0;
    this.offset = LIMIT * this.pageNo;
    this.getCommunitySongs(this.communityId, LIMIT, this.offset);
  }

  searchCommunity() {
    this.srchString = '';
    this.displaySongDetails = false;
    this.displayCommunitySearch = true;
    this.searchCommunityCategory('');
  }

  getCommunitySongs(communityId, LIMIT, offset) {
    this.dashboardService.getCommunityData(communityId, LIMIT, offset).subscribe(
      async data => {
        if (data) {
          console.log(data)
          var video_types_array = ['mp4'];
          // this.communitySongData = data.resultObject;
          await Promise.all(data.resultObject.map(async (singleData, i) => {
            if (singleData.action != '0') {
              this.communitySongData.push(singleData);
              const currentIndex = this.communitySongData.length - 1;

              if (singleData.cover) {
                if (singleData.track_type == '5')
                  this.communitySongData[currentIndex].coverImage = `${this.videoBaseUrl}${singleData.cover}`;
                else if (singleData.track_type == '6')
                  this.communitySongData[currentIndex].coverImage = `${this.eventBaseUrl}${singleData.cover}`;
                else
                  this.communitySongData[currentIndex].coverImage = `${this.audioBaseUrl}${singleData.cover}`;
              } else {
                this.communitySongData[currentIndex].coverImage = undefined;
              }
            }
          }));
          console.log(this.communitySongData);
        }
      },
      err => {
        return console.error(err);
      }
    );
  }


  /* Community People list window */
  openPromptModal(community_id) {
    this.modalDialogService.openDialog(this.viewContainer, {
      title: 'Members',
      childComponent: PeopleListComponent,
      settings: {
        closeButtonClass: 'close theme-icon-close',
        headerTitleClass: "headerWithoutData"
      },
      data: {
        user_id: this.currentUserId,
        id: community_id,
        route_from: "community"
      },
      actionButtons: [
        {
          text: 'Close',
          buttonClass: 'btn btn-danger',
          onAction: () => true
        },
      ]
    });
  }
  /* Community People list window */
  openPromptModalRequests(community_id) {
    this.modalDialogService.openDialog(this.viewContainer, {
      title: 'Requests',
      childComponent: PeopleListComponent,
      settings: {
        closeButtonClass: 'close theme-icon-close',
        headerTitleClass: "headerWithoutData"
      },
      data: {
        user_id: this.currentUserId,
        community_id: community_id,
        route_from: "community_requests"
      },
      actionButtons: [
        {
          text: 'Close',
          buttonClass: 'btn btn-danger',
          onAction: () => true
        },
      ]
    });
  }

  /* Add People to Community list window */
  openPromptModalForAddPeople(addCommunity: TemplateRef<any>) {
    this.getUsersToAdd('');
    clearTimeout(this.timeout);
    var refObj = this;
    // Make a new timeout set to go off in 500ms
    this.timeout = setTimeout(function () {
      refObj.modalRef = refObj.modalService.show(addCommunity);
    }, 200);

  }
  getUsersToAdd(searchterm) {
    const getUsersParam = {
      searchterm: searchterm,
      limit: 500,
      offset: 0
    }
    this.dashboardService.getAllUsers(getUsersParam).subscribe(
      async data => {
        this.peopleList = data.respon;
      },
      err => {
        return console.error(err);
      });
  }

  searchByKey(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.getUsersToAdd(filterValue);
  }

  openPromptModalAddToCommunity(person) {
    this.modalDialogService.openDialog(this.viewContainer, {
      title: 'Add Friend?',
      childComponent: SimpleModalComponent,
      placeOnTop: false,
      data: {
        text: 'Are you sure you want to add ' + '"' + person.username + '"' + ' ?'
      },
      settings: {
        closeButtonClass: 'close theme-icon-close',
        headerTitleClass: "headerWithoutData"
      },
      actionButtons: [
        {
          text: 'Yes',
          buttonClass: 'btn btn-success',
          onAction: () => {
            this.addToCommunity(person.id);
            return true;
          }
        },
        {
          text: 'No',
          buttonClass: 'btn btn-danger',
          onAction: () => true
        },
      ]
    });
  }

  addToCommunity(user_id) {
    const addUsersParam = {
      parent_user_id: this.currentUserId,
      community_id: this.communityId,
      user_id: user_id,
      request: 1
    }
    this.dashboardService.addUserToCommunity(addUsersParam).subscribe(
      async data => {
        //this.modalRef.hide();
        this.openSimpleModal(data.message);
      },
      err => {
        return console.error(err);
      });
  }

  // openPromptModalForAddPeople(community_id) {
  //   this.modalDialogService.openDialog(this.viewContainer, {
  //     title: 'Add Member',
  //     childComponent: PeopleListComponent,
  //     settings: {
  //       closeButtonClass: 'close theme-icon-close',
  //       headerTitleClass: "headerWithoutData"
  //     },
  //     data: {
  //       user_id: this.currentUserId,
  //       community_id: community_id,
  //       search_term: "",
  //       route_from: "community_friends"
  //     },
  //     actionButtons: [
  //       {
  //         text: 'Close',
  //         buttonClass: 'btn btn-danger',
  //         onAction: () => true
  //       },
  //     ]
  //   });
  // }

  backToCommunity() {
    window.scrollTo(0, 0);
    this.srchString = '';
    this.displaySongDetails = false;
    this.displayCommunitySearch = false;
    this.activeTab = 'posts';
  }

  deleteSingleCommunity(singleCommunity) {
    this.openPromptModalDelete(singleCommunity);
  }

  openPromptModalDelete(singleCommunity) {
    this.modalDialogService.openDialog(this.viewContainer, {
      title: 'Delete Community?',
      childComponent: SimpleModalComponent,
      data: {
        text: 'Are you sure you want to delete ' + '"' + singleCommunity.community_name + '"' + ' ?'
      },
      settings: {
        closeButtonClass: 'close theme-icon-close',
        headerTitleClass: "headerWithoutData"
      },
      actionButtons: [
        {
          text: 'Yes',
          buttonClass: 'btn btn-success',
          onAction: () => {
            this.deleteRecord(singleCommunity);
            return true;
          }
        },
        {
          text: 'No',
          buttonClass: 'btn btn-danger',
          onAction: () => true
        },
      ]
    });
  }

  /* call pagination method */
  nextPage(): void {
    this.pageNo = this.pageNo + 1;
    this.offset = (this.pageNo * LIMIT);
    this.getCommunitySongs(this.communityId, LIMIT, this.offset);
  }

  /* delete single playlist */
  deleteRecord(singleCommunity) {
    const deleteCommunityParam = {
      'X-API-KEY': "testkey",
      parent_user_id: this.currentUserId,
      community_id: singleCommunity.id
    }
    this.spinnerService.show();
    this.dashboardService.deleteSingleCommunity(deleteCommunityParam).subscribe(
      async data => {
        if (data) {
          this.spinnerService.hide();
          this.getCommunityCategory();
          this.openSimpleModal("Community deleted successfully.");
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  public playedId = 0;
  playAudioSong(songArr, index) {
    console.log('index')
    console.log(index);
    this.isPlayed = true;
    this.isPaused = false;
    console.log('play audio called')

    this.playedId = songArr[index].id;
    this.playAudioInfo = [
      { showPlayerBool: true, songInfo: songArr[index], format: "audio" }
    ];
    this.songAddToplaylist(songArr[index]);
    //songArr.splice(index, 1);
    // for (let i = index + 1; i < songArr.length; i++) {
    //   //this.songAddToplaylist(songArr[i]);
    //   this.playAudioInfo.push(
    //     { showPlayerBool: true, songInfo: songArr[i], format: "audio" });

    // }
    // for (let j = 0; j < index; j++) {
    //   //this.songAddToplaylist(songArr[j]);
    //   this.playAudioInfo.push(
    //     { showPlayerBool: true, songInfo: songArr[j], format: "audio" });
    // }
    this.isInitPlayed = true;
    this.data.changeSong(this.playAudioInfo);
  }

    /* add song to playlist */
    songAddToplaylist(songArr) {
      const audioParam = {
        user_id: this.currentUserId,
        track_id: songArr.id,
        track_type: 1
      };
      this.dashboardService.songAddToplaylist(audioParam).subscribe(
        async data => {
          if (data) {
          }
        },
        err => {
          return console.error(err);
        }
      );
    }

  playVideoSong(songArr) {
    this.playVideoInfo = [
      { showPlayerBool: true, songInfo: songArr, format: 'video' }
    ]
    this.data.changeSong(this.playVideoInfo);
  }

  editEventModal(template: TemplateRef<any>, event) {
    this.isImageEdited = false;
    this.dashboardService.getAllEventsById(this.currentUserId).subscribe(
      async data => {
        var result = await data.userobject.filter(userobject => {
          return userobject.id === event.track_id;
        })

        this.modalRef = this.modalService.show(template, this.modalOption);


        this.eventName = result[0].name;
        this.eventAddress = result[0].address;
        this.jsStartDate = moment(result[0].from_date).format('MM-DD-YYYY');
        this.jsEndDate = moment(result[0].to_date).format('MM-DD-YYYY');
        this.uploadedImagesEvent = result[0].image;
        this.currentEventId = parseInt(result[0].id);
      },
      err => {
        return console.error(err);
      });
  }

  closeModal() {
    this.modalRef.hide();
  }

  openPromptModalSendRequest(singleCommunity) {
    this.modalDialogService.openDialog(this.viewContainer, {
      title: 'Join Community?',
      childComponent: SimpleModalComponent,
      data: {
        text: '<p class="headerWithoutData">Are you sure you want to join the community : ' + '"' + singleCommunity.name + '"' + ' ?</p>'
      },
      settings: {
        closeButtonClass: 'close theme-icon-close',
        headerTitleClass: "headerWithoutData"
      },
      actionButtons: [
        {
          text: 'Yes',
          buttonClass: 'btn btn-success',
          onAction: () => {
            this.sendRequestToCommunity(singleCommunity);
            return true;
          }
        },
        {
          text: 'No',
          buttonClass: 'btn btn-danger',
          onAction: () => true
        },
      ]
    });
  }

  sendRequestToCommunity(singleCommunity) {
    const addUsersParam = {
      parent_user_id: singleCommunity.parent_user_id,
      community_id: singleCommunity.id,
      user_id: this.currentUserId,
      request: 2
    }
    this.dashboardService.addUserToCommunity(addUsersParam).subscribe(
      async data => {
        this.openSimpleModal(data.message);
      },
      err => {
        return console.error(err);
      });
  }

}