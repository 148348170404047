import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { LoginComponent } from './auth/login/login.component';
import { SignupComponent } from './auth/signup/signup.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { ForgetPasswordComponent } from './auth/forget-password/forget-password.component';
import { AskOshoComponent } from './ask-osho/ask-osho.component';
import { DashboardComponent } from './ask-osho/dashboard/dashboard.component';
import { AudioComponent } from './ask-osho/dashboard/audio/audio.component';
import { MainContentComponent } from './ask-osho/dashboard/main-content/main-content.component';
import { VideoComponent } from './ask-osho/dashboard/video/video.component';
import { PlaylistComponent } from './ask-osho/dashboard/playlist/playlist.component';
import { NewsComponent } from './ask-osho/dashboard/news/news.component';
import { CommunityComponent } from './ask-osho/dashboard/community/community.component';
import { EventsComponent } from './ask-osho/dashboard/events/events.component';
import { MeditationsComponent } from './ask-osho/dashboard/meditations/meditations.component';
import { MusicComponent } from './ask-osho/dashboard/music/music.component';
import { InterviewsComponent } from './ask-osho/dashboard/interviews/interviews.component';
import { JokesComponent } from './ask-osho/dashboard/jokes/jokes.component';
import { OshoCenterComponent } from './ask-osho/dashboard/osho-center/osho-center.component';
import { AboutComponent } from './ask-osho/dashboard/about/about.component';
import { ContactUsComponent } from './ask-osho/dashboard/contact-us/contact-us.component';
import { HowToUseComponent } from './ask-osho/dashboard/how-to-use/how-to-use.component';
import { ProfileComponent } from './ask-osho/dashboard/profile/profile.component';

import { TermsComponent } from './auth/terms/terms.component';
import { ProductsComponent } from './ask-osho/dashboard/products/products.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { DonationComponent } from './ask-osho/donation/donation.component';
import { InstructionsComponent } from './ask-osho/instructions/instructions.component';
import { DownloadComponent } from './ask-osho/download/download.component';
import { MagazinesPodcastComponent } from './ask-osho/dashboard/magazines-podcast/magazines-podcast.component';
import { AudioVideoplayComponent } from './audio-videoplay/audio-videoplay.component';

import { SubscriptionComponent } from './ask-osho/dashboard/subscription/subscription.component';
import { SubscriptionNewComponent } from './ask-osho/dashboard/subscription-new/subscription-new.component';


const routes: Routes = [
  {
    path: 'auth', component: AuthComponent, children: [
      { path: 'login', component: LoginComponent },
      { path: 'signup', component: SignupComponent },
      { path: 'change-password', component: ChangePasswordComponent },
      { path: 'forget-password', component: ForgetPasswordComponent }
    ],
  },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent},
  { path: 'avplay', component: AudioVideoplayComponent},
  {
    path: 'ask-osho', component: AskOshoComponent, children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'audio', component: AudioComponent },
      { path: 'main-content', component: MainContentComponent },
      { path: 'video', component: VideoComponent },
      { path: 'playlist', component: PlaylistComponent },
      { path: 'news', component: NewsComponent },
      { path: 'subscription-old', component: SubscriptionComponent },
      { path: 'subscription', component: SubscriptionNewComponent },
      { path: 'community', component: CommunityComponent },
      { path: 'events', component: EventsComponent },
      { path: 'meditations', component: MeditationsComponent },
      { path: 'music', component: MusicComponent },
      { path: 'interview', component: InterviewsComponent },
      { path: 'jokes', component: JokesComponent },
      { path: 'osho-center', component: OshoCenterComponent },
      { path: 'about', component: AboutComponent},
      { path: 'contact-us', component: ContactUsComponent},
      { path: 'how-to-use', component: HowToUseComponent},
      { path: 'terms', component: TermsComponent},
      { path: 'profile', component: ProfileComponent},
      { path: 'Products', component: ProductsComponent},
      { path: 'donation', component: DonationComponent},
      { path: 'instructions', component: InstructionsComponent},
      { path: 'download', component: DownloadComponent},
      { path: 'magazines-podcast', component: MagazinesPodcastComponent},
    ],
  },
  { path: '', redirectTo: '/auth', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [AudioComponent, MainContentComponent, VideoComponent,
  PlaylistComponent, NewsComponent, CommunityComponent, EventsComponent, MeditationsComponent,
  MusicComponent, InterviewsComponent, JokesComponent, OshoCenterComponent, AboutComponent, 
  ContactUsComponent, HowToUseComponent, ProfileComponent,TermsComponent, TermsAndConditionsComponent,ProductsComponent,DownloadComponent];
