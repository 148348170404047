import { Component, OnInit, TemplateRef } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { environment } from 'src/environments/environment';
import { DataService } from 'src/app/shared-services/data-service/data.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { NgNavigatorShareService } from 'ng-navigator-share';

const LIMIT = 0;
const MASTER_SUBSCRIPTION_ID = 1;

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss', '../main-content/main-content.component.scss'],
  providers: [DashboardService]
})

export class VideoComponent implements OnInit {

  videoBaseUrl: any;
  audioCatUrl: any;
  videoInfo = [];
  videoDiscourseInfo = [];
  pageNo = 0;
  offset = LIMIT * this.pageNo;
  activeTab = 'qAndATab';
  playVideoInfo = [];
  videoCategoryResult = [];
  videoDisCategoryResult = [];
  categoryId = 0;
  categoryName = "";
  searchStr = "";
  sortBy = 1;
  discourseSortBy = 1;
  selectFilterBool = false;
  selectDisFilterBool = false;
  selectLanguage = [
    { id: 1, name: "English" },
    { id: 2, name: "Hindi" },
  ];
  storageBaseUrl: any;
  baseUrl: any;
  noRecordBool = false;
  modalRef: BsModalRef;
  subscriptionBody: any;
  currentUserId: any;
  srchString = '';
  timeout = null;
  isSearching = false;
  showSearchOption = false;
  showDisSearchOption = false;
  isSort = false;
  shareAudio = [];

  constructor(private dashboardService: DashboardService, private data: DataService,
    private spinnerService: Ng4LoadingSpinnerService, private modalService: BsModalService,
    private ngNavigatorShareService: NgNavigatorShareService) {
    this.videoBaseUrl = environment.storageBaseUrl + '/videos/cover/';
    this.audioCatUrl = environment.baseUrl + "/uploads/media_images/";
    let currentuser = JSON.parse(localStorage.getItem('loggedInUser'));
    this.currentUserId = parseInt(currentuser.id);
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getqAndaVideoCategory();
    /* this.getVideoSongsList(LIMIT,this.offset); */

  }

  tabChange(tab) {
    this.srchString = '';
    this.activeTab = tab;
    this.showSearchOption = false;
    this.showDisSearchOption = false;
    if (this.activeTab === 'discourseTab') {
      this.showDisSearchOption = true;
      this.videoDisCategoryResult = [];
      this.discourseSortBy = 1
      this.pageNo = 0;
      this.offset = LIMIT * this.pageNo;
      this.getDiscourseVideoCategory(this.srchString, LIMIT, this.offset);
    } else {
      this.sortBy = 1;
      this.pageNo = 0;
      this.offset = LIMIT * this.pageNo;
      this.videoCategoryResult = [];
      this.getqAndaVideoCategory();
    }
  }

  onOptionsFilterSelected(event) {
    if (event.target.value == 'English') {
      this.selectFilterBool = true;
      this.sortBy = 1;
      this.pageNo = 0;
      this.offset = LIMIT * this.pageNo;
      this.videoInfo = [];
    } else if (event.target.value == 'Hindi') {
      this.selectFilterBool = true;
      this.sortBy = 2;
      this.pageNo = 0;
      this.offset = LIMIT * this.pageNo;
      this.videoInfo = [];
    }
    this.getVideoSongsList('', LIMIT, this.offset, this.categoryId);
  }

  onOptionsFilterDiscourseSelected(event) {
    if (event.target.value == 'English') {
      this.selectDisFilterBool = true;
      this.discourseSortBy = 1;
      this.pageNo = 0;
      this.offset = LIMIT * this.pageNo;
      this.videoDisCategoryResult = [];
    } else if (event.target.value == 'Hindi') {
      this.selectDisFilterBool = true;
      this.discourseSortBy = 2;
      this.pageNo = 0;
      this.offset = LIMIT * this.pageNo;
      this.videoDisCategoryResult = [];
    }
    this.getDiscourseVideoCategory(this.srchString, LIMIT, this.offset);
  }

  searchSongByKey(searchName) {
    var refObj = this;
    refObj.srchString = searchName;
    refObj.videoInfo = [];
    clearTimeout(refObj.timeout);
    // Make a new timeout set to go off in 500ms
    refObj.timeout = setTimeout(function () {
      console.log('Input Value:', refObj.srchString);
      if (refObj.srchString.length == 0) {
        refObj.srchString = ''
        refObj.isSearching = false;
        refObj.pageNo = 0;
        refObj.offset = LIMIT * refObj.pageNo;
        refObj.getVideoSongsList(refObj.srchString, LIMIT, refObj.offset, refObj.categoryId);
      } else {
        refObj.isSearching = true;
        refObj.pageNo = 0;
        refObj.offset = LIMIT * refObj.pageNo;
        refObj.getVideoSongsList(refObj.srchString, LIMIT, refObj.offset, refObj.categoryId);
      }
    }, 500);
  }
  
  searchDiscourseByKey(searchName) {
    var refObj = this;
    refObj.srchString = searchName;
    refObj.videoDisCategoryResult = [];
    clearTimeout(refObj.timeout);
    // Make a new timeout set to go off in 500ms
    refObj.timeout = setTimeout(function () {
      console.log('Input Value:', refObj.srchString);
      if (refObj.srchString.length == 0) {
        refObj.srchString = ''
        refObj.isSearching = false;
        refObj.pageNo = 0;
        refObj.offset = LIMIT * refObj.pageNo;
        refObj.getDiscourseVideoCategory(refObj.srchString, LIMIT, refObj.offset);
      } else {
        refObj.isSearching = true;
        refObj.pageNo = 0;
        refObj.offset = LIMIT * refObj.pageNo;
        refObj.getDiscourseVideoCategory(refObj.srchString, LIMIT, refObj.offset);
      }
    }, 500);
  }

  /* get Q and A video cateogry */
  getqAndaVideoCategory() {
    this.spinnerService.show();
    this.noRecordBool = false;
    this.dashboardService.getqAndaVideoCategory().subscribe(
      async data => {
        if (data) {
          if (data.respon.length == 0) {
            this.noRecordBool = true;
          }
          this.spinnerService.hide();

          await Promise.all(data.respon.map(async (singleData, i) => {
            this.videoCategoryResult.push(singleData);
            const currentIndex = this.videoCategoryResult.length - 1;
            this.videoCategoryResult[currentIndex].coverImage = `${this.audioCatUrl}${singleData.image_url}`;
          }));
          console.log('video Cate data', data);
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  showVideoSongs(tab, category) {
    window.scrollTo(0, 0);
    this.showSearchOption = false;
    if (tab == 'QandASongs') {
      this.categoryId = category.id;
      this.categoryName = category.name;
      this.videoInfo = [];
      this.sortBy = 1;
      this.activeTab = "qAndATabSonngs"
      this.showSearchOption = true;
      this.getVideoSongsList('', LIMIT, this.offset, this.categoryId);
    }
    if (tab == 'discourseSongs') {
      this.searchStr = category.name;
      this.videoDiscourseInfo = [];
      this.sortBy = 1;
      this.activeTab = "discourseTabSonngs"
      this.getDiscourseVideoSongsList(LIMIT, this.offset, this.searchStr);
    }
  }

  getVideoSongsList(searchName, LIMIT, offset, catId) {
    const videoParam = {
      searchterm: searchName,
      limit: LIMIT,
      offset: offset,
      quedisc: 1,
      album_id: this.sortBy,
      should_orderby_name: false,
      media_type_id: parseInt(catId),
    };
    this.spinnerService.show();
    this.noRecordBool = false;
    this.dashboardService.getVideoSongsList(videoParam).subscribe(
      async data => {
        if (data) {
          if (data.respon.length == 0) {
            this.noRecordBool = true;
          }
          const subscription_status = await this.getUserSubscription(this.currentUserId);
          this.spinnerService.hide();
          await Promise.all(data.respon.map(async (singleData, i) => {
            this.videoInfo.push(singleData);
            const currentIndex = this.videoInfo.length - 1;
            this.videoInfo[currentIndex].coverImage = `${this.videoBaseUrl}${singleData.cover}`;
            this.videoInfo[currentIndex].subscriptionStatus = subscription_status;
          }));
          console.log('video data', this.videoInfo);
        }
      },
      err => {
        return console.error(err);
      }
    );

  }


  getDiscourseVideoCategory(searchName, LIMIT, offset) {
    const videoParam = {
      searchterm: searchName,
      limit: LIMIT,
      offset: offset,
      quedisc: 2,
      album_id: this.discourseSortBy,
      should_orderby_name: true,
    };
    this.spinnerService.show();
    this.noRecordBool = false;
    this.dashboardService.getDiscourseVideoCategory(videoParam).subscribe(
      async data => {
        if (data) {
          if (data.respon.length == 0) {
            this.noRecordBool = true;
          }
          this.spinnerService.hide();
          await Promise.all(data.respon.map(async (singleData, i) => {
            this.videoDisCategoryResult.push(singleData);
            const currentIndex = this.videoDisCategoryResult.length - 1;
            this.videoDisCategoryResult[currentIndex].coverImage = `${this.videoBaseUrl}${singleData.cover}`;
          }));
          console.log('Discourse video category data', data);
        }
      },
      err => {
        return console.error(err);
      }
    );
  }


  getDiscourseVideoSongsList(LIMIT, offset, searchStr) {
    const videoParam = {
      searchterm: searchStr,
      quedisc: 2,
      album_id: this.discourseSortBy,
      limit: LIMIT,
      offset: offset,
      should_orderby_name: false
    }
    this.spinnerService.show();
    this.noRecordBool = false;
    this.dashboardService.getDiscourseVideoSongsList(videoParam).subscribe(
      async data => {
        if (data) {
          if (data.respon.length == 0) {
            this.noRecordBool = true;
          }
          this.spinnerService.hide();
          await Promise.all(data.respon.map(async (singleData, i) => {
            this.videoDiscourseInfo.push(singleData);
            const currentIndex = this.videoDiscourseInfo.length - 1;
            this.videoDiscourseInfo[currentIndex].coverImage = `${this.videoBaseUrl}${singleData.cover}`;
          }));
          console.log('video discourse', this.videoDiscourseInfo);
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  nextPage(): void {
    this.pageNo = this.pageNo + 1;
    this.offset = (this.pageNo * LIMIT);
    if (this.activeTab === 'discourseTab' || this.activeTab === 'discourseTabSonngs') {
      this.getDiscourseVideoSongsList(LIMIT, this.offset, this.searchStr);
    } else {
      this.getVideoSongsList(this.srchString, LIMIT, this.offset, this.categoryId);
    }

  }

  playvideoSong(songArr) {
    this.playVideoInfo = [
      { showPlayerBool: true, songInfo: songArr, format: 'video' }
    ]
    this.data.changeSong(this.playVideoInfo);
  }

  sortTimeline() {
    console.log("in sortTimeline", this.isSort);
    if(this.isSort == false) {
      this.isSort = true;
      this.videoDisCategoryResult.sort((a,b) => a.timeline.localeCompare(b.timeline));
    } else {
      this.isSort = false;
      this.videoDisCategoryResult.sort((a,b) => a.file_name.localeCompare(b.file_name));
    }

  }

  /* Add to Community Method */
  sendToCommunity(songInfo) {
    console.log('send to community video comp');
    this.modalService.hide(1);
    let communityParam = [
      { "trackType": 'community', "TrackTypeId": 5, "songId": songInfo.id }
    ];
    this.data.changeCommunity(communityParam);
  }

  // Get User subscription 
  // tslint:disable-next-line: variable-name
  async getUserSubscription(user_id) {
    let status = false;
    try {
      this.subscriptionBody = {
        userId: user_id,
        materId: MASTER_SUBSCRIPTION_ID
      }

      let subscription_status = new Promise((resolve, reject) => {
        this.dashboardService.getSubscription(this.subscriptionBody).subscribe(
          async data => {
            console.log('data => ', data);
            if (data.iOSstatus || data.status) {
              status = true;
            } else {
              status = false;
            }
            console.log('STATUS WE GET => ', status);

            resolve(status);
          },
          err => {
            return console.error(err);
          }
        );
      });
      return subscription_status;
    } catch (error) {
      return console.error(error);
    }

  }

  // Payment Popup
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  shareToSocial(audioInfo) {
    console.log("in audio", audioInfo );
    // if (!this.ngNavigatorShareService.canShare()) {
    //   alert(`This service/api is not supported in your Browser`);
    //   return;
    // }
    this.spinnerService.show();
    
    const reqObj={
      av_id: audioInfo.id,
      user_id: this.currentUserId,
      media_type: 2,
      media_category: 2
    }
    this.dashboardService.getAvLink(reqObj).subscribe((res:any) =>{
      console.log("link", res.respon);

      this.ngNavigatorShareService.share({
        title: res.respon.name,
        text: res.respon.name,
        url: res.respon.link
      }).then( (response) => {
        console.log(response);
        this.modalService.hide(1);
        this.spinnerService.hide();
      })
      .catch( (error) => {
        this.spinnerService.hide();
        alert("Something went wrong! Please try after sometime");
      });

      

    },(error)=>{
      console.log("error", error);
    });
    
  }

  openShareModal(template: TemplateRef<any>, audio) {
    this.shareAudio = audio;
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered' 
    });
  }

}
