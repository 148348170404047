import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';


import { BehaviorSubject } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Access-Control-Request-Method': 'GET,POST,PUT,DELETE,OPTIONS'
  })
};

const httpFileOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'multipart/form-data'
  })
};


// headers.append('Content-Type', 'application/form-data');

// , 'Access-Control-Request-Method': 'GET,POST,PUT,DELETE,OPTIONS,PATCH',


@Injectable({
  providedIn: 'root'
})
export class DataService {
  baseURL: any;
  url: any;
  dummySong = [];
  dummyPlaylist = [];
  dummyCommunity = [];

  mockObject :any =  true;

  constructor(private http: HttpClient, private router: Router) {
    this.baseURL = environment.apiBase;
  }

  private songName = new BehaviorSubject(this.dummySong);
  currentPlayedSong = this.songName.asObservable();

  changeSong(message: any) {
    this.songName.next(message);
  }

  private playlist = new BehaviorSubject(this.dummyPlaylist);
  private pause = new BehaviorSubject(this.dummyPlaylist);
  private community = new BehaviorSubject(this.dummyCommunity);
  currentPlaylist = this.playlist.asObservable();
  pauseSong = this.pause.asObservable();
  currentCommunity = this.community.asObservable();

  changePlaylist(message: any) {
    this.playlist.next(message);
  }

  changeCommunity(message: any) {
    this.playlist.next(message);
  }

  getService(apiUrl: string): Observable<any> {
    this.url = this.baseURL + apiUrl;
    return this.http.get<any[]>(this.url, { responseType: 'json' })
    .pipe(
      catchError(this.handleError(apiUrl, []))
    );
  }

  pauseAudioSong() {
     if (this.mockObject == true){
       this.mockObject = false;
     } else {
      this.mockObject = true;
     }

    this.pause.next(this.mockObject);
  }

  postService(apiUrl: string, data: any): Observable<any> {
    this.url = this.baseURL + apiUrl;
    return this.http.post(this.url, data, { responseType: 'json' })
    .pipe(
      tap((user: any) => console.log(`Operation Performed Successfully`)),
      catchError(this.handleError(apiUrl, []))
    );
  }

  fileService(apiUrl: string, data: any): Observable<any> {
    this.url = this.baseURL + apiUrl;
    return this.http.post(this.url, data, httpFileOptions)
    .pipe(
      tap((user: any) => console.log(`Operation Performed Successfully`)),
      catchError(this.handleError(apiUrl, []))
    );
  }

  deleteService(apiUrl: string): Observable<any> {
    this.url = this.baseURL + apiUrl;
    return this.http.delete(this.url, { responseType: 'json' });
  }

  putService(apiUrl: string, data: any): Observable<any> {
    this.url = this.baseURL + apiUrl;
    return this.http.put(this.url, data, { responseType: 'json' });
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
