import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { environment } from 'src/environments/environment';
import { Track } from 'ngx-audio-player';
import { DataService } from 'src/app/shared-services/data-service/data.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

const LIMIT = 10;

@Component({
  selector: 'app-meditations',
  templateUrl: './meditations.component.html',
  styleUrls: ['./meditations.component.scss', '../main-content/main-content.component.scss']
})

export class MeditationsComponent implements OnInit {

  pageNo = 0;
  offset = LIMIT * this.pageNo;
  categoryId = 1;
  activeTab = 'maditation';

  maditationInfo = [];
  maditationWorkshopInfo = [];
  meditationTechnique = [];
  playAudioInfo = [];
  maditationBaseUrl: any;
  sortBy = 1;
  selectFilterBool = false;
  selectLanguage = [
    { id: 1, name: "English" },
    { id: 2, name: "Hindi" },
  ];
  storageBaseUrl: any;
  noRecordBool = false;
  currentUserId: any;
  showHide: boolean;
  isInitPlayed = false;
  isPaused = false;
  isPlayed = false;
  searchStr = '';
  srchString = '';
  timeout = null;
  isSearching = false;
  showSearchOption = false;

  constructor(private dashboardService: DashboardService, private data: DataService, private spinnerService: Ng4LoadingSpinnerService) {
    this.maditationBaseUrl = environment.storageBaseUrl + '/audios/cover/';
    let currentuser = JSON.parse(localStorage.getItem('loggedInUser'));
    this.currentUserId = parseInt(currentuser.id);
    this.showHide = false;
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getMeditationSongsList('', LIMIT, this.offset, this.categoryId);

    this.data.pauseSong.subscribe(songName => {
      console.log('init audio comp')
      if (songName.toString() == 'true') {
        this.isPaused = true;
        this.isPlayed = false;
      } else {
        this.isPaused = false;
        this.isPlayed = true;
      }
      console.log(this.isPaused)
    })
  }

  changeShowStatus() {
    this.showHide = !this.showHide;
  }

  onOptionsFilterSelected(event) {
    if (event.target.value == 'English') {
      this.selectFilterBool = true;
      this.sortBy = 1;
      this.maditationWorkshopInfo = [];
    } else if (event.target.value == 'Hindi') {
      this.selectFilterBool = true;
      this.sortBy = 2;
      this.maditationWorkshopInfo = [];
    }
    this.pageNo = 0;
    this.offset = LIMIT * this.pageNo;
    this.meditationWorkshopSongsList('', LIMIT, this.offset, this.categoryId);
  }

  tabChange(tab) {
    this.srchString = '';
    this.activeTab = tab;
    this.pageNo = 0;
    this.offset = LIMIT * this.pageNo;
    this.showSearchOption = false;
    if (this.activeTab === 'maditation') {
      this.maditationInfo = [];
      this.getMeditationSongsList('', LIMIT, this.offset, this.categoryId);
    } else if (this.activeTab === 'workshop') {
      this.sortBy = 1;
      this.maditationWorkshopInfo = [];
      this.showSearchOption = true;
      this.meditationWorkshopSongsList('', LIMIT, this.offset, this.categoryId);
    }
    else if (this.activeTab === 'meditationTechnique') {
      this.meditationTechnique = [];
      this.showSearchOption = true;
      this.getMeditationTechSongsList('', LIMIT, this.offset, this.categoryId);
    }
  }

  /* get meditation songs */
  getMeditationSongsList(searchName, LIMIT, offset, catId) {
    const audioParam = {
      searchterm: searchName,
      type: 1,
      limit: LIMIT,
      offset: offset
    }
    this.spinnerService.show();
    this.noRecordBool = false;
    this.dashboardService.getMeditationSongsList(audioParam).subscribe(
      async data => {
        if (data) {
          if (data.respon.length == 0) {
            this.noRecordBool = true;
          }
          this.spinnerService.hide();
          await Promise.all(data.respon.map(async (singleData, i) => {
            this.maditationInfo.push(singleData);
            const currentIndex = this.maditationInfo.length - 1;
            this.maditationInfo[currentIndex].coverImage = `${this.maditationBaseUrl}${singleData.cover}`;
          }));
          console.log('maditationInfo data', this.maditationInfo);
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  /* get meditation workshop songs */
  meditationWorkshopSongsList(searchName, LIMIT, offset, catId) {
    const audioParam = {
      searchterm: searchName,
      type: 2,
      limit: LIMIT,
      album_id: this.sortBy,
      offset: offset
    }
    this.spinnerService.show();
    this.noRecordBool = false;
    this.dashboardService.meditationWorkshopSongsList(audioParam).subscribe(
      async data => {
        if (data) {
          if (data.respon.length == 0) {
            this.noRecordBool = true;
          }
          this.spinnerService.hide();
          await Promise.all(data.respon.map(async (singleData, i) => {
            this.maditationWorkshopInfo.push(singleData);
            const currentIndex = this.maditationWorkshopInfo.length - 1;
            this.maditationWorkshopInfo[currentIndex].coverImage = `${this.maditationBaseUrl}${singleData.cover}`;
          }));
          console.log('maditationInfo workshop data', this.maditationWorkshopInfo);
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  /* get meditation technique songs */
  getMeditationTechSongsList(searchName, LIMIT, offset, catId) {
    const audioParam = {
      searchterm: searchName,
      type: 3,
      limit: LIMIT,
      offset: offset
    }
    this.spinnerService.show();
    this.noRecordBool = false;
    this.dashboardService.getMeditationTechSongsList(audioParam).subscribe(
      async data => {
        if (data) {
          if (data.respon.length == 0) {
            this.noRecordBool = true;
          }
          this.spinnerService.hide();
          await Promise.all(data.respon.map(async (singleData, i) => {
            this.meditationTechnique.push(singleData);
            const currentIndex = this.meditationTechnique.length - 1;
            this.meditationTechnique[currentIndex].coverImage = `${this.maditationBaseUrl}${singleData.cover}`;
          }));
          console.log('meditationTechnique data', this.meditationTechnique);
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  /* Search songs */
  searchSongByKey(searchName) {
    let refObj = this;
    refObj.srchString = searchName;
    if (refObj.activeTab === 'maditation') {
      refObj.maditationInfo = [];
    } else if (refObj.activeTab === 'workshop') {
      refObj.maditationWorkshopInfo = [];
    } else if (refObj.activeTab === 'meditationTechnique') {
      refObj.meditationTechnique = [];
    }
    clearTimeout(refObj.timeout);
    // Make a new timeout set to go off in 500ms
    refObj.timeout = setTimeout(function () {
      console.log('Input Value:', refObj.srchString);
      if (refObj.srchString.length === 0) {
        refObj.srchString = '';
        refObj.isSearching = false;
        refObj.pageNo = 0;
        refObj.offset = LIMIT * refObj.pageNo;
        if (refObj.activeTab === 'maditation') {
          refObj.getMeditationSongsList(refObj.srchString, LIMIT, refObj.offset, refObj.categoryId);
        } else if (refObj.activeTab === 'workshop') {
          refObj.meditationWorkshopSongsList(refObj.srchString, LIMIT, refObj.offset, refObj.categoryId);
        } else if (refObj.activeTab === 'meditationTechnique') {
          refObj.getMeditationTechSongsList(refObj.srchString, LIMIT, refObj.offset, refObj.categoryId);
        }
      } else {
        refObj.isSearching = true;
        refObj.pageNo = 0;
        refObj.offset = LIMIT * refObj.pageNo;
        if (refObj.activeTab === 'maditation') {
          refObj.getMeditationSongsList(refObj.srchString, LIMIT, refObj.offset, refObj.categoryId);
        } else if (refObj.activeTab === 'workshop') {
          refObj.meditationWorkshopSongsList(refObj.srchString, LIMIT, refObj.offset, refObj.categoryId);
        } else if (refObj.activeTab === 'meditationTechnique') {
          refObj.getMeditationTechSongsList(refObj.srchString, LIMIT, refObj.offset, refObj.categoryId);
        }
      }
    }, 500);
  }


  /* call play audio method */
  public playedId = 0;
  playAudioSong(songArr, index) {
    this.isPlayed = true;
    this.isPaused = false;

    this.playedId = songArr[index].id;
    //this.playAudioInfo = [];
    //this.playAudioInfo[0] = { showPlayerBool: true, songInfo: songArr[index], format: "audio" };
    this.playAudioInfo = [
      { showPlayerBool: true, songInfo: songArr[index], format: "audio" }
    ]
    this.songAddToplaylist(songArr[index]);
    //songArr.splice(index, 1);
    for (let i = index + 1; i < songArr.length; i++) {
      this.playAudioInfo.push(
        { showPlayerBool: true, songInfo: songArr[i], format: "audio" });

    }
    for (let j = 0; j < index; j++) {
      this.playAudioInfo.push(
        { showPlayerBool: true, songInfo: songArr[j], format: "audio" });
    }
    this.isInitPlayed = true;
    this.data.changeSong(this.playAudioInfo);
  }


  /* Add to Community Method */
  sendToCommunity(songInfo) {
    console.log('send to community audio comp')
    let communityParam = [
      { "trackType": 'community', "TrackTypeId": 2, "songId": songInfo.id }
    ];
    this.data.changeCommunity(communityParam);
  }
  /* add song to playlist */
  songAddToplaylist(songArr) {
    const audioParam = {
      user_id: this.currentUserId,
      track_id: songArr.id,
      track_type: 2
    };
    this.dashboardService.songAddToplaylist(audioParam).subscribe(
      async data => {
        if (data) {
          console.log("song added to playlist")
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  /* call pagination method */
  nextPage(): void {
    this.pageNo = this.pageNo + 1;
    this.offset = (this.pageNo * LIMIT);
    if (this.activeTab === 'maditation') {
      this.getMeditationSongsList(this.srchString, LIMIT, this.offset, this.categoryId);
    } else if (this.activeTab === 'workshop') {
      this.meditationWorkshopSongsList(this.srchString, LIMIT, this.offset, this.categoryId);
    }
    else if (this.activeTab === 'meditationTechnique') {
      this.getMeditationTechSongsList(this.srchString, LIMIT, this.offset, this.categoryId);
    }
  }

  /* Add to Plalist Method */
  sendToPlaylist(songInfo) {
    let playlistParam = [
      { "trackType": 'Jokes', "TrackTypeId": 2, "songId": songInfo.id }
    ];
    this.data.changePlaylist(playlistParam);
  }

}
