import { Component, OnInit } from '@angular/core';
import { TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DashboardService } from '../dashboard.service';
import { environment } from 'src/environments/environment';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
/* popup import files */
import { ViewContainerRef } from '@angular/core';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { DataService } from 'src/app/shared-services/data-service/data.service';

@Component({
  selector: 'app-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.scss', '../main-content/main-content.component.scss']
})
export class PlaylistComponent implements OnInit {
  
  modalRef: BsModalRef;
  allPlaylistInfo = [];
  playlistName = "";
  playlistNameTitle = "";
  playlistSongsInfo = [];
  isPaused = false;
  isPlayed = false;
  isInitPlayed = false;
  audioBaseUrl:any;
  showListSongsBool = false;
  playAudioInfo = [];
  noRecordBool = false;
  storageBaseUrl: any;
  currentUserId:any;

  constructor(private dashboardService: DashboardService,private modalService: BsModalService,private spinnerService: Ng4LoadingSpinnerService,private modalDialogService: ModalDialogService, private viewContainer: ViewContainerRef,private data: DataService) { 
    this.audioBaseUrl = environment.storageBaseUrl + '/audios/cover/';
  }
  
  ngOnInit() {
    window.scrollTo(0, 0);
    let currentuser = JSON.parse(localStorage.getItem('loggedInUser'));
    this.currentUserId = parseInt(currentuser.id);
    console.log("Current User Id : ",this.currentUserId);
    this.getAllPlaylist();

    this.data.pauseSong.subscribe(songName => {
      console.log('init audio comp')
      if (songName.toString() == 'true') {
        this.isPaused = true;
        this.isPlayed = false;
      } else {
        this.isPaused = false;
        this.isPlayed = true;
      }
      console.log(this.isPaused)
    })
  }
  
  openSimpleModal(message) {
    this.modalDialogService.openDialog(this.viewContainer, {
      /* title: 'Simple dialog', */
      childComponent: SimpleModalComponent,
      settings: {
        closeButtonClass: 'fa fa-times'
      },
      data: {
        text: message
      }
    });
  }
  
  /* get all playlist data */
  getAllPlaylist(){
    const playListparam = {
      'X-API-KEY' : 'testkey',
      user_id:this.currentUserId
    }
    this.spinnerService.show();
    this.noRecordBool = false;
    this.dashboardService.getAllPlaylist(playListparam).subscribe(
      async data => {
        this.spinnerService.hide();
        if(data.respon.length == 0){
          this.noRecordBool = true;
        }
        if (data) {
          this.allPlaylistInfo = data.respon;
        }
      },
      err => {
        return console.error(err);
      }
    );
  }
  
  createPlaylist(){
    const createPlaylistParam = {
      user_id:this.currentUserId,
      name:this.playlistName
    }
    this.spinnerService.show();
    this.dashboardService.createPlaylist(createPlaylistParam).subscribe(
      async data => {
        if (data) {
          this.spinnerService.hide();
          this.getAllPlaylist();
          this.playlistName = "";
          this.modalRef.hide();
          this.openSimpleModal("Playlist added successfully.");
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  deleteSinglePlaylist(singlePlaylist){
    this.openPromptModal(singlePlaylist);
  }

  openPromptModal(singlePlaylist) {
    this.modalDialogService.openDialog(this.viewContainer, {
      title: 'Delete Playlist?', 
      childComponent: SimpleModalComponent,
      data: {
        text: 'Are you sure you want to delete '+'"'+singlePlaylist.name+'"'+' ?'
      },
      settings: {
        closeButtonClass: 'close theme-icon-close',
        headerTitleClass:"headerWithoutData"
      },
      actionButtons: [
        {
          text: 'Yes',
          buttonClass: 'btn btn-success',
          onAction: () => {
            this.deleteRecord(singlePlaylist);
            return true;
          }
        },
        {
          text: 'No',
          buttonClass: 'btn btn-danger',
          onAction: () => true 
        },
      ]
    });
  }
  
  
  /* delete single playlist */
  deleteRecord(singlePlaylist){
    const createPlaylistParam = {
      'X-API-KEY' : "testkey",
      id : singlePlaylist.id
    }
    this.spinnerService.show();
    this.dashboardService.deleteSinglePlaylist(createPlaylistParam).subscribe(
      async data => {
        if (data) {
          this.spinnerService.hide();
          this.getAllPlaylist();
          this.openSimpleModal("Playlist deleted successfully.");
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  showPlaylistSongs(playlistSongs){
    this.spinnerService.show();
    this.playlistNameTitle = playlistSongs.name;
    this.showListSongsBool = true;
    this.playlistSongsInfo = [];
    let playlistId = playlistSongs.id;
    this.noRecordBool = false;
    this.dashboardService.showPlaylistSongs(playlistId).subscribe(
      async data => {
        if(data.respon.length == 0){
          this.noRecordBool = true;
          this.spinnerService.hide();
        }
        if (data) {
          await Promise.all(data.respon.map(async (singleData,i) => {
            this.playlistSongsInfo.push(singleData[0]);
            const currentIndex = this.playlistSongsInfo.length - 1;
            this.playlistSongsInfo[currentIndex].coverImage = `${this.audioBaseUrl}${singleData[0].cover}`;
          }));
          this.spinnerService.hide();
        }
      },
      err => {
        return console.error(err);
      }
    );
  }
  
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  
  backToPlaylist(){
    this.showListSongsBool = false;
    this.noRecordBool = false;
  }

  /* call play audio method */
  public playedId = 0;
  playAudioSong(songArr, index) {
    console.log('index')
    console.log(index);
    this.isPlayed = true;
    this.isPaused = false;
    console.log('play audio called')

    this.playedId = songArr[index].id;
    this.playAudioInfo = [
      { showPlayerBool: true, songInfo: songArr[index], format: "audio" }
    ];
    this.songAddToplaylist(songArr[index]);
    //songArr.splice(index, 1);
    for (let i = index + 1; i < songArr.length; i++) {
      // this.songAddToplaylist(songArr[i]);
      this.playAudioInfo.push(
        { showPlayerBool: true, songInfo: songArr[i], format: "audio" });

    }
    for (let j = 0; j < index; j++) {
      // this.songAddToplaylist(songArr[j]);
      this.playAudioInfo.push(
        { showPlayerBool: true, songInfo: songArr[j], format: "audio" });
    }
    this.isInitPlayed = true;
    this.data.changeSong(this.playAudioInfo);
  }

    /* add song to playlist */
    songAddToplaylist(songArr) {
      const audioParam = {
        user_id: this.currentUserId,
        track_id: songArr.id,
        track_type: 1
      };
      this.dashboardService.songAddToplaylist(audioParam).subscribe(
        async data => {
          if (data) {
          }
        },
        err => {
          return console.error(err);
        }
      );
    }
  
}
