import { HttpErrorResponse, JsonpInterceptor } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DashboardService } from '../ask-osho/dashboard/dashboard.service';
import { DataService } from 'src/app/shared-services/data-service/data.service';
import { Router } from '@angular/router';
import { Track } from 'ngx-audio-player';
import { environment } from 'src/environments/environment';
import { Meta, Title, MetaDefinition } from '@angular/platform-browser';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-audio-videoplay',
  templateUrl: './audio-videoplay.component.html',
  styleUrls: ['./audio-videoplay.component.scss']
})
export class AudioVideoplayComponent implements OnInit {
  audioBaseUrl: any;
  videoBaseUrl: any;
  public getavCoverImage:any;
  playAudioInfo: Array<any> = [];
  isPaused = false;
  isPlayed = false;
  hideButton = false;
  avTitle: any;
  constructor(private apiCall:DashboardService,
              private data: DataService,
              private router: Router, public meta: Meta, public pageTitle: Title) {
                this.audioBaseUrl = environment.storageBaseUrl + '/audios/cover/';
                this.videoBaseUrl = environment.storageBaseUrl + '/videos/cover/';
                this.data.pauseSong.subscribe(songName => {
                  if (songName.toString() == 'true') {
                    this.isPaused = true;
                    this.isPlayed = false;
                  } else {
                    this.isPaused = false;
                    this.isPlayed = true;
                  }
                })
               }

  ngOnInit() {
    if(!localStorage.getItem('loggedInUser')){
      this.router.navigate(['/auth'], { state: { 'openLogin': true, 'redirectURLAfterLogin': this.router.url.replace('param=', '').replace('%3D', '=') } } );
    } else {

      //Check subscription and redirect to subscription page
      var usersDetail = JSON.parse(localStorage.getItem('loggedInUser'));
      var CurrentDate = new Date();
      var subscriptionEndDate = new Date(usersDetail.subscriptionEndDate);
  
      if(subscriptionEndDate < CurrentDate){
          
        this.router.navigate(['/ask-osho/subscription']);

      } else {
        this.getavCoverImage = 'assets/images/av_bg.jpg';
        this.playAudioVideo();
      }

    }
  }

  public playAudioVideo(): void{
    console.log("in play autio", this.router.url.replace('param=', '').replace('%3D', '='));
    const reqObj={
      link: this.router.url.replace('param=', '').replace('%3D', '=')
      //audio link
      // link:'https://www.askbhagwan.com/avplay?MTkmMSY2'
      // link:"https://www.askbhagwan.com/avplay?NjMmMSYz"
      //video link
      // link:'https://www.askbhagwan.com/avplay?MiYyJjE3' 
      // link:'https://avapplication.s3.amazonaws.com/audios/track/1537542020The_blooming_Dales.mp3'
    }
    this.apiCall.getaudioorvideo(reqObj).subscribe((res:any) =>{
      this.avTitle = res.respon.name;
      
      this.pageTitle.setTitle("Ask Bhagwan");

      const title: MetaDefinition = {
        property:'og:title',
        content: res.respon.file_name
      }
      const description: MetaDefinition = {
        property:'og:description',
        content: res.respon.name
      }
      const description1: MetaDefinition = {
        name:'description',
        content: res.respon.name
      }
      const type: MetaDefinition = {
        property:'og:type',
        content: 'website'
      }
      const url: MetaDefinition = {
        property:'og:url',
        content: this.router.url
      }
      const image: MetaDefinition = {
        property:'og:image',
        content: 'https://askbhagwan.com/assets/images/ask__bhagwan.jpg'
      }
      const image_w: MetaDefinition = {
        property:'og:image:width',
        content: '256'
      }
      const image_h: MetaDefinition = {
        property:'og:image:height',
        content: '256'
      }

      this.meta.addTags([description1, title, type, description, url, image, image_w, image_h]);

      if(res.respon.format == '1'){
        // this.getavCoverImage = `${this.audioBaseUrl}${res.respon.cover}`;
        this.playAudioInfo = [{ showPlayerBool: true, songInfo: res.respon, format: "audio" }]

      }else if(res.respon.format == '2'){
        // this.getavCoverImage = `${this.videoBaseUrl}${res.respon.cover}`;
        this.playAudioInfo = [{ showPlayerBool: true, songInfo: res.respon, format: "video" }]
      }
    },(error:HttpErrorResponse)=>{
      console.log(error);
    });
  }

  playMusic(){
    this.hideButton = true;
    this.data.changeSong(this.playAudioInfo);
  }
}
