// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// staging server data
// export const environment = {
//   production: false,
//   apiBase: 'http://3.111.148.57',
//   env: 'dev',
//   storageBaseUrl: 'https://avapplication.s3.amazonaws.com',
//   baseUrl: 'http://3.111.148.57'
// };

// // Live data
export const environment = {
  production: false,
  apiBase: 'https://ask-osho.net/',
  storageBaseUrl: 'https://avapplication.s3.amazonaws.com',
  baseUrl: 'https://ask-osho.net/',
  env: 'dev'
};

// Local data
// export const environment = {
//   production: false,
//   apiBase: 'http://localhost/php-askbhagwan/',
//   storageBaseUrl: 'https://avapplication.s3.amazonaws.com',
//   baseUrl: 'http://localhost/php-askbhagwan/',
//   env: 'dev'
// };


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
