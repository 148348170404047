import { Component, OnInit,TemplateRef } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { environment } from 'src/environments/environment';
import { Track } from 'ngx-audio-player';
import { DataService } from 'src/app/shared-services/data-service/data.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NgNavigatorShareService } from 'ng-navigator-share';

const LIMIT = 10;

@Component({
  selector: 'app-jokes',
  templateUrl: './jokes.component.html',
  styleUrls: ['./jokes.component.scss', '../main-content/main-content.component.scss']
})

export class JokesComponent implements OnInit {
  pageNo = 0;
  offset = LIMIT * this.pageNo;
  jokesInfo = [];
  jokesUrl: any;
  sortBy = 1;
  playAudioInfo = [];
  selectFilterBool = false;
  srchString = '';
  isSearching = false;
  selectLanguage = [
    { id: 1, name: "English" },
    { id: 2, name: "Hindi" },
  ];

  storageBaseUrl: any;
  noRecordBool = false;
  currentUserId: any;
  timeout = null;

  isInitPlayed = false;
  isPaused = false;
  isPlayed = false;

  modalRef: BsModalRef;
  shareAudio = [];


  constructor(private dashboardService: DashboardService, private data: DataService, private spinnerService: Ng4LoadingSpinnerService,
    private modalService: BsModalService, private ngNavigatorShareService: NgNavigatorShareService) {
    this.jokesUrl = environment.storageBaseUrl + '/audios/cover/';
    let currentuser = JSON.parse(localStorage.getItem('loggedInUser'));
    this.currentUserId = parseInt(currentuser.id);
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getJokesSongsList('', LIMIT, this.offset, this.sortBy);

    this.data.pauseSong.subscribe(songName => {
      console.log('init audio comp');
      if (songName.toString() === 'true') {
        this.isPaused = true;
        this.isPlayed = false;
      } else {
        this.isPaused = false;
        this.isPlayed = true;
      }
      console.log(this.isPaused);
    });
  }

  onOptionsFilterSelected(event) {
    if (event.target.value == 'English') {
      this.selectFilterBool = true;
      this.sortBy = 1;
      this.jokesInfo = [];
    } else if (event.target.value == 'Hindi') {
      this.selectFilterBool = true;
      this.sortBy = 2;
      this.jokesInfo = [];
    }
    this.pageNo = 0;
    this.offset = LIMIT * this.pageNo;
    this.getJokesSongsList('', LIMIT, this.offset, this.sortBy);
  }

  searchSongByKey(searchName) {
    var refObj = this;
    refObj.srchString = searchName;
    refObj.jokesInfo = [];
    clearTimeout(refObj.timeout);
    // Make a new timeout set to go off in 500ms
    refObj.timeout = setTimeout(function () {
      console.log('Input Value:', refObj.srchString);
      if (refObj.srchString.length == 0) {
        refObj.srchString = ''
        refObj.isSearching = false;
        refObj.pageNo = 0;
        refObj.offset = LIMIT * refObj.pageNo;
        refObj.getJokesSongsList(refObj.srchString, LIMIT, refObj.offset, refObj.sortBy);
      } else {
        refObj.isSearching = true;
        refObj.pageNo = 0;
        refObj.offset = LIMIT * refObj.pageNo;
        refObj.getJokesSongsList(refObj.srchString, LIMIT, refObj.offset, refObj.sortBy);
      }
    }, 500);
  }

  /* get Jokes songs */
  getJokesSongsList(searchName, LIMIT, offset, sortBy) {
    const audioParam = {
      searchterm: searchName,
      album_id: sortBy,
      limit: LIMIT,
      offset: offset
    }
    this.spinnerService.show();
    this.noRecordBool = false;
    this.dashboardService.getJokesSongsList(audioParam).subscribe(
      async data => {
        if (data) {
          if (data.respon.length == 0) {
            this.noRecordBool = true;
          }
          this.spinnerService.hide();
          await Promise.all(data.respon.map(async (singleData, i) => {
            this.jokesInfo.push(singleData);
            const currentIndex = this.jokesInfo.length - 1;
            this.jokesInfo[currentIndex].coverImage = `${this.jokesUrl}${singleData.cover}`;
          }));
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  /* call play audio method */
  public playedId = 0;
  playAudioSong(songArr, index) {
    this.isPlayed = true;
    this.isPaused = false;

    this.playedId = songArr[index].id;
    this.playAudioInfo = [
      { showPlayerBool: true, songInfo: songArr[index], format: 'audio' }
    ];
    this.songAddToplaylist(songArr[index]);
    for (let i = index + 1; i < songArr.length; i++) {
      this.playAudioInfo.push(
        { showPlayerBool: true, songInfo: songArr[i], format: 'audio' });

    }
    for (let j = 0; j < index; j++) {
      this.playAudioInfo.push(
        { showPlayerBool: true, songInfo: songArr[j], format: 'audio' });
    }
    this.isInitPlayed = true;
    this.data.changeSong(this.playAudioInfo);
  }

  /* call pagination method */
  nextPage(): void {
    this.pageNo = this.pageNo + 1;
    this.offset = (this.pageNo * LIMIT);
    this.getJokesSongsList(this.srchString, LIMIT, this.offset, this.sortBy);

  }

  /* Add to Community Method */
  sendToCommunity(songInfo) {
    console.log('send to community jokes comp');
    this.modalService.hide(1);
    let communityParam = [
      { "trackType": 'community', "TrackTypeId": 7, "songId": songInfo.id }
    ];
    this.data.changeCommunity(communityParam);
  }

  /* Add to Plalist Method */
  sendToPlaylist(songInfo) {
    let playlistParam = [
      { "trackType": 'Jokes', "TrackTypeId": 1, "songId": songInfo.id }
    ];
    this.data.changePlaylist(playlistParam);
  }

  /* add song to playlist */
  songAddToplaylist(songArr) {
    const audioParam = {
      user_id: this.currentUserId,
      track_id: songArr.id,
      track_type: 5
    };
    this.dashboardService.songAddToplaylist(audioParam).subscribe(
      async data => {
        if (data) {
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  shareToSocial(audioInfo) {
    console.log("in audio", audioInfo );
    // if (!this.ngNavigatorShareService.canShare()) {
    //   alert(`This service/api is not supported in your Browser`);
    //   return;
    // }
    this.spinnerService.show();
    
    const reqObj={
      av_id: parseInt(audioInfo.id),
      user_id: this.currentUserId,
      media_type: parseInt(audioInfo.category),
      media_category: 4
    }
    this.dashboardService.getAvLink(reqObj).subscribe((res:any) =>{
      console.log("link", res.respon);

      this.ngNavigatorShareService.share({
        title: res.respon.name,
        text: res.respon.name,
        url: res.respon.link
      }).then( (response) => {
        console.log(response);
        this.modalService.hide(1);
        this.spinnerService.hide();
      })
      .catch( (error) => {
        this.spinnerService.hide();
        alert("Something went wrong! Please try after sometime");
      });

      

    },(error)=>{
      console.log("error", error);
    });
    
  }

  openShareModal(template: TemplateRef<any>, audio) {
    this.shareAudio = audio;
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered' 
    });
  }

}
