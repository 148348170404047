import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { environment } from 'src/environments/environment';
import { Track } from 'ngx-audio-player';
import { DataService } from 'src/app/shared-services/data-service/data.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';


const LIMIT = 10;

@Component({
  selector: 'app-interviews',
  templateUrl: './interviews.component.html',
  styleUrls: ['./interviews.component.scss', '../main-content/main-content.component.scss']
})
export class InterviewsComponent implements OnInit {
  
  pageNo = 0;
  offset = LIMIT * this.pageNo;
  activeTab = 'Audios';
  audioBaseUrl:any;
  videoBaseUrl:any;
  playAudioInfo = [];
  playVideoInfo = [];
  selectFilterBool = false;
  sortBy = 1;
  interviewSongsInfo = [];
  interviewVideoInfo = [];
  selectLanguage=[
    { id: 1, name: "English" },
    { id: 2, name: "Hindi" },
  ];
  storageBaseUrl: any;
  noRecordBool = false;
  currentUserId:any;
  // Searching
  searchStr = '';
  srchString = '';
  timeout = null;
  isSearching = false;

  isInitPlayed = false;
  isPaused = false;
  isPlayed = false;
  showSearchOption = false;

  constructor(private dashboardService: DashboardService,private data: DataService,private spinnerService: Ng4LoadingSpinnerService) { 
    this.audioBaseUrl = environment.storageBaseUrl + '/audios/cover/';
    this.videoBaseUrl = environment.storageBaseUrl + '/videos/cover/';
    let currentuser = JSON.parse(localStorage.getItem('loggedInUser'));
    this.currentUserId = parseInt(currentuser.id);
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getInterviewSongsList('', LIMIT,this.offset);

    this.data.pauseSong.subscribe(songName => {
      console.log('init audio comp');
      if (songName.toString() === 'true') {
        this.isPaused = true;
        this.isPlayed = false;
      } else {
        this.isPaused = false;
        this.isPlayed = true;
      }
      console.log(this.isPaused);
    });
  }

  tabChange(tab) {
    this.srchString = '';
    this.activeTab = tab;
    this.pageNo = 0;
    this.offset = LIMIT * this.pageNo;
    this.showSearchOption = false;
    if (this.activeTab === 'Audios') {
      this.interviewSongsInfo = [];
      this.sortBy = 1;
      this.showSearchOption = true;
      this.getInterviewSongsList('', LIMIT,this.offset);
    } else if(this.activeTab === 'Videos') {
      this.sortBy = 1;
      this.interviewVideoInfo = [];
      this.showSearchOption = true;
      this.getInterviewVideoList('', LIMIT,this.offset);
    }
  }

  onOptionsFilterSelected(event){
    this.pageNo = 0;
    this.offset = LIMIT * this.pageNo;
    if(event.target.value==='English'){
      this.selectFilterBool=true;
      this.sortBy = 1;
      this.interviewSongsInfo = [];
    }else if(event.target.value==='Hindi'){
      this.selectFilterBool=true;
      this.sortBy = 2;
      this.interviewSongsInfo = [];
    }
    this.getInterviewSongsList('', LIMIT,this.offset);
  }

  onOptionsFilterVideosSelected(event) {
    if(event.target.value==='English') {
      this.selectFilterBool=true;
      this.sortBy = 1;
      this.interviewVideoInfo = [];
    }else if(event.target.value==='Hindi'){
      this.selectFilterBool=true;
      this.sortBy = 2;
      this.interviewVideoInfo = [];
    }
    this.pageNo = 0;
    this.offset = LIMIT * this.pageNo;
    this.getInterviewVideoList('', LIMIT,this.offset);
  }

  /* get meditation songs */
  getInterviewSongsList(searchName, LIMIT,offset) {
    const audioParam = {
      searchterm: searchName,
      album_id:this.sortBy,
      limit:LIMIT,
      offset:offset
    }
    this.spinnerService.show();
    this.noRecordBool = false;
    this.dashboardService.getInterviewSongsList(audioParam).subscribe(
      async data => {
        if (data) {
          if(data.respon.length == 0){
            this.noRecordBool = true;
          }
          this.spinnerService.hide();
          await Promise.all(data.respon.map(async (singleData, i) => {
            this.interviewSongsInfo.push(singleData);
            const currentIndex = this.interviewSongsInfo.length - 1;
            this.interviewSongsInfo[currentIndex].coverImage = `${this.audioBaseUrl}${singleData.cover}`;
          }));
        }
      },
      err => {
        this.spinnerService.hide();
        return console.error(err);
      }
    );
  }

   /* get video songs */
   getInterviewVideoList(searchName, LIMIT,offset) {
    const audioParam = {
      searchterm: searchName,
      album_id:this.sortBy,
      limit:LIMIT,
      offset:offset
    }
    this.spinnerService.show();
    this.noRecordBool = false;
    this.dashboardService.getInterviewVideoList(audioParam).subscribe(
      async data => {
        if (data) {
          if(data.respon.length == 0){
            this.noRecordBool = true;
          }
          this.spinnerService.hide();
          await Promise.all(data.respon.map(async (singleData, i) => {
            this.interviewVideoInfo.push(singleData);
            const currentIndex = this.interviewVideoInfo.length - 1;
            this.interviewVideoInfo[currentIndex].coverImage = `${this.videoBaseUrl}${singleData.cover}`;
          }));
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  /* Search songs */
  searchSongByKey(searchName) {
    let refObj = this;
    refObj.srchString = searchName;
    if (refObj.activeTab === 'Audios') {
      refObj.interviewSongsInfo = [];
    } else if(refObj.activeTab === 'Videos') {
      refObj.interviewVideoInfo = [];
    }
    clearTimeout(refObj.timeout);
    // Make a new timeout set to go off in 500ms
    refObj.timeout = setTimeout(function () {
      console.log('Input Value:', refObj.srchString);
      if (refObj.srchString.length === 0) {
        refObj.srchString = '';
        refObj.isSearching = false;
        refObj.pageNo = 0;
        refObj.offset = LIMIT * refObj.pageNo;
        if (refObj.activeTab === 'Audios') {
          refObj.getInterviewSongsList(refObj.srchString, LIMIT,refObj.offset);
        } else if(refObj.activeTab === 'Videos') {
          refObj.getInterviewVideoList(refObj.srchString, LIMIT,refObj.offset);
        }
      } else {
        refObj.isSearching = true;
        refObj.pageNo = 0;
        refObj.offset = LIMIT * refObj.pageNo;
        if (refObj.activeTab === 'Audios') {
          refObj.getInterviewSongsList(refObj.srchString, LIMIT,refObj.offset);
        } else if(refObj.activeTab === 'Videos') {
          refObj.getInterviewVideoList(refObj.srchString, LIMIT,refObj.offset);
        }
      }
    }, 500);
  }

  /* call play audio method */
  public playedId = 0;
  playAudioSong(songArr, index) {
    this.isPlayed = true;
    this.isPaused = false;

    this.playedId = songArr[index].id;
    this.playAudioInfo = [
      { showPlayerBool: true, songInfo: songArr[index], format: 'audio' }
    ];
    this.songAddToplaylist(songArr[index]);
    for (let i = index + 1; i < songArr.length; i++) {
      this.playAudioInfo.push(
        { showPlayerBool: true, songInfo: songArr[i], format: 'audio' });

    }
    for (let j = 0; j < index; j++) {
      this.playAudioInfo.push(
        { showPlayerBool: true, songInfo: songArr[j], format: 'audio' });
    }
    this.isInitPlayed = true;
    this.data.changeSong(this.playAudioInfo);
    
  }

  playVideoSong(songArr) {
    this.playVideoInfo = [
      {showPlayerBool:true,songInfo:songArr,format:"video"}
    ]
    this.data.changeSong(this.playVideoInfo);
   
  }

  /* Add to Community Method */
sendToCommunity(songInfo) {
  console.log('send to community audio comp')
  let communityParam = [
    { "trackType": 'community', "TrackTypeId": 4, "songId": songInfo.id }
  ];
  this.data.changeCommunity(communityParam);
}
  /* add song to playlist */ 
  songAddToplaylist(songArr){
    const audioParam = {
      user_id:this.currentUserId,
      track_id:songArr.id,
      track_type:4
    };
    this.dashboardService.songAddToplaylist(audioParam).subscribe(
      async data => {
        if (data) {
        }
      },
      err => {
        return console.error(err);
      }
    );
  }


  /* call pagination method */
  nextPage(): void {
    this.pageNo = this.pageNo + 1;
    this.offset = (this.pageNo * LIMIT);
    if (this.activeTab === 'Audios') {
      this.getInterviewSongsList(this.srchString, LIMIT,this.offset);
    }else if(this.activeTab === 'Videos'){
      this.getInterviewVideoList(this.srchString, LIMIT,this.offset);
    }
  }

  /* Add to Plalist Method */
  sendToPlaylist(songInfo){
    let playlistParam = [
      {"trackType":'Jokes',"TrackTypeId":4,"songId":songInfo.id}
    ];
    this.data.changePlaylist(playlistParam);
  }
}
