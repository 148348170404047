import { ChangeDetectionStrategy, Component, TemplateRef, Input } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { DashboardService } from '../dashboard.service';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss'],
  providers: [DashboardService]
})
export class SocialShareComponent {

  currentUserId: any;
  modalRef: BsModalRef;
  shareAudio = [];

  constructor(private dashboardService: DashboardService, private modalService: BsModalService, private spinnerService: Ng4LoadingSpinnerService, private ngNavigatorShareService: NgNavigatorShareService) {
    let currentuser = JSON.parse(localStorage.getItem('loggedInUser'));
    this.currentUserId = parseInt(currentuser.id);
  }

  shareToSocial(audioInfo) {
    console.log("in audio", audioInfo );
    // if (!this.ngNavigatorShareService.canShare()) {
    //   alert(`This service/api is not supported in your Browser`);
    //   return;
    // }
    this.spinnerService.show();
    
    const reqObj={
      av_id: parseInt(audioInfo.id),
      user_id: this.currentUserId,
      media_type: parseInt(audioInfo.category),
      media_category: parseInt(audioInfo.category)
    }
    this.dashboardService.getAvLink(reqObj).subscribe((res:any) =>{
      console.log("link", res.respon);

      this.ngNavigatorShareService.share({
        title: res.respon.name,
        text: res.respon.name,
        url: res.respon.link
      }).then( (response) => {
        console.log(response);
        this.modalService.hide(1);
        this.spinnerService.hide();
      })
      .catch( (error) => {
        this.spinnerService.hide();
        alert("Something went wrong! Please try after sometime");
      });     

    },(error)=>{
      console.log("error", error);
    });
    
  }

  openShareModal(template: TemplateRef<any>, audio) {
    console.log("in social share", template, audio);
    this.shareAudio = audio;
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered' 
    });
    
  }
}
