import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { DataService } from 'src/app/shared-services/data-service/data.service';
import { environment } from 'src/environments/environment';
import { SwiperComponent } from "swiper/angular";

// import Swiper core and required components
import SwiperCore , {
  Navigation
} from 'swiper';


SwiperCore.use([
  Navigation
]);

const LIMIT = 10;
@Component({
  selector: 'app-recently-played',
  templateUrl: './recently-played.component.html',
  styleUrls: ['./recently-played.component.scss', '../main-content/main-content.component.scss'],
  providers: [DashboardService]
})
export class RecentlyPlayedComponent implements OnInit {
  @ViewChild('swiperRef') swiperRef?: SwiperComponent;

  recentlyPlayedList: any = [];
  // audioBaseUrl = `https://avapplicationstaging.s3.amazonaws.com/audios/cover/`;
  audioBaseUrl = null;
  userId = 1;
  pageNo = 0;
  offset = LIMIT * this.pageNo;
  playAudioInfo = [];

  constructor(private dashboardService: DashboardService,private data: DataService) {
    let currentuser = JSON.parse(localStorage.getItem('loggedInUser'));
    this.userId = parseInt(currentuser.id);
   }

   breakpoints = {
    280: { slidesPerView: 3, spaceBetween: 20 },
    320: { slidesPerView: 4, spaceBetween: 20 },
    480: { slidesPerView: 4, spaceBetween: 20 },
    640: { slidesPerView: 5, spaceBetween: 20 },
    768: { slidesPerView: 7, spaceBetween: 20 },
    1024: { slidesPerView: 7, spaceBetween: 20 },
    1360: { slidesPerView: 9, spaceBetween: 20 },
  };

  breakPointsToggle: boolean;
  breakpointChange() {
    this.breakPointsToggle = !this.breakPointsToggle;
    this.breakpoints = {
      280: { slidesPerView: 3, spaceBetween: 20 },
      320: { slidesPerView: 4, spaceBetween: 20 },
      480: { slidesPerView: 4, spaceBetween: 20 },
      640: { slidesPerView: 5, spaceBetween: 20 },
      768: { slidesPerView: 7, spaceBetween: 20 },
      1024: { slidesPerView: 7, spaceBetween: 20 },
      1360: { slidesPerView: 9, spaceBetween: 20 },
    };
  }

  ngOnInit() {
    this.getRecentlyPlayedList(this.userId, LIMIT, this.offset);
    this.audioBaseUrl = environment.storageBaseUrl + '/audios/cover/';
  }


  getRecentlyPlayedList(id, limit, offset) {
    console.log('LIMIT', limit, 'OFFSET', offset);
    this.dashboardService.getRecentlyPlayedSongs(id, limit, offset).subscribe(
      async data => {
        if (data) {
          await Promise.all(data.respon.map(async (singleData, i) => {
            this.recentlyPlayedList.push(singleData[0]);
            const currentIndex = this.recentlyPlayedList.length - 1;
            this.recentlyPlayedList[currentIndex].coverImage = `${this.audioBaseUrl}${singleData[0].cover}`;
          }));
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  nextPage(): void {
    this.pageNo = this.pageNo + 1;
    this.offset = (this.pageNo * LIMIT);
    this.getRecentlyPlayedList(this.userId, LIMIT, this.offset);
  }

  playAudioSong(songArr,index) {
    console.log('index')
    console.log(index);
    console.log('play audio called')
    this.playAudioInfo = [];
        this.playAudioInfo[0] = { showPlayerBool: true, songInfo: songArr[index], format: "audio" };
        this.songAddToplaylist(songArr[index]);
        //songArr.splice(index, 1);
        for(let i=index+1 ; i< songArr.length ; i++){
              this.songAddToplaylist(songArr[i]);
              this.playAudioInfo.push(
                { showPlayerBool: true, songInfo: songArr[i], format: "audio" });

        }
        for( let j =0 ; j< index; j++){
          this.songAddToplaylist(songArr[j]);
          this.playAudioInfo.push(
            { showPlayerBool: true, songInfo: songArr[j], format: "audio" });
        } 
    this.data.changeSong(this.playAudioInfo);
  }

  songAddToplaylist(songArr) {
    const audioParam = {
      user_id: this.userId,
      track_id: songArr.id,
      track_type: 1
    };
    this.dashboardService.songAddToplaylist(audioParam).subscribe(
      async data => {
        if (data) {
        }
      },
      err => {
        return console.error(err);
      }
    );
  }


}
