import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Track } from 'ngx-audio-player';
import { DataService } from 'src/app/shared-services/data-service/data.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TemplateRef } from '@angular/core';
import { SwiperComponent } from "swiper/angular";

// import Swiper core and required components
import SwiperCore , {
  Navigation,
  Autoplay
} from 'swiper';


SwiperCore.use([
  Navigation,
  Autoplay
]);


const LIMIT = 10;
const MASTER_SUBSCRIPTION_ID = 1;

@Component({
  selector: 'app-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.scss']
})
export class MainContentComponent implements OnInit {
  @ViewChild('swiperRef') swiperRef?: SwiperComponent;

  sortBy = 1;
  discourseSortBy = 1;
  noRecordBool = false;
  audioInfo = [];
  audioBaseUrl: any;
  pageNo = 0;
  offset = LIMIT * this.pageNo;
  categoryId = 1;
  playAudioInfo = [];
  audioDiscourseInfo = [];
  videoInfo = [];
  videoDiscourseInfo = [];
  videoBaseUrl: any;
  playVideoInfo = [];
  meditationMusicInfo = [];
  modalRef: BsModalRef;
  currentUserId: any;
  subscriptionBody: any;
  isInitPlayed = false;
  isPaused = false;
  isPlayed = false;


  constructor(private dashboardService: DashboardService, private httpClient: HttpClient, private data: DataService, private spinnerService: Ng4LoadingSpinnerService, private modalService: BsModalService, ) {
    this.audioBaseUrl = environment.storageBaseUrl + '/audios/cover/';
    this.videoBaseUrl = environment.storageBaseUrl + '/videos/cover/';
    let currentuser = JSON.parse(localStorage.getItem('loggedInUser'));
    this.currentUserId = parseInt(currentuser.id);
  }

  breakpoints = {
    280: { slidesPerView: 3, spaceBetween: 20 },
    320: { slidesPerView: 4, spaceBetween: 20 },
    480: { slidesPerView: 4, spaceBetween: 20 },
    640: { slidesPerView: 5, spaceBetween: 20 },
    768: { slidesPerView: 7, spaceBetween: 20 },
    1024: { slidesPerView: 7, spaceBetween: 20 },
    1360: { slidesPerView: 9, spaceBetween: 20 },
  };

  breakPointsToggle: boolean;
  breakpointChange() {
    this.breakPointsToggle = !this.breakPointsToggle;
    this.breakpoints = {
      280: { slidesPerView: 3, spaceBetween: 20 },
      320: { slidesPerView: 4, spaceBetween: 20 },
      480: { slidesPerView: 4, spaceBetween: 20 },
      640: { slidesPerView: 5, spaceBetween: 20 },
      768: { slidesPerView: 7, spaceBetween: 20 },
      1024: { slidesPerView: 7, spaceBetween: 20 },
      1360: { slidesPerView: 9, spaceBetween: 20 },
    };
  }

  ngOnInit() {
    // window.scrollTo(0, 0);
    this.getAudioSongsList(LIMIT, this.offset, this.categoryId);
    this.getDiscourseAudioSongsList(LIMIT, this.offset, this.categoryId);
    this.getVideoSongsList(LIMIT, this.offset, this.categoryId);
    this.getDiscourseVideoSongsList(LIMIT, this.offset, this.categoryId);
    this.getMediationMusicSongsList(LIMIT, this.offset);

    this.data.pauseSong.subscribe(songName => {
      console.log('init audio comp')
      if (songName.toString() == 'true') {
        this.isPaused = true;
        this.isPlayed = false;
      } else {
        this.isPaused = false;
        this.isPlayed = true;
      }
      console.log(this.isPaused)
    })
  }


  /* get Audio songs */
  getAudioSongsList(LIMIT, offset, catId) {
    const audioParam = {
      searchterm: '',
      limit: LIMIT,
      offset: offset,
      quedisc: 1,
      album_id: this.sortBy,
      should_orderby_name: false,
      media_type_id: parseInt(catId),
    };
    this.spinnerService.show();
    this.noRecordBool = false;
    this.dashboardService.getAudioSongsList(audioParam).subscribe(
      async data => {
        if (data) {
          if (data.respon.length == 0) {
            this.noRecordBool = true;
          }
          this.spinnerService.hide();
          const subscription_status = await this.getUserSubscription(this.currentUserId);

          await Promise.all(data.respon.map(async (singleData, i) => {
            this.audioInfo.push(singleData);
            const currentIndex = this.audioInfo.length - 1;
            this.audioInfo[currentIndex].coverImage = `${this.audioBaseUrl}${singleData.cover}`;
            this.audioInfo[currentIndex].subscriptionStatus = subscription_status;
            // this.audioInfo[currentIndex].subscriptionStatus = false;
          }));
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  /* get Discourse Audio songs */
  getDiscourseAudioSongsList(LIMIT, offset, catId) {
    const audioParam = {
      searchterm: '',
      limit: LIMIT,
      offset: offset,
      quedisc: 2,
      album_id: this.sortBy,
      should_orderby_name: false,
      media_type_id: parseInt(catId),
    };
    this.spinnerService.show();
    this.noRecordBool = false;
    this.dashboardService.getDiscourseAudioSongsList(audioParam).subscribe(
      async data => {
        if (data) {
          if (data.respon.length == 0) {
            this.noRecordBool = true;
          }
          this.spinnerService.hide();
          await Promise.all(data.respon.map(async (singleData, i) => {
            this.audioDiscourseInfo.push(singleData);
            const currentIndex = this.audioDiscourseInfo.length - 1;
            this.audioDiscourseInfo[currentIndex].coverImage = `${this.audioBaseUrl}${singleData.cover}`;
          }));
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  getVideoSongsList(LIMIT, offset, catId) {
    const videoParam = {
      searchterm: '',
      limit: LIMIT,
      offset: offset,
      quedisc: 1,
      album_id: this.sortBy,
      should_orderby_name: false,
      media_type_id: parseInt(catId),
    };
    this.spinnerService.show();
    this.noRecordBool = false;
    this.dashboardService.getVideoSongsList(videoParam).subscribe(
      async data => {
        if (data) {
          if (data.respon.length == 0) {
            this.noRecordBool = true;
          }
          this.spinnerService.hide();
          await Promise.all(data.respon.map(async (singleData, i) => {
            this.videoInfo.push(singleData);
            const currentIndex = this.videoInfo.length - 1;
            this.videoInfo[currentIndex].coverImage = `${this.videoBaseUrl}${singleData.cover}`;
          }));
          console.log('video data', this.videoInfo);
        }
      },
      err => {
        return console.error(err);
      }
    );

  }

  getDiscourseVideoSongsList(LIMIT, offset, catId) {
    const videoParam = {
      searchterm: '',
      limit: LIMIT,
      offset: offset,
      quedisc: 2,
      album_id: this.sortBy,
      should_orderby_name: false,
      media_type_id: parseInt(catId),
    }
    this.spinnerService.show();
    this.noRecordBool = false;
    this.dashboardService.getDiscourseVideoSongsList(videoParam).subscribe(
      async data => {
        if (data) {
          if (data.respon.length == 0) {
            this.noRecordBool = true;
          }
          this.spinnerService.hide();
          await Promise.all(data.respon.map(async (singleData, i) => {
            this.videoDiscourseInfo.push(singleData);
            const currentIndex = this.videoDiscourseInfo.length - 1;
            this.videoDiscourseInfo[currentIndex].coverImage = `${this.videoBaseUrl}${singleData.cover}`;
          }));
          console.log('video discourse', this.videoDiscourseInfo);
        }
      },
      err => {
        return console.error(err);
      }
    );
  }


  /* get meditation songs */
  getMediationMusicSongsList(LIMIT, offset) {
    const audioParam = {
      searchterm: "",
      limit: LIMIT,
      offset: offset
    }
    this.spinnerService.show();
    this.noRecordBool = false;
    this.dashboardService.getMediationMusicSongsList(audioParam).subscribe(
      async data => {
        if (data) {
          if (data.respon.length == 0) {
            this.noRecordBool = true;
          }
          this.spinnerService.hide();
          await Promise.all(data.respon.map(async (singleData, i) => {
            this.meditationMusicInfo.push(singleData);
            const currentIndex = this.meditationMusicInfo.length - 1;
            this.meditationMusicInfo[currentIndex].coverImage = `${this.audioBaseUrl}${singleData.cover}`;
          }));
          console.log('interviewSongsInfo data', this.meditationMusicInfo);
        }
      },
      err => {
        this.spinnerService.hide();
        return console.error(err);
      }
    );
  }


  /* call play audio method */
  public playedId = 0;
  playAudioSong(songArr, index, musicType) {
    this.isPlayed = true;
    this.isPaused = false;
    
    this.playedId = songArr[index].id;
  
    //this.playAudioInfo = [];
    //this.playAudioInfo[0] = { showPlayerBool: true, songInfo: songArr[index], format: "audio" };
    this.playAudioInfo = [
      { showPlayerBool: true, songInfo: songArr[index], format: "audio" }
    ]

    if (musicType == "audio") {
      let trackType = 1;
      this.songAddToplaylist(songArr[index], trackType);
    }
    if (musicType == "music") {
      let trackType = 3;
      this.songAddToplaylist(songArr[index], trackType);
    }

    //songArr.splice(index, 1);
    for (let i = index + 1; i < songArr.length; i++) {
      this.playAudioInfo.push(
        { showPlayerBool: true, songInfo: songArr[i], format: "audio" });

    }
    for (let j = 0; j < index; j++) {
      this.playAudioInfo.push(
        { showPlayerBool: true, songInfo: songArr[j], format: "audio" });
    }
    this.isInitPlayed = true;
    this.data.changeSong(this.playAudioInfo);
  }

  songAddToplaylist(songArr, trackType) {
    const audioParam = {
      user_id: this.currentUserId,
      track_id: songArr.id,
      track_type: trackType
    };
    this.dashboardService.songAddToplaylist(audioParam).subscribe(
      async data => {
        if (data) {
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  playVideoSong(songArr) {
    this.playVideoInfo = [
      { showPlayerBool: true, songInfo: songArr, format: "video" }
    ]
    this.data.changeSong(this.playVideoInfo);
  }

  /* Add to Plalist Method */
  sendToPlaylist(songInfo, cat) {
    let catId;
    if (cat == 'audioQA') {
      catId = 1;
    }
    else if (cat == 'audioDis') {
      catId = 1;
    }
    else if (cat == 'homeMusic') {
      catId = 3;
    }
    let playlistParam = [
      { "trackType": 'Jokes', "TrackTypeId": catId, "songId": songInfo.id }
    ];
    this.data.changePlaylist(playlistParam);
  }


  // Get User subscription 
  // tslint:disable-next-line: variable-name
  async getUserSubscription(user_id) {
    let status = false;
    try {
      this.subscriptionBody = {
        userId: user_id,
        materId: MASTER_SUBSCRIPTION_ID
      }

      let subscription_status = new Promise((resolve, reject) => {
        this.dashboardService.getSubscription(this.subscriptionBody).subscribe(
          async data => {
            console.log('data => ', data);
            if (data.iOSstatus || data.status) {
              status = true;
            } else {
              status = false;
            }
            console.log('STATUS WE GET => ', status);

            resolve(status);
          },
          err => {
            return console.error(err);
          }
        );
      });
      return subscription_status;
    } catch (error) {
      return console.error(error);
    }

  }

  // Payment Popup
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
}
