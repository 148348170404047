import { Injectable } from '@angular/core';
import { DataService } from 'src/app/shared-services/data-service/data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private dataService: DataService) { }

  login(loginForm) {
    return this.dataService.postService(`/endpoint/appusers/login/`, loginForm);
  }

  signUp(signupForm) {
    return this.dataService.postService(`/endpoint/appusers/add/`, signupForm);
  }

  forgetPassword(email) {
    return this.dataService.getService('/endpoint/appusers/reset?email=' + email);
  }

  getCountries() {
    return this.dataService.getService(`/endpoint/country/getAllCountries`);
  }

  getStates(stateForm) {
    return this.dataService.postService(`/endpoint/country/getAllStateByCountryId`, stateForm);
  }

}
