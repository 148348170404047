import { Component, OnInit, ElementRef, EventEmitter, Output, AfterViewInit, ViewChild } from '@angular/core';
import { Track } from 'ngx-audio-player';
import { environment } from 'src/environments/environment';
import { DataService } from 'src/app/shared-services/data-service/data.service';
import { NgxAudioPlayerModule, MatAdvancedAudioPlayerComponent } from 'ngx-audio-player';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { TemplateRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-music-player',
  templateUrl: './music-player.component.html',
  styleUrls: ['./music-player.component.scss']
})
export class MusicPlayerComponent implements AfterViewInit {
  @ViewChild('audioPlayerEle') audioPlayerEle: any;

  @ViewChild('openModal') openModalTemplate : TemplateRef<any>;
  msaapDisplayTitle = true;
  msaapDisplayPlayList = true;
  msbapDisplayTitle = true;
  msbapTitle: String;
  isFirstRemoval = true;
  msaapPageSizeOptions = [5];
  songsList = [];
  msaapPlaylist: Track[] = [];
  dataSource;
  videoFileName: any;
  videoTitle: any;
  buttonClass = '';
  buttonHandlerAreaClass = '';
  firstTimePreparingPlaylist = true;

  modalOption: ModalOptions = {
    class: "banner__modal-dialog"
  };
  modalRef: BsModalRef;


  @Output()
  public onPauseSong: EventEmitter<Object> = new EventEmitter<Object>();


  showPlayerBool = false;
  showVideoPlayerBool = false;
  msbapAudioUrl;
  storageBaseUrl: any;

  constructor(private data: DataService, private modalService: BsModalService, private router: Router) {}

  ngAfterViewInit() {


    this.data.currentPlayedSong.subscribe(songName => {

      this.msaapPlaylist = [];
      console.log('songs to music player')
      console.log(songName)
      let newList = songName;
      var self = this;
      newList.forEach(function (entry) {
        let resultPlayerBool = entry.showPlayerBool
        let title = '';
        if (entry.songInfo.track_name) {
          title = entry.songInfo.track_name;
        } else if (entry.songInfo.name) {
          title = entry.songInfo.name;
        } else {
          title = entry.songInfo.file_name;
        }
        let songFile = entry.songInfo.file;
        // let index =  self.msaapPlaylist.findIndex(x => x.title == title)
        // if(index === -1) {
        self.msaapPlaylist.push({
          title: title,
          link: environment.storageBaseUrl + '/audios/track/' + songFile
        })
        // }

      });

      if (songName[0]) {

        if (songName[0].format == "audio") {
          let resultPlayerBool = songName[0].showPlayerBool;
          let title = songName[0].songInfo.file_name;
          this.msbapTitle = title;
          let songFile = environment.storageBaseUrl + '/audios/track/' + songName[0].songInfo.file;
          this.showPlayer(resultPlayerBool, title, songFile);
        }

        if (songName[0].format == "video") {
          let resultPlayerBool = songName[0].showPlayerBool
          let title = songName[0].songInfo.file_name;
          let songFile = environment.storageBaseUrl + '/videos/video/' + songName[0].songInfo.file;
          this.showVideoPlayer(resultPlayerBool, title, songFile);
        }


      }
    })    
  }


  closePlayer() {
    console.log(this.modalRef);
    if(this.modalRef != undefined) {
      this.modalRef.hide();
    }
    this.audioPlayerEle.isPlaying = false;
    // this.audioPlayerEle.player.nativeElement.autoplay = false;
    this.showPlayerBool = false;
  }

  showPlayer(showPlayerBool, title, songFile) {
    //Check subscription and redirect to subscription page
    let currentuser = JSON.parse(localStorage.getItem('loggedInUser'));
    var CurrentDate = new Date();
    var subscriptionEndDate = new Date(currentuser.subscriptionEndDate);
    if(subscriptionEndDate < CurrentDate){
      this.router.navigate(['/ask-osho/subscription']);
    } else {

      if (this.showVideoPlayerBool) {
        this.closeImgModal();
      }
      if (this.showPlayerBool) {
        this.showPlayerBool = false;
      }
      setTimeout(() => {
        this.showPlayerBool = true;
      }, 200);
  
      this.msbapAudioUrl = songFile;
  
      var newThisInstance = this;
      this.msaapPlaylist.forEach(function (item, i) {
        if (item.link === songFile) {
          newThisInstance.msaapPlaylist.splice(i, 1);
          newThisInstance.msaapPlaylist.unshift(item);
        }
      });
      setTimeout(() => {
        this.audioPlayerEle.isPlaying = true;
        this.audioPlayerEle.player.nativeElement.autoplay = true;
  
      }, 200);

    }

          
  }

  closeModal() {
    this.modalRef.hide();
  }


  showVideoPlayer(showPlayerBool, title, songFile) {
    //Check subscription and redirect to subscription page
    let currentuser = JSON.parse(localStorage.getItem('loggedInUser'));
    var CurrentDate = new Date();
    var subscriptionEndDate = new Date(currentuser.subscriptionEndDate);
    if(subscriptionEndDate < CurrentDate){
      this.router.navigate(['/ask-osho/subscription']);
    } else {
      this.modalRef = this.modalService.show(this.openModalTemplate, this.modalOption);
    
      if (this.showPlayerBool) {
        this.closePlayer();
      }
      this.showVideoPlayerBool = showPlayerBool;
      this.videoTitle = title;
      this.videoFileName = songFile;
    }
  }


  closeImgModal() {
    this.showVideoPlayerBool = false;
  }

  pauseAudioSong(ev) {
    if (ev.path[1].firstElementChild == null)
      this.buttonClass = ev.path[1].className;
    else
      this.buttonHandlerAreaClass = ev.path[0].firstElementChild.className.toString();

    if (this.buttonClass !== '' && this.buttonClass) {
      // Workaround for play pause event
      if (this.buttonClass.includes('play') || this.buttonClass.includes('pause')) {
        this.data.pauseAudioSong();
      }
    } else if ((this.buttonHandlerAreaClass !== '' && this.buttonHandlerAreaClass)) {
      if (this.buttonHandlerAreaClass == 'mat-button-wrapper')
        this.data.pauseAudioSong();
    }

  }
}