import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../../src/app/auth/auth.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-osho-center',
  templateUrl: './osho-center.component.html',
  styleUrls: ['./osho-center.component.scss', '../main-content/main-content.component.scss', '../profile/profile.component.scss']
})
export class OshoCenterComponent implements OnInit {
  countryData: any[] = [];
  stateData: any[] = [];
  showStateBool = false;
  userstate: '';
  oshoCenterInfo = [];
  noRecordBool = false;
  usercountry : '';
  
  


  constructor(private authService: AuthService,private spinnerService: Ng4LoadingSpinnerService, private dashboardService: DashboardService) { }

  async ngOnInit() {
    window.scrollTo(0, 0)
    await this.getCountries();
    // this.getLoginUserInfo();
  }


  // Get Countries
  getCountries(): void {
    this.spinnerService.show();
    this.authService.getCountries().subscribe(
      async data => {
        console.log(data);
        if (data.status) {
          this.countryData = data.resultObject;
          console.log(this.countryData);
          this.spinnerService.hide();
        }
      },
      err => {
        return console.error(err);
      }
    );  
  }

  // Get States
  setStateId(countryId,str): void {
    const stateForm = {
      id: countryId
    };
    this.spinnerService.show();
    this.authService.getStates(stateForm).subscribe(
      async data => {
        console.log(data);
        if (data.status) {
          this.stateData = data.resultObject;
          if(str == "changeState"){
            this.userstate = "";
          }
          this.showStateBool = true;
          this.spinnerService.hide();
        }
      },
      err => {
        return console.error(err);
      }
    );
  }

  // Get States
  changeStateId(stateId): void {
    const stateForm = {
      id: stateId
    };
    this.spinnerService.show();
    this.dashboardService.getOshoCenters(stateForm).subscribe(
      async data => {
        console.log(data);
        if (data.status) {
         this.oshoCenterInfo = data.resultObject;
         if(this.oshoCenterInfo){
          this.noRecordBool = false;
         }
         else{
          this.noRecordBool = true;
         }
          this.spinnerService.hide();
        }
      },
      err => {
        return console.error(err);
      }
    );
  }


}
