import { Component, OnInit, ViewChild } from '@angular/core';
import { BannerService } from './banner.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {CarouselComponent} from 'ngx-bootstrap';
import { SwiperComponent } from "swiper/angular";
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { TemplateRef } from '@angular/core';

// import Swiper core and required components
import SwiperCore , {
  Navigation
} from 'swiper';


SwiperCore.use([
  Navigation
]);

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  providers: [BannerService]
})
export class BannerComponent implements OnInit {
  @ViewChild('swiperRef') swiperRef?: SwiperComponent;
  
  modalOption: ModalOptions = {
    class: "banner__modal-dialog"
  };
  modalRef: BsModalRef;

  sliderBaseUrl: any;
  bannerImage: any;
  sliderLoopBool = false;
  slides= [];
  
  sliderImgArray: any = [];
  numberOfThumbsDisp = 3;
  showImgModalBool = false;
  previewCarouselBool = false;
  storageBaseUrl: any;
  sliderBaseUrlQuote:any;

  indexNumber = 1;

  constructor(private bannerService: BannerService, private httpClient: HttpClient, private modalService: BsModalService) {
    this.sliderBaseUrl = environment.storageBaseUrl + '/slider/';
    this.sliderBaseUrlQuote = environment.storageBaseUrl + '/quote/';
  }

  openModal(template: TemplateRef<any>, slideImage) {
    this.modalRef = this.modalService.show(template, this.modalOption);
    this.bannerImage = slideImage;
  }
  closeModal() {
    this.modalRef.hide();
  }
  
  ngOnInit() {
    this.getBannerData();
  }

  navigation = false;
  toggleNavigation() {
    console.log("in navigation");
    this.navigation = !this.navigation;
  }

  log(log: string) {
    // console.log(string);
  }

  breakpoints = {
    320: { slidesPerView: 1, spaceBetween: 20 },
    480: { slidesPerView: 1, spaceBetween: 20 },
    640: { slidesPerView: 1, spaceBetween: 20 },
    768: { slidesPerView: 2, spaceBetween: 20 },
    1024: { slidesPerView: 3, spaceBetween: 20 },
  };

  breakPointsToggle: boolean;
  breakpointChange() {
    this.breakPointsToggle = !this.breakPointsToggle;
    this.breakpoints = {
      320: { slidesPerView: 1, spaceBetween: 20 },
      480: { slidesPerView: 1, spaceBetween: 20 },
      640: { slidesPerView: 1, spaceBetween: 20 },
      768: { slidesPerView: 2, spaceBetween: 20 },
      1024: { slidesPerView: 3, spaceBetween: 20 },
    };
  }
  
  getBannerData() {
    this.bannerService.getBannerImages().subscribe(
      async data => {
        if (data) {
          await Promise.all(data.respon.map(async (singleData) => {
            if(singleData.quote == 1){
              this.slides.push({ image: `${this.sliderBaseUrlQuote}${singleData.file}` });
            }
            else{
              this.slides.push({ image: `${this.sliderBaseUrl}${singleData.file}` });
            }
          }));
          this.sliderLoopBool = true;
          if(this.sliderLoopBool){
            this.getThumbnailImgArr();
          }
        }
      },
      err => {
        return console.error(err);
      }
    );
  }
  
  getThumbnailImgArr(){
    this.sliderImgArray = [];
    /* let numberOfThumbsDisp = 3; */
    let lengthOfThumbArr = this.slides.length%this.numberOfThumbsDisp == 0 ? this.slides.length/this.numberOfThumbsDisp : (this.slides.length/this.numberOfThumbsDisp + 1);
    for(let i=0,imgIndex=0; i<lengthOfThumbArr; i++){
      let tempArr = [];
      for(let j=0; j<this.numberOfThumbsDisp; j++, imgIndex++){
        if(this.slides[imgIndex]){
          this.slides[imgIndex].index = imgIndex;
          tempArr.push(this.slides[imgIndex]);
        }
        else{
          break;
        }
      }
      this.sliderImgArray.push(tempArr);
      if(this.slides[imgIndex] == null || this.slides[imgIndex] == undefined){
        break;
      }
    }
    return this.sliderImgArray;
  }
  
  showImgInModal(index){
    
    this.showImgModalBool = true;
    this.previewCarouselBool = true;
    setTimeout(() => {
      this.previewCarouselBool = false;
      this.swiperRef.setIndex(index);
    }, 200);
  }
  closeImgModal(){
    this.showImgModalBool = false;
  }}
